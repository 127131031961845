import { PaginationState, QueryState, converMonthYearToMonthYearNo, initialQueryState, stringifyObjectToRequestQuery } from "src/helpers/index"
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios, { AxiosError } from "axios"
import { getRewardsTable, getSummary } from "src/service/services"
import { RootState } from "../store"

interface playerSummaryProp {

    collection_coverage_summary: null | number,

    collection_efficeincy_summary: null | number
    desconnection_summary: null | number
    event_summary: null | number
    is_on_time_summary: null | number
    reconnection_summary: null | number
    total_customers: null | number
    total_summary: null | number
}

export interface summmaryDataProps {
    playerSummary: playerSummaryProp

}

interface RewardsState extends QueryState {
    message: string | undefined
    error: boolean,
    dataChanged: boolean,
    summaryLoading: boolean,
    rewardsTableLoading: boolean,
    summmaryData: summmaryDataProps
    rewardsTableData: any[]
    // paginations: PaginationState,
    employeeName: string | undefined,
    employeeId: string | undefined,
    page: number
    items_per_page: 10 | 30 | 50 | 100,
    user_id: number
}

const initialState: RewardsState = {
    summaryLoading: false,
    rewardsTableLoading: false,
    message: undefined,
    error: false,
    dataChanged: false,
    summmaryData: {
        playerSummary: {
            collection_coverage_summary: null,
            collection_efficeincy_summary: null,
            desconnection_summary: null,
            event_summary: null,
            is_on_time_summary: null,
            reconnection_summary: null,
            total_customers: null,
            total_summary: null
        }
    },
    rewardsTableData: [],
    employeeName: undefined,
    employeeId: undefined,
    page: 1,
    items_per_page: 50,
    user_id: 0
}


export const reward_summary_data: any = createAsyncThunk(
    'chart/reward_summary_data',
    async (_, thunkApi: any,) => {
        try {


            const getFilterState = thunkApi.getState().dashboard
            const query = {
                dist_id: getFilterState.districtId,
                bym: getFilterState.monthYear,

            }

            const queryparams = stringifyObjectToRequestQuery(query)
            // console.log(queryparams)
            const response = await getSummary(queryparams);

            return response.data;

        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)

export const reward_summury_table: any = createAsyncThunk(
    'chart/reward_summury_table',
    async (_, thunkApi: any,) => {
        try {

            const getFilterState = thunkApi.getState().dashboard
            const getFilterState_2 = thunkApi.getState().reward;
            const query = {
                page: getFilterState_2.page,
                items_per_page: getFilterState_2.items_per_page,
                dist_id: getFilterState.districtId,
                bym: getFilterState.monthYear.toUpperCase(),
                name: getFilterState_2?.employeeName,
                employee_id: getFilterState_2?.employeeId,
            }
            const queryparams = stringifyObjectToRequestQuery(query);



            const response = await getRewardsTable(queryparams);

            return response;

        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)

export const reward_api_call: any = createAsyncThunk(
    'chart/reward_api_call',
    async (_, thunkApi: any,) => {
        thunkApi.dispatch(reward_summury_table())
        thunkApi.dispatch(reward_summary_data())
    }
)

export const rewardsDashboard = createSlice({
    name: "dashboardSlicer",
    initialState,
    reducers: {
        updateSummaryData(state, action: PayloadAction<any>) {
            state.summmaryData = action.payload
        },
        updateUserId(state, action: PayloadAction<any>) {
            state.user_id = action.payload
        },
        updateRewardsTableData(state, action: PayloadAction<any>) {
            state.rewardsTableData = action.payload
        },
        RewardsEmployeeName(state, action: PayloadAction<any>) {
            state.employeeName = action.payload;
            // if (action.payload) {
            //     state.employeeId = undefined;
            // }


        },
        RewardsEmployeeId(state, action: PayloadAction<any>) {
            state.employeeId = action.payload;
            // if (action.payload) {
            //     state.employeeName = undefined;
            // }


        },
        setPage: (state, action: PayloadAction<any>) => {
            state.page = action.payload;
        },
        setItemsPerPage: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload;
        },
        setPaggination: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload.pageSize;
            state.page = action.payload.pageIndex;
        },

        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        setResetState(state) {
            state.items_per_page = initialState.items_per_page;
            state.page = initialState.page;
            state.employeeId = initialState.employeeId;
            state.employeeName = initialState.employeeName;

        }
    },
    extraReducers: (builder) => {
        builder.addCase(reward_summary_data.fulfilled, (state, action: PayloadAction<any>) => {
            state.summmaryData = action.payload;
            state.summaryLoading = false

        });
        builder.addCase(reward_summary_data.pending, (state) => {
            state.summaryLoading = true;
        });
        builder.addCase(reward_summary_data.rejected, (state, action: PayloadAction<any>) => {
            state.summaryLoading = false;
            state.message = action.payload || 'Error Occured'
        });

        builder.addCase(reward_summury_table.fulfilled, (state, action: PayloadAction<any>) => {
            let data = action.payload?.data?.data
            state.rewardsTableData = data;
            state.rewardsTableLoading = false;
            state.items_per_page = action.payload?.data?.payload?.pagination?.items_per_page;
            state.page = action.payload?.data?.payload?.pagination?.page;
            state.total_records = action.payload?.data?.payload?.pagination?.total_records;
            state.total = action.payload?.data?.payload?.pagination?.total;
            // console.log(action.payload)
        });
        builder.addCase(reward_summury_table.pending, (state) => {
            state.rewardsTableLoading = true;
            state.error = false;
            state.message = "";
            state.rewardsTableData = [];
        });
        builder.addCase(reward_summury_table.rejected, (state, action: PayloadAction<any>) => {
            state.rewardsTableLoading = false;
            state.message = action.payload || 'Error Occured'
        });
    }
})

export const { setError, resetError, updateSummaryData, updateRewardsTableData,
    RewardsEmployeeName, RewardsEmployeeId, setPage, setItemsPerPage,
    setPaggination, setResetState, updateUserId } = rewardsDashboard.actions;
export const rewardsReducerState = (state: RootState) => state.reward;
export default rewardsDashboard.reducer;
