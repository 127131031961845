import React, { useEffect, useMemo, useState } from "react";
import { useQueryResponse } from "../Context/Core/QueryResponseProvider";
import BitwiseChartOneToTen from "../../BucketWisePoint/Context/ChartComponent/BitwiseChartOneToTen";
import BitwiseChartElevenToTwenty from "../../BucketWisePoint/Context/ChartComponent/BitwiseChartElevenToTwenty";
import BitwiseChartTwentyOneToEnd from "../../BucketWisePoint/Context/ChartComponent/BitwiseChartTwentyOneToEnd";
import { convertToCamelCase } from "../../../helpers";
import { BitwiseUpdatedMainState } from "../Context/index";
import { Link, useLocation } from "react-router-dom";
import { Loader2, RotateCcw } from "lucide-react";
import BucketActivityWiseTable from "../Context/ChartComponent/BucketActivityWiseTable";
import { bucketColumn } from "../Context/ChartComponent/bucketColumn";
import TotalBucket from "../Context/ChartComponent/TotalBucket";
import { tableBucketColumn } from "../Context/ChartComponent/tableBucketColumn";
import ChartsSummary from "../Context/ChartComponent/ChartsSummary";
import TotalChartSummary from "../Context/ChartComponent/TotalChartSummary";
import DateMothFilter from "../../TableFilters/FilterComp/DateMothFilter";
import { useQueryResponseLoading } from "../Context/Core/QueryResponseProvider";
import Loader from "../../Loader/Loader";
import EditableComponent from "../../EditableComponent/Component/EditableComponent";
import UserActivityDropDown from "src/modules/TableFilters/FilterComp/UserActivityDropDown";
import CollectionDropDown from "src/modules/TableFilters/FilterComp/CollectionDropDown";
import { collectionEfficiencyColumn } from "../Context/ChartComponent/collectionEfficiencyColumn";
import OtherActivityChart from "../Context/ChartComponent/OtherActivityChart";
import DatesBoxes from "../Context/ChartComponent/DatesBoxes";
import UserIdDropDown from "../../TableFilters/FilterComp/UserIdDropDown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { dashboardReducerState } from "src/redux/slicer/dashboardSlicer";
import { Button } from "src/components/ui/button";
import {
  updateActivityDropDown,
  updateDatefilter,
  updateTableDataAndPostData,
  updateTargetValue,
} from "src/redux/slicer/editableSlicer";
import SingleGraphColEff from "../Context/ChartComponent/SingleGraphColEff";
import {
  updateActivityDropDownPunch,
  updateDatefilterPunch,
  updateTableDataAndPostDataPunch,
  updateTargetValuePunch,
} from "src/redux/slicer/punchEditableSlicer";
import { GET_PROFILE_PICTURE } from "src/service/apis";

interface EfficiencyData {
  user_id: number;
  dt: string;
  actual_collection: number;
  cumulative_collection: string;
  actual_points: number;
  bonus_points: number;
  total_points: number;
  final_points: number;
  dump_data_achieved: string | null;
  cumulative_activity_achieved: string | null;
  match: number | null;
}

type UserInfo = {
  profile_image: string;
  name: string;
  employee_id: string;
  global_rank: number;
  group_rank: number;
  team_rank: number;
};

type Tna = {
  target: number;
  dt: string;
  collection: number;
};

function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}

type DailyData = {
  dt: string | undefined;
  achieved: string | undefined | null;
};
type Props = {};

const BucketTable = (props: Props) => {
  const [monthFilter, setMonthFilter] = useState<string>("");
  const [activity, setActivity] = useState<string>("");
  const [userId, setUserId] = useState<number>(0);
  const { dashBoardState } = useSelector((state: RootState) =>
    dashboardReducerState(state)
  );

  const { response } = useQueryResponse();
  const isLoading = useQueryResponseLoading();

  const dispatch = useDispatch();

  const apiResponse: any = useMemo(() => response, [response]);

  const tableData: any = useMemo(
    () => (isLoading ? [] : apiResponse ? apiResponse?.data : []),
    [isLoading, apiResponse]
  );

  const userName: string = useMemo(
    () =>
      isLoading
        ? "N/A"
        : tableData && tableData?.user_info
          ? tableData?.user_info?.name
          : "N/A",
    [isLoading, tableData]
  );


  const profile_image: string = useMemo(
    () =>
      isLoading
        ? ""
        : tableData && tableData?.user_info
          ? tableData?.user_info?.profile_image
          : "",
    [isLoading, tableData]
  );

  const employee_id: string = useMemo(
    () =>
      isLoading
        ? "N/A"
        : tableData && tableData?.user_info
          ? tableData?.user_info?.employee_id
          : "N/A",
    [isLoading, tableData]
  );

  const global_rank: any = useMemo(
    () =>
      isLoading
        ? "N/A"
        : tableData && tableData?.user_info
          ? tableData?.user_info?.global_rank
          : "N/A",
    [isLoading, tableData]
  );

  const group_rank: any = useMemo(
    () =>
      isLoading
        ? "N/A"
        : tableData && tableData?.user_info
          ? tableData?.user_info?.group_rank
          : "N/A",
    [isLoading, tableData]
  );

  const team_rank: any = useMemo(
    () =>
      isLoading
        ? "N/A"
        : tableData && tableData?.user_info
          ? tableData?.user_info?.team_rank
          : "N/A",
    [isLoading, tableData]
  );

  const targets: number = useMemo(
    () =>
      isLoading
        ? 0
        : tableData && tableData?.t_n_a
          ? tableData?.t_n_a?.target?.toFixed(2)
          : 0,
    [isLoading, tableData]
  );

  const valMax: number = useMemo(
    () =>
      isLoading
        ? 0
        : tableData && tableData?.y_max
          ? tableData?.y_max[tableData?.y_max.length - 1]?.yAxisxValue
          : 0,
    [isLoading, tableData]
  );

  const collection: number = useMemo(
    () =>
      isLoading
        ? 0
        : tableData && tableData?.t_n_a
          ? tableData?.t_n_a?.collection?.toFixed(2)
          : 0,
    [isLoading, tableData]
  );
  const totals: any = useMemo(
    () =>
      isLoading
        ? 0
        : tableData && tableData?.total
          ? tableData?.total[0]?.total_points?.toFixed(2)
          : 0,
    [isLoading, tableData]
  );

  const targetPuch = useMemo(() => isLoading ? 0 : (tableData?.target), [tableData])
  // const totals = tableData?.total || [];

  const date = new Date(monthFilter);
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;

  const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

  const { search } = useLocation();

  useEffect(() => {
    const params = search && search?.split("?")[1].split("&");
    // console.log({ params });
    let searchParams: any = {};

    params &&
      params.forEach((o: any) => {
        let splitParam = o?.split("=");
        searchParams[splitParam[0]] = splitParam[1];
      });

    setMonthFilter(searchParams?.bym);
    setActivity(searchParams?.activity);
    setUserId(searchParams?.userId);
    return () => { };
  }, [search]);

  const { state, updateState } = BitwiseUpdatedMainState();

  const effData = useMemo(() => {
    return [
      ...(tableData?.first_to_ten_detailed || []),
      ...(tableData?.eleven_to_twenty_detailed || []),
      ...(tableData?.last_ten_days_detailed || []),
    ];
  }, [tableData]);

  const details = useMemo(
    () => (isLoading ? [] : tableData ? tableData?.detailed : []),
    [isLoading, tableData]
  );
  // console.log(apiResponse)

  useEffect(() => {
    userId !== 0 &&
      updateState({
        filter: {
          bym: monthFilter,
          activity: activity,
          user_id: userId,
        },
      });
  }, [monthFilter, activity, userId]);

  const total = tableData?.total || [];
  const activities = {
    collection_efficeincy: "Collection Efficiency ₦ ",
    desconnection: "Disconnection Count",
    is_on_time: "Punctual Attendance",
    reconnection: "Reconnection Count",
    event_count: "Visits",
    collection_coverage: "Collection Coverage",
  };

  const dailyData: DailyData[] = useMemo(() => {
    if (activity === "collection_efficeincy") {
      return (
        effData &&
        Array.isArray(effData) &&
        effData.map((cur: EfficiencyData) => {
          return {
            dt: cur?.dt,
            achieved: cur?.dump_data_achieved,
          };
        })
      );
    } else {
      return (
        tableData?.detailed &&
        Array.isArray(tableData?.detailed) &&
        tableData?.detailed.map((cur: EfficiencyData) => {
          return {
            dt: cur?.dt,
            achieved: cur?.dump_data_achieved,
          };
        })
      );
    }
  }, [effData, tableData]);

  const bemarkVal: number | undefined = useMemo(() => {
    if (activity === "collection_efficeincy") {
      return 3000;
    } else if (activity === "collection_coverage") {
      return 10;
    } else if (activity === "desconnection") {
      return 50;
    } else if (activity === "reconnection") {
      return 200;
    } else {
      return 0;
    }
  }, [activity]);

  const sandboxDispatch = (monthFilter: string, activity: string) => {
    if (activity === "is_on_time" || activity === "event_count") {
      dispatch(
        updateTableDataAndPostDataPunch({
          bym: monthFilter,
          jsonInput: {
            activity: activity,
            target: targetPuch,
            daily_data: dailyData,
          },
        })
      );

      dispatch(updateDatefilterPunch(monthFilter));
      dispatch(updateActivityDropDownPunch(activity));
      dispatch(
        updateTargetValuePunch(targetPuch)
      );
    } else {
      dispatch(
        updateTableDataAndPostData({
          bym: monthFilter,
          jsonInput: {
            A: 160,
            bmark: bemarkVal,
            per_million: 1000000,
            activity: activity,
            target: tableData?.target && tableData?.target?.target,
            daily_data: dailyData,
          },
        })
      );
      dispatch(updateDatefilter(monthFilter));
      dispatch(updateActivityDropDown(activity));
      dispatch(
        updateTargetValue(tableData?.target && tableData?.target?.target)
      );
    }
  };

  const target: any = useMemo(
    () => (tableData ? tableData?.t_n_a?.target : 0),
    [tableData]
  );

  const xAxisValues: any = useMemo(() => {
    return tableData?.detailed?.map((cur: any) => ({
      xAxisValue: Number(
        ((Number(cur.cumulative_collection) / Number(target)) * 100).toFixed(2)
      ),
    }));
  }, [tableData]);

  const xAxisValues2: any = useMemo(() => {
    return tableData?.detailed?.map((cur: any) => ({
      xAxisValue: Number(
        ((Number(cur.actual_points) / Number(target)) * 100).toFixed(2)
      ),
    }));
  }, [tableData]);

  const achivedPercentage = useMemo(
    () =>
      xAxisValues &&
        Array.isArray(xAxisValues) &&
        xAxisValues2 &&
        Array.isArray(xAxisValues2)
        ? activity === "is_on_time" || activity === "event_count"
          ? xAxisValues2[xAxisValues2?.length - 1]?.xAxisValue
          : xAxisValues[xAxisValues?.length - 1].xAxisValue
        : 0,
    [xAxisValues, xAxisValues2]
  );


  return (
    <div
      className={`px-2 ${!dashBoardState ? "ml-20  duration-300" : "ml-60 duration-300"
        }`}
    >
      <h2 className="text-gray-900 font-semibold text-lg mt-2 ml-5 ">
        Points Breakup
      </h2>
      <div className="flex justify-between items-center px-5">
        <div className="flex justify-start items-center space-x-6  ">
          {/* <h1 className='text-sm font-medium text-gray-500 '><span className='text-gray-700'>#{ }</span></h1> */}
          <h1 className="text-sm font-medium text-gray-500 ">
            {" "}
            <span className="text-gray-900 font-semibold text-sm">
              {monthFilter}
            </span>
          </h1>
          <h1 className="text-xs font-medium text-gray-500">
            <span className="text-xs text-gray-900 dark:text-white font-bold ">
              Activity :
            </span>{" "}
            <span className="text-gray-700 ml-2 font-semibold">
              {activities[activity as keyof typeof activities] ||
                convertToCamelCase(activity)}
            </span>
          </h1>
        </div>
        <div className="space-x-6">
          {activity === "collection_efficeincy" ? <SingleGraphColEff /> : <></>}
          <Button
            className="text-xs "
            disabled={isLoading}
            onClick={() => sandboxDispatch(monthFilter, activity)}
          >
            {activity === "is_on_time" || activity === "event_count" ? (
              <Link to="/dashboard/editable-punch"> View Punch Sandbox</Link>
            ) : (
              <Link to="/dashboard/editable-comp"> View Sandbox</Link>
            )}
          </Button>
        </div>
      </div>

      <div className="flex justify-around items-center space-x-6 my-4">
        <h1 className="text-xs font-medium text-gray-500 flex items-center">
          <span className="text-xs text-gray-900 dark:text-white font-bold ">
            {" "}
            User Name :
          </span>
          <img
            src={`${GET_PROFILE_PICTURE}${profile_image}`}
            alt={""}
            className="w-8 h-8 rounded-full ml-2"
          />
          {"  "}
          <span className="text-gray-700 ml-2 font-semibold">{userName}</span>
        </h1>
        <h1 className="text-xs font-medium text-gray-500">
          <span className="text-xs text-gray-900 dark:text-white font-bold ">
            User Id :
          </span>{" "}
          <span className="text-gray-700 ml-2 font-semibold">{userId}</span>{" "}
        </h1>
        <h1 className="text-xs font-medium text-gray-500">
          <span className="text-xs text-gray-900 dark:text-white font-bold ">
            {" "}
            Employee Id :
          </span>{" "}
          <span className="text-gray-700 ml-2 font-semibold">
            {employee_id}
          </span>
        </h1>
        <h1 className="text-xs font-medium text-gray-500">
          <span className="text-xs text-gray-900 dark:text-white font-bold ">
            Global Rank :
          </span>{" "}
          <span className="text-gray-700 ml-2 font-semibold">
            {global_rank}
          </span>
        </h1>
        <h1 className="text-xs font-medium text-gray-500">
          <span className="text-xs text-gray-900 dark:text-white font-bold ">
            Group Rank :
          </span>{" "}
          <span className="text-gray-700 ml-2 font-semibold">{group_rank}</span>
        </h1>
        <h1 className="text-xs font-medium text-gray-500">
          <span className="text-xs text-gray-900 dark:text-white font-bold ">
            Team Rank :
          </span>{" "}
          <span className="text-gray-700 ml-2 font-semibold">{team_rank}</span>
        </h1>
      </div>

      {/* <div className='flex justify-between space-x-6 px-4 w-full items-center'>


        <div className='w-full flex justify-end space-x-2 my-2 '>
          <div className='w-1/6 -mb-2'>

            <h3 className=' text-center text-sm text-gray-800 font-medium'>Select User Id</h3>
            <UserIdDropDown setUserId={setUserId} searchId={userId} loading={isLoading}/>

          </div>

          <div className='w-1/5'>

            <h3 className='-mb-2 text-center text-sm text-gray-800 font-medium'>Select Activity</h3>
            <CollectionDropDown setActivity={setActivity} activity={activity}  loading={isLoading}/>

          </div>

          <div className='w-1/6 '>

            <h3 className='-mb-2 text-center text-sm text-gray-800 font-medium'>Select Month/Year</h3>
            <DateMothFilter setMonthFilter={setMonthFilter} monthYear={monthFilter}  loading={isLoading}/>

          </div>
        </div>

      </div> */}

      {isLoading ? (
        <div className="flex justify-center items-center ">
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />{" "}
          <span>Loading...</span>
        </div>
      ) : (
        <div>
          {activity === "collection_efficeincy" ? (
            <div className="-mt-1">
              <div>
                <div className="w-full grid grid-cols-3 text-center">
                  <div className="w-full border border-gray-300">
                    <BitwiseChartOneToTen />
                  </div>
                  <div className="border  border-gray-300 w-full">
                    <BitwiseChartElevenToTwenty />
                  </div>
                  <div className="border  border-gray-300 w-full">
                    <BitwiseChartTwentyOneToEnd />
                  </div>
                </div>
                <div className="w-full ">
                  <div className="">
                    <TotalChartSummary color="bg-purple-500" word="Total" />
                  </div>
                </div>
                <div className="mt-2">
                  <BucketActivityWiseTable
                    columns={collectionEfficiencyColumn}
                    data={effData || []}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-4">
              <div className="flex flex-col justify-center items-center">
                <div className="my-2 px-10">
                  <DatesBoxes
                    data={tableData?.detailed || []}
                    arrInd={daysInCurrentMonth}
                    ind={0}
                    color="bg-green-600"
                  />
                </div>
                <OtherActivityChart
                  data={tableData || []}
                  activity={activity}
                />
              </div>
              <div className=" text-sm font-semibold text-center text-gray-700 my-2 space-x-6">
                <span>
                  <span className="text-gray-800 font-bold dark:text-white">
                    {" "}
                    Achived Value:
                  </span>
                  {"  "}₦{Intl.NumberFormat("en-US").format(collection)}
                </span>
                <span>
                  <span className="text-gray-800 font-bold dark:text-white">
                    Target Value:
                  </span>
                  {"  "}
                  {Intl.NumberFormat("en-US").format(targets)}
                </span>

                <span>
                  <span className="text-gray-800 font-bold dark:text-white">
                    Achived Percentage:
                  </span>
                  {"  "}
                  {achivedPercentage} %
                </span>
              </div>

              <h3 className=" text-sm font-bold text-center text-gray-800 my-2 dark:text-white">
                Achived Points: {Intl.NumberFormat("en-US").format(totals)}
                {"  "} <span className="font-semibold">Out Of</span>{" "}
                {Intl.NumberFormat("en-US").format(valMax)}
              </h3>

              <BucketActivityWiseTable
                columns={bucketColumn}
                data={details || []}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BucketTable;
