
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import React, { useEffect, useState } from 'react';

dayjs.extend(duration);
dayjs.extend(relativeTime);

interface DurationProps {
  start_date: string;
  end_date: string;
}

interface CalculationDurationProps {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
  milliseconds: number;
}

const Duration = ({ start_date, end_date }: DurationProps) => {
  const [duration, setDuration] = useState<string>('');

  useEffect(() => {
    const start = dayjs(start_date);
    const end = dayjs(end_date);

    const durationObj = dayjs.duration(end.diff(start));

    const hours = durationObj.hours();
    const minutes = durationObj.minutes();
    const seconds = durationObj.seconds();

    if (Math.abs(hours) >= 1) {
      setDuration(`${Math.abs(hours).toFixed(2)} hr`);
    } else if (Math.abs(minutes) >= 1) {
      setDuration(`${Math.abs(minutes).toFixed(0)} min`);
    } else {
      setDuration(`${Math.abs(seconds).toFixed(0)} sec`);
    }
  }, [start_date, end_date]);

  return (
    <div>
      {duration}
    </div>
  );
};

export default Duration;
