import Marquee from 'react-fast-marquee';
import { useQueryResponse } from '../Core/QueryResponseProvider';
import { WallOfFameMainState, useQueryResponseLoading } from '../index';
import React, { useMemo } from 'react'

import List_2 from './List_2';


const List = () => {
    const { state, updateState } = WallOfFameMainState();
    const { response } = useQueryResponse()

    const isLoading = useQueryResponseLoading();

    const apiResponseData: any = useMemo(() => response, [response]);

    const tableData: any = useMemo(() => isLoading ? {} : (apiResponseData ? apiResponseData?.data : {}), [isLoading, apiResponseData])

    const all: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.all : []), [isLoading, tableData])

    const allDistName: any = useMemo(() => all?.length > 0 ? all.map((cur: any) => cur.dist_name) : [], [all])
    const commonDist: string[] = useMemo(() => allDistName?.length > 0 ? allDistName.filter((cur: string, ind: number) => allDistName.indexOf(cur) === ind) : [], [allDistName])

    // const uniqueDistNameArray = all.reduce((result: any, currentObject: any) => {
    //     const existingObject = result.find((item: any) => item.dist_name === currentObject.dist_name);

    //     if (!existingObject) {
    //         // If the dist_name doesn't exist in the result array, add a new object
    //         result.push({
    //             // dist_name: currentObject.dist_name,
    //             data: [currentObject] // Create an array containing the current object
    //         });
    //     } else {
    //         // If the dist_name already exists, push the current object into the existing object's data array
    //         existingObject.data.push(currentObject);
    //     }

    //     return result;
    // }, [all]);




    return (
        <div className='w-full '>
            <Marquee loop={0} gradient={false} speed={100} className='p-2' gradientWidth={10}>
                <div className='w-full flex px-10 space-x-12'>
                    {Array.isArray(all) && all.length > 0 && all?.map((cur: any, ind: number) => <List_2 data={cur} />
                    )}


                </div>
            </Marquee>
        </div>
    )
}

export default List