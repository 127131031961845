


import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useQueryResponse } from '../Core/QueryResponseProvider';
import React, { useMemo } from 'react';
import { convertToCamelCase } from '../../../../helpers';
import { dataAxis_2 } from './dataAxis';
import LineCharts from './LineCharts';
import ChartsSummary from './ChartsSummary';
import DatesBoxes from '../ChartComponent/DatesBoxes';
import { useQueryResponseLoading } from '../index';
type Props = {}

const BitwiseChartElevenToTwenty = () => {
  const { response } = useQueryResponse();

  const isLoading = useQueryResponseLoading();
  const apiResponse: any = useMemo(() => response, [response])

  const tableData: any = useMemo(() => isLoading ? [] : (apiResponse ? apiResponse?.data : []), [isLoading, apiResponse])
  const summaryBucket_2: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.eleven_to_twenty_summary : []), [isLoading, tableData])
  const targetBucket_2: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.eleven_to_twenty_t_n_a : []), [isLoading, tableData])
  const details: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.eleven_to_twenty_detailed : []), [isLoading, tableData])
  const eleven_to_twenty_t_n_a: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.eleven_to_twenty_t_n_a : []), [isLoading, tableData])



  return (
    <>
      {/* <h1 className='text-lg font-medium text-gray-950  mt-1'>11 to 20 Days</h1> */}
      <div className='flex flex-col justify-center items-center'>
        <div className='mt-2 px-6'>
          <DatesBoxes data={details} arrInd={20} ind={10} color='bg-sky-600' />
        </div>
        {/* <h1 className=' text-sm font-normal text-right mr-16 mt-2 text-gray-500'>Collection:{targets[0]?.collection}</h1>
      <h1 className=' text-right text-sm font-normal mr-4 text-gray-500'>Target Collection :{targets[0]?.target}</h1> */}
        <div className='text-center mt-2'>



          <LineCharts details={details} summary={eleven_to_twenty_t_n_a} color='rgb(2 132 199)' textColor='text-sky-600' />
        </div>
      </div>
      <div className=''>

        <ChartsSummary datas={summaryBucket_2} color='text-sky-600' targets={targetBucket_2} details={details} />



      </div>
    </>
  )
}

export default BitwiseChartElevenToTwenty;
