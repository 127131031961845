import { AchievedJobSummaryMainState } from '../index'
import { ReportTable } from './columns'
import React, { ReactElement, ReactNode, ReactPropTypes } from 'react'
import { Link, Outlet } from 'react-router-dom'


interface props extends ReportTable {
    children: ReactNode
}

const RedirectToUserActivity = ({
    id, bym, children
}: props) => {
    const { state } = AchievedJobSummaryMainState()
    return (
        <>
            <Link to={`/dashboard/dv/collection-summary?job_id=${id}&month=${bym}`}>
                {children}
            </Link>

        </>
    )
}

export default RedirectToUserActivity