import React, { useEffect, useMemo, useState } from 'react'
import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useQueryResponse } from '../Core/QueryResponseProvider';
import { Button } from 'src/components/ui/button';
import { cn } from 'src/helpers';
import { useQueryResponseLoading } from '../index';


interface LineChartsProps {
    details: any,
    summary?: any,
    color: string,
    textColor?: string

}

export interface XaxisVal {
    actual_collection: number
    actual_points: number
    bonus_points: number
    cumulative_activity_achieved: string
    cumulative_collection: string
    dt: string
    dump_data_achieved: string
    final_points: number
    match: number
    total_points: number
    user_id: number
}

const SingleLineChart = ({ details, summary, color, textColor }: LineChartsProps) => {

    const [opacity, setOpacity] = useState<any>({
        performancePoints: 1,
        bonusPoints: 1,
        totalPoints: 1,
    });
    const { response } = useQueryResponse();
    const apiResponse: any = useMemo(() => response, [response])
    const isLoading = useQueryResponseLoading();
    const tableData: any = useMemo(() => isLoading ? [] : (apiResponse ? apiResponse?.data : []), [isLoading, apiResponse])

    const xAxis = useMemo(() => details ? details : [], [details])
    const target = useMemo(() => summary ? summary[0]?.target : 0, [summary])
    const axisData12 = useMemo(() => isLoading ? [] : (tableData ? tableData?.y_max : []), [tableData, isLoading])


    const valueMax = useMemo(() => axisData12 ? axisData12[axisData12?.length - 1].yAxisxValue : 0, [axisData12])

    const xAxisValues = useMemo(() => {
        return xAxis.map((cur: XaxisVal) => ({
            "xAxisValue": Number(((Number(cur.cumulative_collection) / Number(target)) * 100).toFixed(2)),
            // "xAxisValue": (Number(cur.cumulative_collection)).toFixed(2),
            "Performance Points": cur?.actual_points.toFixed(2),
            "Bonus Points": cur?.bonus_points.toFixed(2),
            "Achived Points ": Number(cur?.final_points).toFixed(2),
            "Date": cur?.dt
        }));
    }, [xAxis]);

    // console.log(xAxisValues)

    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip text-left">
                    {/* <p>{`Percentage: ${label * 100}`}</p> */}

                    {/* {payload.map((point: any) => (
                        <p key={point.name} style={{ color: point.color }} className='text-xs font-medium '>
                            {`${point.name}: ${point.value}`}
                        </p>
                    ))} */}
                    <p className='text-sm font-medium ' style={{ color: `#E5E7EB` }}> {payload[0].name === undefined ? "" : (`Base Line: ${payload[0].value}`)} </p>
                    <p className='text-sm font-medium ' style={{ color: `#8b0a50` }}>{payload[0].payload['Performance Points'] === undefined ? "" : (`Performance Points: ${payload[0].payload['Performance Points']}`)}</p>
                    <p className='text-xs font-medium ' style={{ color: `${color}` }}>{payload[0].payload.Date === undefined ? "" : (`Date: ${payload[0].payload.Date}`)}</p>
                    <p className='text-xs font-medium ' style={{ color: `${color}` }}>{payload[0].payload.xAxisValue === undefined ? "" : (`Achived Percentage: ${payload[0].payload.xAxisValue}%`)}</p>
                    {/*  */}
                </div>
            );
        }

        return null;
    };



    return (
        <>
            <LineChart
                width={350}
                height={280}
                margin={{
                    top: 5,
                    right: 10,
                    left: 10,
                    bottom: 5,
                }}
                data={axisData12}
            >
                <XAxis
                    dataKey='xAxisValue'
                    type='number'
                    style={{ "fontSize": "12px" }}

                    domain={[0, 100]} tickCount={15}
                />

                <YAxis
                    style={{ "fontSize": "12px" }}
                    domain={[0, valueMax]}
                    dataKey='yAxisValue'
                    tickCount={35} />

                <CartesianGrid stroke="#ccc" />

                <Tooltip content={<CustomTooltip />} />


                <Line
                    type="monotone"
                    dataKey="yAxisxValue"
                    name="Base Line"
                    stroke="rgb(229 231 235)"
                    legendType='none'
                    data={axisData12} />

                <Line
                    type="monotone"
                    data={xAxisValues}
                    dataKey='Performance Points'
                    stroke='#8b0a50'
                    strokeWidth={1}
                    opacity={opacity.performancePoints} />
                {/* 
                <Line
                    type="monotone"
                    data={xAxisValues}
                    dataKey='Bonus Points'
                    stroke={color}
                    strokeWidth={2}
                    opacity={opacity.bonusPoints} /> */}

                <Line
                    type="monotone"
                    data={xAxisValues}
                    dataKey='Achived Points '
                    stroke='#657939'
                    strokeWidth={1}
                    opacity={opacity.totalPoints} />

            </LineChart>
            <div className='flex space-x-6 px-4 justify-center items-center my-1'>
                <span onClick={() => {
                    setOpacity((prevOpacity: any) => ({
                        ...prevOpacity,
                        performancePoints: prevOpacity.performancePoints === 1 ? 0 : 1,
                    }));
                }}
                    className={cn('cursor-pointer text-xs hover:underline text-pink-600 font-medium', { 'text-gray-400': opacity.performancePoints === 0 })}>Peformance Points</span>

                {/* <span onClick={() => {
                    setOpacity((prevOpacity: any) => ({
                        ...prevOpacity,
                        bonusPoints: prevOpacity.bonusPoints === 1 ? 0 : 1,
                    }));
                }}
                    className={cn(`cursor-pointer text-xs hover:underline ${textColor} font-medium`, { 'text-gray-400': opacity.bonusPoints === 0 })}>Bonus Points</span> */}

                <span onClick={() => {
                    setOpacity((prevOpacity: any) => ({
                        ...prevOpacity,
                        totalPoints: prevOpacity.totalPoints === 1 ? 0 : 1,
                    }));
                }}
                    className={cn('cursor-pointer text-xs hover:underline text-lime-700 font-medium', { 'text-gray-400': opacity.totalPoints === 0 })}>Achived Points</span>
            </div>

        </>
    )
}

export default SingleLineChart



