import React, { useEffect, useMemo, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState, VisibilityState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { DataTablePagination } from './data-table-pagination';

import { ScrollArea } from 'src/components/ui/scroll-area';
import { Loader2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';

import { reward_summury_table, rewardsReducerState, setPaggination } from 'src/redux/slicer/rewardsDashboard';

type Props = {}
interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}


const RewarsTable = <TData, TValue>({ data, columns }: DataTableProps<TData, TValue>) => {
  const { rewardsTableLoading, items_per_page, page, total_records, total } = useSelector((state: RootState) => rewardsReducerState(state))
  const dispatch = useDispatch()
  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 50,
    })

  useEffect(() => {
    // console.log({ pageIndex, pageSize });

    dispatch(setPaggination({ pageIndex: pageIndex + 1, pageSize }))
    dispatch(reward_summury_table())
    return () => { }
  }, [pageIndex, pageSize])

  const pagination = React.useMemo(
    () => ({
      pageIndex: page - 1,
      pageSize: items_per_page,
    }),
    [page, items_per_page]
  )

  const pageCounts = useMemo(() => total ? Number(total) : 1, [total])

  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination: {
        pageIndex: 0,
        pageSize: 1000
      }
    },
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  return (
    <>
      <div className='w-full mt-4  overflow-scroll static top-0 left-0 right-0'>

        <ScrollArea className="whitespace-nowrap  w-full h-[500px] relative rounded-md ">
          <Table className="w-full text-sm  rtl:text-right text-gray-500 dark:text-gray-400 ">
            <TableHeader className=" text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-center hover:bg-gray-400  sticky top-0 left-0 right-0 table-auto">
              {table.getHeaderGroups().map((headerGroup) => <TableRow key={`${headerGroup.id}`} className='w-full'>

                {headerGroup.headers.map((header) => {
                  const columnRelativeDepth = header.depth - header.column.depth;
                  if (
                    !header.isPlaceholder &&
                    columnRelativeDepth > 1 &&
                    header.id === header.column.id
                  ) {
                    return null;
                  }

                  let rowSpan = 1;
                  if (header.isPlaceholder) {
                    const leafs = header.getLeafHeaders();
                    rowSpan = leafs[leafs.length - 1].depth - header.depth;
                  }
                  return (
                    <TableHead key={header.id}
                      colSpan={header.colSpan}
                      rowSpan={rowSpan}
                      className="text-center cursor-default text-gray-900 text-xs font-bold" scope='column'>
                      {header.isPlaceholder ? null :
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}


              </TableRow>)}

            </TableHeader>

            <TableBody
              className="">
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    // onClick={() =>handleClick(row.original)}
                    className='odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 hover:bg-gray-200' >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className=" text-left overflow-clip" scope="row" >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="w-full h-24 text-center">
                    {rewardsTableLoading ? <div className='w-full flex justify-center items-center '>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
                    </div> : "No results."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollArea>

      </div>
      {/* <div className='mt-2 w-full'>
        <DataTablePagination table={table} />
      </div> */}
    </>
  )
}

export default RewarsTable

