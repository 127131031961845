import axios, { AxiosResponse } from 'axios'
import { SUMMARY_BY_ACTIVITY } from 'src/service/apis'

const getSummuryByActivity = (query: string,) => {
    return axios
        .get(`${SUMMARY_BY_ACTIVITY}?${query}`
           

        )
        .then((d: AxiosResponse<any>) => d.data)
}

export { getSummuryByActivity }