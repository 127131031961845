import { useEffect, useMemo, useState } from 'react';
import { columnsCollection } from '../Context/TableComponent/columnsCollection';

import DateMothFilter from '../../TableFilters/FilterComp/DateMothFilter';
import { MainCollectionSummaryState } from '../Context';
import { useQueryResponseData } from '../Context/Core/QueryResponseProvider';
import CollectionTable from '../Context/TableComponent/CollectionTable';
import { Calendar } from '@/ui/calendar';
import { useLocation } from 'react-router-dom';
import Loader from '../../Loader/Loader';
import { useQueryResponseLoading } from '../Context/Core/QueryResponseProvider'
import { Button } from 'src/components/ui/button';
import { COLLECTION_SUMMARY } from 'src/service/apis';
import { stringifyObjectToRequestQuery } from 'src/helpers/index';
import { FileDown } from 'lucide-react';
import JobIdDropDown from '../../TableFilters/FilterComp/JobIdDropDown';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer';
import { useQueryResponse } from '../Context/Core/QueryResponseProvider';


interface Data {
  activity: string,
  total_dump_data_achieved: string,
  total_cumulative_activity_achieved: string,
  is_match: number,
  dt: string,
  bym: string
}
interface CollectionSummaryResponse {
  code: number,
  status: boolean,
  data: Data[] | []

}
type Props = {}
const CollectionSummaryTable = (props: Props) => {
  const [monthFilter, setMonthFilter] = useState<string>("")
  const [jobId, setJobId] = useState<number>(0)
  const { state, updateState } = MainCollectionSummaryState();
  const { response } = useQueryResponse()

  const isLoading = useQueryResponseLoading();

  const apiResponseData: any = useMemo(() => response, [response])
  const tableData: any = useMemo(() => isLoading ? [] : (apiResponseData ? apiResponseData?.data : []), [isLoading, apiResponseData])
  const data = useQueryResponseData();
  const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state))

  // console.log(data)


  const { search } = useLocation();

  useEffect(() => {
    const params = search && search?.split('?')[1].split("&");
    // console.log({ params });
    let searchParams: any = {};

    params && params.forEach((o: any) => {
      let splitParam = o?.split("=");
      searchParams[splitParam[0]] = splitParam[1];
    });

    setMonthFilter(searchParams?.month)
    setJobId(searchParams?.job_id)

    return () => { }
  }, [search])

  useEffect(() => {
    monthFilter !== "" && jobId !== 0 &&
      updateState({
        filter: {
          "bym": monthFilter,
          "job_id": jobId
        }
      });

  }, [monthFilter, jobId])

  const handleOnClickExport = () => {

    let table_query = { export_excel: 'download' };
    const newstate = state?.filter && JSON.parse(JSON.stringify(state?.filter));
    // console.log({ newstate });
    // // delete newstate["_fkey"];
    delete newstate["items_per_page"];
    delete newstate["page"];
    table_query = { ...table_query, ...newstate }
    console.log(table_query);

    // query = stringifyObjectToRequestQuery(table_query);
    const queryString = stringifyObjectToRequestQuery(table_query);
    // console.log({ queryString });

    const baseUrl = COLLECTION_SUMMARY + "?" + queryString;
    // console.log(baseUrl);
    var win: any = window.open(baseUrl, '_blank');
    win.focus();

  }

  // console.log(jobId,"job id")

  return (
    <>
      <div className={`mt-4 ${!dashBoardState ? 'ml-20  duration-300' : 'ml-60 duration-300'}`}>
        {/* <h1 className=' text-gray-700 font-bold text-xl my-1 ml-5'> Summary Report</h1> */}
        <h2 className='text-gray-700 font-medium text-lg mt-2 ml-5'> Summary By Month/Year</h2>
        <div className='w-full flex justify-between items-center px-6 mt-2'>
          <div className='flex items-center space-x-6'>
            <h1 className='text-sm font-medium text-gray-500 '><span className='text-gray-700'> #{jobId}</span></h1>
            <h1 className='text-sm font-medium text-gray-500 '><span className='text-gray-700'>{monthFilter}</span></h1>
          </div>
          {/* <div className='w-1/6'>
            <h3 className=' text-center text-sm text-gray-800 font-medium'>Select Job Id</h3>
            <JobIdDropDown setJobId={setJobId} jobId={jobId} loading={isLoading}/>
            
          </div>
          <div className='w-1/5 mt-2'>
            <h3 className='-mb-2 text-center text-sm text-gray-800 font-medium'>Select Month/Year</h3>
            <DateMothFilter setMonthFilter={setMonthFilter} monthYear={monthFilter} loading={isLoading}/>
           
          </div> */}

          <Button
            variant='secondary'
            onClick={handleOnClickExport}
            className='bg-white '
            disabled={isLoading}> <FileDown /></Button>
        </div>


        <CollectionTable columns={columnsCollection} data={tableData || []} />
      </div>

    </>
  )
}

export default CollectionSummaryTable;

