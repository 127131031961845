import axios, { AxiosResponse } from 'axios'
import { GET_TARGET_OVERFLOW_BY_JOB_ID} from 'src/service/apis'

const getOverflowJobStausById = (query: string) => {
  return axios
    .get(`${GET_TARGET_OVERFLOW_BY_JOB_ID}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
}

export {  getOverflowJobStausById }

