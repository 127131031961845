import axios, { AxiosResponse } from "axios";
import {
  GET_ALL_JOB_ID,
  GET_ALL_PLAYERS_USERID,
  GET_DASHBOARD_EMPLOYEE_REWARDS_TABLE,
  GET_DASHBOARD_SUMMARY_API,
  GET_FAIL_JOB_MAKE_SUCCESS,
  OFFER_SUMMARY,
  OFFER_SUMMARY_REPORT,
  OFFER_UI,
  POINTS_EXCEL_CALCULATION,
  POINTS_EXCEL_CALCULATION_IS_ON_TIME,
} from "./apis";

export function pointExcelCalculation(
  postData: any
): Promise<AxiosResponse<any>> {
  return axios.post(POINTS_EXCEL_CALCULATION, postData).then(
    (d: AxiosResponse) =>
      // d.data.daily_data
      d.data
  );
}

export function pointExcelIsOnTime(postData: any): Promise<AxiosResponse<any>> {
  return axios.post(POINTS_EXCEL_CALCULATION_IS_ON_TIME, postData).then(
    (d: AxiosResponse) =>
      // d.data.daily_data
      d.data
  );
}

export function getAllPlayerUserId(): Promise<AxiosResponse<any>> {
  return axios.get(GET_ALL_PLAYERS_USERID).then((d: AxiosResponse) => d.data);
}

export function getAllJobId(): Promise<AxiosResponse<any>> {
  return axios.get(GET_ALL_JOB_ID).then((d: AxiosResponse) => d.data);
}

export function getFailJobMakeSuccess(
  job_id: number
): Promise<AxiosResponse<any>> {
  return axios
    .get(`${GET_FAIL_JOB_MAKE_SUCCESS}?job_id=${job_id}`)
    .then((d: AxiosResponse) => d.data);
}

export function getSummary(query: string): Promise<AxiosResponse<any>> {
  return axios
    .get(`${GET_DASHBOARD_SUMMARY_API}?${query}`)
    .then((d: AxiosResponse) => d.data);
}

export function getHolidaySummary(query: string): Promise<AxiosResponse<any>> {
  return axios
    .get(`${OFFER_SUMMARY_REPORT}?${query}`)
    .then((d: AxiosResponse) => d.data);
}

export function getHolidayOfferUi(query: string): Promise<AxiosResponse<any>> {
  return axios.get(`${OFFER_UI}?${query}`).then((d: AxiosResponse) => d.data);
}

// export function getHolidayOfferTableUi(
//   query: string
// ): Promise<AxiosResponse<any>> {
//   return axios
//     .get(`${OFFER_SUMMARY}?${query}`)
//     .then((d: AxiosResponse) => d.data);
// }

export function getHolidayOfferTableUi(
  query: string
): Promise<AxiosResponse<any>> {
  return axios
    .get(`${OFFER_SUMMARY}?${query}`)
    .then((d: AxiosResponse) => d.data);
}

export function getRewardsTable(query: string): Promise<AxiosResponse<any>> {
  return axios
    .get(`${GET_DASHBOARD_EMPLOYEE_REWARDS_TABLE}?${query}`)
    .then((d: AxiosResponse) => d.data);
}
