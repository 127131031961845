import RewarsTable from '../Context/DetailsComponents/RewarsTable';
import { useQueryResponse } from '../Context/Core/QueryResponseProvider';
import { useQueryResponseLoading, RewardsDetailsReportMainState } from '../Context/index';
import React, { useEffect, useMemo } from 'react'
import { useSelector } from 'react-redux';
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer';
import { rewardsReducerState } from 'src/redux/slicer/rewardsDashboard';
import { RootState } from 'src/redux/store';
import { columnDetailReport } from '../Context/DetailsComponents/columnDetailReport';

const RewardsDetailsReport = () => {
    const { dashBoardState, monthYear } = useSelector((state: RootState) =>
        dashboardReducerState(state)
    );
    const { state, updateState } = RewardsDetailsReportMainState();
    const { user_id } = useSelector((state: RootState) => rewardsReducerState(state));
    console.log(user_id)

    useEffect(() => {
        user_id !== 0 &&
            updateState({
                filter: {
                    bym: monthYear,
                    userId: user_id
                }
            })
    }, [monthYear, user_id])

    const { response }: any = useQueryResponse();
    const isLoading = useQueryResponseLoading();
    const apiResponse: any = useMemo(() => response, [response]);
    const tableData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? apiResponse?.data : []),
        [isLoading, apiResponse]
    );
    return (
        <div className={`w-full mt-2 px-4 ${!dashBoardState ? "ml-20  duration-300" : "ml-60 duration-300"
            }`}>
            <h1 className="text-gray-700 font-medium text-lg mt-2 ml-5  sticky top-0">
                Daily Value & Points Analysis
            </h1>
            <div className='w-full'>
                <RewarsTable columns={columnDetailReport} data={tableData || []} />
            </div>

        </div>
    )
}

export default RewardsDetailsReport