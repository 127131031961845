import { useQueryResponse } from './Context/Core/QueryResponseProvider'
// import DistrictFilter from '../DropDown'
import { QueryRequestProvider, QueryResponseProvider, DistrictDropDownMainState } from './Context'

import React, { useMemo, useState } from 'react'
import { useQueryResponseLoading } from './Context/index'
// import DropDown from '../DropDown'
import { stat } from 'fs'
import { useDispatch, useSelector } from 'react-redux'
import FormikSelectPicker2 from 'src/modules/TableFilters/FilterComp/SearchBarComp/FormikSelectPicker2'
import { dashboardReducerState, districtIdState } from 'src/redux/slicer/dashboardSlicer'
import ta from 'date-fns/esm/locale/ta/index.js'
import { RootState } from 'src/redux/store'
import { rewardsReducerState } from 'src/redux/slicer/rewardsDashboard'
// import { districtDropdownState } from 'src/redux/Slicer/dropdownSlicer'



type Props = {}

const Main = (props: Props) => {
    const [selecteVal, setSelectVal] = useState<string>('')
    const { response } = useQueryResponse();
    const isLoading = useQueryResponseLoading();
    const { state, updateState } = DistrictDropDownMainState();
    const apiResponse: any = useMemo(() => response, [response]);

    const { districtId } = useSelector((state: RootState) =>
        dashboardReducerState(state)
    );

    const { rewardsTableLoading, summaryLoading } = useSelector((state: RootState) => rewardsReducerState(state))

    const tableData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? (apiResponse?.data ? apiResponse?.data.filter((cur: string) => cur !== '') : []) : []),
        [apiResponse, isLoading]
    );
    const dispatch = useDispatch()



    const dropDataUserName: any = useMemo(() => tableData && Array.isArray(tableData) ? (tableData.map((cur) => {
        return {
            value: cur.code,
            label: cur.name
        }
    })) : [], [tableData])

    const val = useMemo(() => districtId !== undefined ? tableData.find((cur: any) => cur.id === districtId) : {}, [districtId])
    const value = useMemo(() => Object.entries(val)?.length > 0 ? val.code : undefined, [val]);


    return (
        <div className='w-full'>
            <h3 className=" text-center text-sm text-gray-800 font-medium -mb-2">
                Select District
            </h3>
            <FormikSelectPicker2 options={dropDataUserName}

                onchange={(e) => {
                    setSelectVal(e);
                    let data = e !== undefined && e !== '' ? tableData.find((cur: any) => cur.code === e) : {}
                    let id = Object.entries(data)?.length > 0 ? data?.id : null
                    // console.log(id)
                    if (id !== null) {
                        dispatch(districtIdState(id))
                    }

                }}

                disabled={isLoading || rewardsTableLoading || summaryLoading}
                value={value !== undefined ? value : ''}
            />
        </div>
    )
}

const DistrictMain: React.FC<Props> = ({ }) => {

    return <>
        <QueryRequestProvider >
            <QueryResponseProvider >
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default DistrictMain
