

import { ColumnDef } from "@tanstack/react-table"
import { Badge } from "src/components/ui/badge"
import AchieveJobSummaryId from "../TableCom/AchieveJobSummaryId"
import Duration from "../TableCom/Duration"
import RedirectToUserActivity from "../TableCom/RedirectToUserActivity"
import dayjs from 'dayjs';
export type ReportTable = {
  id: number,
  start_date: string,
  end_date: string,
  is_success: number
  bym: string,
  dt: string
}


export const columns: ColumnDef<ReportTable>[] = [
  {
    accessorKey: "id",
    header: "Job Id",
    cell({ row: { original } }) {
      return <RedirectToUserActivity {...original}>
        <>{original?.id || ""}</>
      </RedirectToUserActivity>
    },
  },
  {
    accessorKey: "dt",
    header: "Date",
    cell: (({ row }) => {
      let startDate: string = row.getValue('dt');

      return dayjs(startDate).format('MMM DD YYYY')
    })
  },
  {
    accessorKey: "start_date",
    header: "Start ts",
    cell: (({ row }) => {
      let startDate: string = row.getValue('start_date');

      return dayjs(startDate).format('hh:mm:ss A ')
    })
  },


  {
    id: "duration",
    header: "Duration",
    cell: ({ row: { original } }) => {
      return <Duration {...original}
      />
    }
  },

  {
    accessorKey: "bym",
    header: "Month-Year",
  },
  {
    accessorKey: "is_success",
    header: "status",

    cell: ({ row: { original } }) => {
      return <AchieveJobSummaryId {...original}
      />
    }

  },
  {
    id: "action",
    header: "Action",
    cell: ({ row: { original } }) => {
      return <RedirectToUserActivity {...original}>

        <>
          <Badge>Go</Badge>
        </>


      </RedirectToUserActivity>
    },
  }
]
