import React, { useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useLocation } from "react-router-dom"
import { toast } from "react-toastify"

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select"
import { dropDownReducerState, jobIdDropData, userIdDropData } from "src/redux/slicer/dropDownSlicer"

import { RootState } from "src/redux/store"
import { number, string } from "yup"
import { any } from "zod"

interface JobIdProps {
  setUserId: (value: number) => void;
  searchId?: number;
  names?: string;
  loadings?: boolean
}

interface Users {

  user_id: number,
  name: string

}
const UserIdDropDown = ({ setUserId, searchId, names, loadings }: JobIdProps) => {
  const { userIdData, jobIdData, loading, error, message } = useSelector((state: RootState) => dropDownReducerState(state))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userIdDropData())
  }, [])


  const _idData: any = useMemo(() => loading ? [] : (userIdData ? userIdData : []), [loading, userIdData])

  useEffect(() => {
    if (error) {
      toast.error(message)
    }
    return () => { }
  }, [error, message])

  // console.log(_idData)

  return (
    <Select onValueChange={(value: string) => setUserId(Number(value))}
      defaultValue={searchId !== undefined ? searchId.toString() : ""}
      value={searchId !== undefined ? searchId.toString() : ""}
      disabled={loading}>
      <SelectTrigger>
        <SelectValue className="text-xs font-medium text-gray-700" placeholder={names} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel className="text-xs font-medium text-gray-700">{names}</SelectLabel>

          {_idData.map((cur: any) => {
            return <SelectItem
              className="text-xs font-medium text-gray-700"
              value={cur?.user_id.toString()} key={`${cur?.name}`}>{cur?.name}{"  "}({cur?.user_id})</SelectItem>
          })}

        </SelectGroup>

      </SelectContent>
    </Select>
  )
}

export default UserIdDropDown