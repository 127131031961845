import { JobStatusMainState } from '../index'
import { TargetOverflowTable } from './jobStatusColumns'
import React, { ReactElement, ReactNode, ReactPropTypes } from 'react'
import { Link } from 'react-router-dom'


interface props extends TargetOverflowTable {
    children: ReactNode
}

const RedirectToUserActivity = ({
    children, bym, batch_job_id,
}: props) => {
    const { state } = JobStatusMainState()
    return (
        <Link to={`/dashboard/job-status/js/job-status-by-id?job_id=${batch_job_id}&month=${bym}`}>
            {children}
        </Link>
    )
}

export default RedirectToUserActivity