import { useDispatch, useSelector } from 'react-redux'
import OfferSummary from '../Offer/OfferComponent/OfferSummary'
import React, { useEffect, useMemo, useState } from 'react'
import { OfferUIProps, offerReducerState, offer_Summary_data, offer_summary_api_call, offer_summury_table, offer_ui_data, updateDropdownCategoryState, updateDropdownOfferState } from 'src/redux/slicer/offerSlicer'
import DropDown from 'src/modules/TableFilters/FilterComp/DropDown'
import { RootState } from 'src/redux/store'
import { Button } from 'src/components/ui/button'
import { FileDown } from 'lucide-react'
import OfferTable from '../Offer/OfferComponent/OfferTable'
import { columnOffer } from './OfferComponent/columnOffer'
import { converMonthYearToMonthYearNo, stringifyObjectToRequestQuery } from 'src/helpers/index'
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer'
import { OFFER_SUMMARY } from 'src/service/apis'
import { convertQueryStringToObject } from './OfferComponent/modulesOffer'
import { toast } from 'react-toastify'

const OfferMain = () => {

    const { offerUIData, offerUiLoading, summaryLoading, tableData, tableLoading, dropdownCategoryState, dropdownOfferState, error, message, items_per_page, page } = useSelector((state: RootState) => offerReducerState(state))

    const { districtId, monthYear } = useSelector((state: RootState) => dashboardReducerState(state))

    const [offers, setOffers] = useState<string>('');
    const [category, setCategory] = useState<string>('')
    const dropDownData: OfferUIProps = useMemo(() => offerUIData ? offerUIData : { date: [], category: [], offer_names: [] }, [offerUIData]);

    const categoty: string[] | [] = useMemo(() => dropDownData ? (Array.isArray(dropDownData.category) && dropDownData.category.length > 0 ? dropDownData.category : []) : [], [dropDownData])

    const offer_names: string[] | [] = useMemo(() => dropDownData ? (Array.isArray(dropDownData.offer_names) && dropDownData.offer_names.length > 0 ? dropDownData.offer_names : []) : [], [dropDownData])
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(offer_summary_api_call())
    }, [])

    useEffect(() => {
        if (error) {
            toast.error(message)
        }
        return () => { }
    }, [error, message])

    const data = useMemo(() => tableLoading ? [] : (tableData), [tableData, tableLoading])

    const offerChange = (val: string) => {
        setOffers(val)
        dispatch(updateDropdownOfferState(val))
    }
    const categoryChange = (val: string) => {
        setCategory(val)
        dispatch(updateDropdownCategoryState(val))
    }

    useEffect(() => {
        dropdownCategoryState !== undefined &&
            dispatch(offer_summury_table())

    }, [dropdownCategoryState])


    useEffect(() => {
        dropdownOfferState !== undefined &&
            dispatch(offer_summury_table())

    }, [dropdownOfferState])



    const handleOnClickExport = () => {
        let table_query = { download: 'excel' };
        let dattes = stringifyObjectToRequestQuery(converMonthYearToMonthYearNo(monthYear?.toUpperCase()));
        let month = convertQueryStringToObject(dattes)
        let obj = {
            page: page,
            items_per_page: items_per_page,
            offer_name: dropdownOfferState,
            activity: dropdownCategoryState,
            ...month
        }

        const newstate = obj && { ...obj } //JSON.parse(JSON.stringify(obj));
        // console.log({ newstate });
        // // delete newstate["_fkey"];
        // delete newstate["items_per_page"];
        // delete newstate["page"];
        table_query = { ...table_query, ...newstate };

        // query = stringifyObjectToRequestQuery(table_query);
        const queryString = stringifyObjectToRequestQuery(table_query);
        // console.log({ queryString });

        const baseUrl = OFFER_SUMMARY + "?" + queryString;
        // console.log(baseUrl);
        var win: any = window.open(baseUrl, "_blank");
        win.focus();
    }
    return (
        <div className='w-full'>
            <div className='flex justify-end space-x-4  w-full   py-2 my-2'>
                <div className='-mt-6'>   <DropDown isLoading={offerUiLoading} jsonData={offer_names || []} onChange={offerChange} name='Search by offer' value={dropdownOfferState} /></div>
                <div className='-mt-6'><DropDown isLoading={offerUiLoading} jsonData={categoty || []} onChange={categoryChange} name='Search by category' value={dropdownCategoryState} /></div>

                <Button variant='outline' onClick={handleOnClickExport} disabled={offerUiLoading || summaryLoading}> <FileDown /></Button>
            </div>
            <OfferSummary />
            <h1 className="text-base font-bold text-gray-800 ml-6 my-4" >Report</h1>
            <OfferTable data={data || []} />
        </div>
    )
}

export default OfferMain