import React from 'react'
import { QueryRequestProvider, QueryResponseProvider, } from './Context'

import SummaryTable from '../../modules/SummaryByActivity/Component/SummaryTable'
import RewardsDetailsReport from './Component/RewardsDetailsReport'



type Props = {}

const Main = (props: Props) => {
    return (
        <div className='mt-14 w-full'>
            <RewardsDetailsReport />
        </div>
    )
}

const RewardsDetailsReportMains: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider >
            <QueryResponseProvider >
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default RewardsDetailsReportMains