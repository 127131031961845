import SummaryBoxes from 'src/components/ui/common/SummaryBoxes';
import React, { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { reward_summary_data, rewardsReducerState } from 'src/redux/slicer/rewardsDashboard';
import { RootState } from 'src/redux/store';

const DashboardSummary = () => {
    const { error, message, summmaryData, summaryLoading } = useSelector(
        (state: RootState) => rewardsReducerState(state)
    );

    let obj = {
        collection_coverage_summary: 0,
        collection_efficeincy_summary: 0,
        desconnection_summary: 0,
        event_summary: 0,
        is_on_time_summary: 0,
        reconnection_summary: 0,
        total_customers: 0,
        total_summary: 0
    }

    // console.log(summmaryData)


    const summary: any = useMemo(
        () =>
            summaryLoading
                ? {
                    playerSummary: obj
                }
                : summmaryData && Object.keys(summmaryData).length > 0
                    ? summmaryData
                    : {
                        playerSummary: obj
                    },
        [summaryLoading, summmaryData]
    );


    const total_summary = useMemo(
        () =>
            Object.keys(summary)?.length > 0
                ? summary?.playerSummary?.total_summary
                    ? summary?.playerSummary?.total_summary?.toFixed(2)
                    : 0
                : 0,
        [summary]
    );



    const collection_coverage_summary = useMemo(
        () =>
            Object.keys(summary)?.length > 0
                ? summary?.playerSummary?.collection_coverage_summary !== null
                    ? summary?.playerSummary?.collection_coverage_summary?.toFixed(2)
                    : 0
                : 0,
        [summary]
    );
    const collection_efficeincy_summary = useMemo(
        () =>
            Object.keys(summary)?.length > 0
                ? summary?.playerSummary?.collection_efficeincy_summary !== null
                    ? summary?.playerSummary?.collection_efficeincy_summary?.toFixed(2)
                    : 0
                : 0,
        [summary]
    );
    const desconnection_summary = useMemo(
        () =>
            Object.keys(summary)?.length > 0
                ? summary?.playerSummary?.desconnection_summary !== null
                    ? summary?.playerSummary?.desconnection_summary?.toFixed(2)
                    : 0
                : 0,
        [summary]
    );
    const event_summary = useMemo(
        () =>
            Object.keys(summary)?.length > 0
                ? summary?.playerSummary?.event_summary !== null
                    ? summary?.playerSummary?.event_summary?.toFixed(2)
                    : 0
                : 0,
        [summary]
    );
    const is_on_time_summary = useMemo(
        () =>
            Object.keys(summary)?.length > 0
                ? summary?.playerSummary?.is_on_time_summary !== null
                    ? summary?.playerSummary?.is_on_time_summary?.toFixed(2)
                    : 0
                : 0,
        [summary]
    );
    const reconnection_summary = useMemo(
        () =>
            Object.keys(summary)?.length > 0
                ? summary?.playerSummary?.reconnection_summary !== null
                    ? summary?.playerSummary?.reconnection_summary?.toFixed(2)
                    : 0
                : 0,
        [summary]
    );
    const total_customers = useMemo(
        () =>
            Object.keys(summary)?.length > 0
                ? summary?.playerSummary?.total_customers !== null
                    ? ` # ${summary?.playerSummary?.total_customers?.toFixed(2)}`
                    : ' #0'
                : '# 0',
        [summary]
    );
    return (
        <div className="py-2 space-y-1">
            <h1 className="text-base font-bold text-gray-800 ml-6">Summary</h1>
            <div className="flex space-x-3">
                <SummaryBoxes titles="Total" summaryTotal={total_summary} customer={total_customers} loading={summaryLoading} />
                <SummaryBoxes titles="CE" summaryTotal={collection_efficeincy_summary} loading={summaryLoading} />
                <SummaryBoxes titles="CC" summaryTotal={collection_coverage_summary} loading={summaryLoading} />
                <SummaryBoxes titles="Reconnection" summaryTotal={reconnection_summary} loading={summaryLoading} />
                <SummaryBoxes titles="Disconnection" summaryTotal={desconnection_summary} loading={summaryLoading} />
                <SummaryBoxes titles="Event" summaryTotal={event_summary} loading={summaryLoading} />
                <SummaryBoxes titles="IOT" summaryTotal={is_on_time_summary} loading={summaryLoading} />
            </div>
        </div>
    )
}

export default DashboardSummary