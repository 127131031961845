import { setDate } from 'date-fns';
import { useQueryResponse } from '../Core/QueryResponseProvider';
import { BitwiseUpdatedMainState } from '../index'
import React, { useEffect, useState } from 'react'


interface DetailedItem {
  user_id: number;
  dt: string;
  actual_collection: number;
  cumulative_collection: string;
  actual_points: number;
  bonus_points: number;
  total_points: number;
  final_points: number;
  dump_data_achieved: string | null,
  cumulative_activity_achieved: string | null,
  match: number | null
}


const DatesBoxes = ({ data, arrInd, ind, color }: any) => {

  const [dates, setDates] = useState<string[]>([]);
  const [currentMonthDates, setcurrentMonthDates] = useState<string[]>([]);
  const [actualPoints, setActualPointes] = useState<number[]>([])
  const { state } = BitwiseUpdatedMainState()

  let value: string = state ? state.filter?.bym : ''
  function getDaysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }
  const date = new Date(value);
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;


  const daysInCurrentMonth = getDaysInMonth(
    currentYear,
    currentMonth,
  );


  // console.log(data,"data")

  useEffect(() => {
    if (Array.isArray(data) && data?.length > 0) {

      const uniqueDates = new Set<string>(dates);
      const points = new Set<number>(actualPoints)
      data.forEach((cur: any) => {
        uniqueDates.add(cur?.dt);
        points.add(cur?.dump_data_achieved)
      });

      setDates(Array.from(uniqueDates));
      setActualPointes(Array.from(points));
    }
  }, [data]);

  //   console.log(dates,"dates")

  // console.log('actualPoints:', actualPoints);
  // console.log('typeof actualPoints:', typeof actualPoints);
  useEffect(() => {
    let arr = [];
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      arr.push(i.toString());
    }

    setcurrentMonthDates(arr);
  }, [daysInCurrentMonth])

// console.log(data)



  return (
    <div className='w-full flex space-x-2'>
      {currentMonthDates.slice(ind, arrInd).map((cur, index) => (
        <div key={index} className="">
          {dates.find((date, ind) => (Number(date.split('-')[2]) === parseInt(cur))
            && Number( data[data.findIndex((item: any) => item?.dt === date)]?.dump_data_achieved) !== 0 
            && data[data.findIndex((item: any) => item?.dt === date)]?.dump_data_achieved !== null ) ? (
            <div
              className={`w-6 h-6 border rounded-xm text-xs font-medium flex items-center justify-center rounded-sm ${color} text-white`}
            >
              {cur}
            </div>
          ) : (
            <div className={`w-6 h-6 border text-xs font-medium border-gray-500 rounded-xm flex items-center justify-center rounded-sm`}>
              {cur}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}

export default DatesBoxes