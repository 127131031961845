import {
  Line,
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";
import { useQueryResponse } from "../Core/QueryResponseProvider";
import React, { useMemo } from "react";
import { cn, convertToCamelCase } from "../../../../helpers";
// import { dataAxis } from './dataAxis';
import LineCharts from "./LineCharts";
import ChartsSummary from "./ChartsSummary";
import DatesBoxes from "../ChartComponent/DatesBoxes";
import { BitwiseUpdatedMainState } from "../index";
import { useQueryResponseLoading } from "../index";
type Props = {};

const BitwiseChartTwentyOneToEnd = () => {
  const { response } = useQueryResponse();
  const { state } = BitwiseUpdatedMainState();

  let value: string = state ? state.filter?.bym : "";

  function getDaysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }
  const date = new Date(value);
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;

  const daysInCurrentMonth = getDaysInMonth(currentYear, currentMonth);

  const isLoading = useQueryResponseLoading();
  const apiResponse: any = useMemo(() => response, [response]);

  const tableData: any = useMemo(
    () => (isLoading ? [] : apiResponse ? apiResponse?.data : []),
    [isLoading, apiResponse]
  );
  const summaryBucket_3: any = useMemo(
    () =>
      isLoading ? [] : tableData ? tableData?.last_ten_days_summary : [],
    [isLoading, tableData]
  );
  const targetBucket_3: any = useMemo(
    () => (isLoading ? [] : tableData ? tableData?.last_ten_days_t_n_a : []),
    [isLoading, tableData]
  );
  const details: any = useMemo(
    () => (isLoading ? [] : tableData ? tableData?.last_ten_days_detailed : []),
    [isLoading, tableData]
  );
  const last_ten_days_t_n_a: any = useMemo(
    () => (isLoading ? [] : tableData ? tableData?.last_ten_days_t_n_a : []),
    [isLoading, tableData]
  );

  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div
          className={cn("mt-2 px-1", {
            "px-4": daysInCurrentMonth <= 30 && daysInCurrentMonth !== 28,
            "px-12": daysInCurrentMonth === 28,
          })}
        >
          <DatesBoxes
            data={details}
            arrInd={daysInCurrentMonth}
            ind={20}
            color="bg-amber-600"
          />
        </div>

        <div className="text-center mt-2">
          <LineCharts
            details={details || []}
            summary={last_ten_days_t_n_a || []}
            color="rgb(217 119 6)"
            textColor="text-amber-600"
          />
        </div>
      </div>
      <div className="">
        <ChartsSummary
          datas={summaryBucket_3}
          color="text-amber-600"
          targets={targetBucket_3}
          details={details}
        />
      </div>
    </>
  );
};

export default BitwiseChartTwentyOneToEnd;
