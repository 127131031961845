import React from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom';
import { updateUserId } from 'src/redux/slicer/rewardsDashboard';
import { GET_PROFILE_PICTURE } from 'src/service/apis'

interface RankProps {
    name: string,
    employee_id: string,
    dist_name: string,
    profile_image: string,
    user_id: number,
}

const Player = ({ name, employee_id, dist_name, profile_image, user_id }: RankProps) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    return (
        <div className='flex items-center space-x-2 cursor-pointer' onClick={() => {
            dispatch(updateUserId(user_id));
            navigate('/dashboard/rewards/rr/report')
        }}>
            <img src={`${GET_PROFILE_PICTURE}${profile_image}`} className='w-10 h-10 rounded-full p-1' />
            <div className='text-left'>
                <h1 className='text-xs font-bold text-gray-800'>{name}</h1>
                <h1 className='text-gary-500 text-xs font-medium'>{dist_name}</h1>
                <h1 className='text-gary-500 text-xs font-medium'>#{employee_id}</h1>
            </div>
        </div>
    )
}

export default Player
