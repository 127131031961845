

import { cn } from 'src/helpers/index';
import { Menu, MenuOptions } from './modules';
import { Link, useLocation } from 'react-router-dom';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { useState } from "react";
import { current } from '@reduxjs/toolkit';
import { Badge } from 'src/components/ui/badge';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardReducerState, updateOpen } from 'src/redux/slicer/dashboardSlicer';
import { RootState } from 'src/redux/store';
const Sidebarlayout = () => {
  const [open, setOpen] = useState(true);

  const dispatch = useDispatch()
  const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state))

  // console.log(dashBoardState,"dashboard")
  const { pathname } = useLocation()



  const menuOptions = Menu;
  return (
    <div className="flex fixed z-40">
      <div
        className={` ${dashBoardState ? "w-60" : "w-20 "
          } bg-green-600 h-screen p-5  pt-8 relative duration-300`}
      >
        <div

          className={`ml-2 absolute cursor-pointer -right-3 top-9 w-8 h-8 text-center mt-8 border-purple-400
           border-2 rounded-full bg-white flex justify-center items-center`}
          onClick={() => {
            dispatch(updateOpen(!dashBoardState))
            setOpen(!open)
          }}
        >
          {dashBoardState ? (<ChevronLeft size={20} />) : (< ChevronRight size={20} />)}
        </div>


        <ul className={`pt-6 mt-10 space-y-10 ${!dashBoardState ? 'space-y-3' : ''}`}>
          {menuOptions.map((Menu, index) => (

            <div key={index} className=''>
              <h1 className={`text-gray-100 font-medium text-sm ${!dashBoardState ? 'hidden' : ''}`}>{Menu.heading}</h1>
              {Menu.options?.map((menuItem, itemIndex) => (
                <li
                  key={itemIndex}
                  className={`mt-1 rounded-md p-2 cursor-pointer hover:bg-light-white text-gray-200 text-xs items-center 
                  ${pathname.startsWith(menuItem?.path) ? 'text-white text-base font-bold' : ''}`}
                >
                  <Link to={menuItem.path} className='flex   items-center space-x-4'>
                    <span
                    >{menuItem.iconName(cn('text-gray-200', { "text-white font-bold": pathname === menuItem?.path || pathname.startsWith(menuItem?.path) }))}
                    </span>

                    <span className={`duration-300 transition-all ease-in-out origin-left ${!dashBoardState ? 'hidden' : ''}`}>
                      {menuItem.name}
                    </span>
                  </Link>
                </li>
              ))}
            </div>))}
        </ul>

      </div>

    </div>
  );
};

export default Sidebarlayout;
;
