// import { QueryRequestProvider,QueryResponseProvider } from './Context'

import { QueryRequestProvider } from './Context/Core/QueryRequestProvider'
import JobStatus from './Components/JobStatus';
import React from 'react'
import { QueryResponseProvider } from './Context/Core/QueryResponseProvider'



type Props = {}

const Main = (props: Props) => {
  return (
    <div className='mt-14 w-full'>
      
        <JobStatus/>
    </div>
  )
}

const JobStatusMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider >
            <QueryResponseProvider >
                    <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default JobStatusMain