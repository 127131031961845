import { QueryRequestProvider, QueryResponseProvider } from './Context'
import ReportTable from './Components/ReportTable'
import React from 'react'



type Props = {}

const Main = (props: Props) => {



  return (
    <div className='mt-14 w-full'>

      <ReportTable />

    </div>
  )
}

const AchievedJobSummaryMain: React.FC<Props> = ({ }) => {

  return <>
    <QueryRequestProvider >
      <QueryResponseProvider >
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  </>
}

export default AchievedJobSummaryMain

