

import { convertToCamelCase } from "src/helpers/index"
import UserIdCom from "../../Context/TableComponent/UserIdCom"
import { ColumnDef } from "@tanstack/react-table"
import { ArrowUpRightSquare, Check, X } from "lucide-react"
import { Badge } from "src/components/ui/badge"
import RedirectToUserActivity from "./RedirectToUserActivity"
import { GET_PROFILE_PICTURE } from "src/service/apis"
import ProfileImage from "../TableComponent/ProfileImage"


export type ReportTable = {
  user_id: number,
  total_dump_data_achieved: string,
  total_cumulative_activity_achieved: string,
  match: number,
  profile_image?: string,
  name: string
}

// console.log(GET_PROFILE_PICTURE)

export const columnsSummaryByActivity: ColumnDef<ReportTable>[] = [
  {
    accessorKey: "user_id",
    header: "User Id",
    cell({ row: { original } }) {
      return <RedirectToUserActivity {...original}>
        <div>{original?.user_id || ""}</div>
      </RedirectToUserActivity>
    },
  },
  {
    accessorKey: "name",
    header: "User",
    cell: ({ row: { original } }) => {
      return <ProfileImage {...original} />
    }
  },

  {
    accessorKey: "total_dump_data_achieved",
    header: "Data Received From TopUPP",
  },
  {
    accessorKey: "total_cumulative_activity_achieved",
    header: "Data In ERRS",
  },
  {
    accessorKey: "match",
    header: "Match",
    cell: ({ row }) => {
      const match = parseFloat(row.getValue("match"))

      if (match === 1) {
        return <Check className=" text-green-600 " />
        // return <Badge
        // className=" bg-transparent border-primary text-primary hover:bg-transparent hover:text-primary ">Success</Badge>
      } else {
        return <X className=" text-red-600 " />
        // return <Badge className=" bg-transparent border-destructive text-destructive hover:bg-transparent hover:text-destructive"  >Fail</Badge>
      }
    },
  },

  {
    id: "action",
    header: "Action",
    cell: ({ row: { original } }) => {
      return <RedirectToUserActivity {...original}>
        <div>
          <Badge>Go</Badge>
        </div>
      </RedirectToUserActivity>
    },
  }

]

