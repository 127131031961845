import SummaryBoxes from 'src/components/ui/common/SummaryBoxes'
import React, { useEffect, useMemo } from 'react'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer'
import { offerReducerState } from 'src/redux/slicer/offerSlicer'

import { offer_Summary_data, offer_ui_data } from 'src/redux/slicer/offerSlicer'
const OfferSummary = () => {
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(offer_Summary_data())

    }, [])
    const { holidaySummaryData, summaryLoading } = useSelector((state: RootState) => offerReducerState(state))

    const overall_offer_total: any = useMemo(
        () =>
            Object.keys(holidaySummaryData).length > 0
                ? holidaySummaryData?.overall_offer_total !== null
                    ? Intl.NumberFormat('en-US').format(holidaySummaryData?.overall_offer_total?.toFixed(2))
                    : 0
                : 0,
        [holidaySummaryData]
    );

    const participants_emp: string = useMemo(
        () =>
            Object.keys(holidaySummaryData).length > 0
                ? holidaySummaryData?.participants_emp !== null ? `# ${holidaySummaryData?.participants_emp}`
                    : `#0`
                : `#0`,
        [holidaySummaryData]
    );

    const total_register_emp: string = useMemo(
        () =>
            Object.keys(holidaySummaryData).length > 0
                ? holidaySummaryData?.total_register_emp !== null
                    ? `# ${holidaySummaryData?.total_register_emp?.toFixed(2)}`
                    : `#0`
                : `#0`,
        [holidaySummaryData]
    );

    return (
        <div className="w-full  space-y-1">
            <h1 className="text-base font-bold text-gray-800 ml-6">Summary</h1>
            <div className="w-full flex space-x-3">
                <SummaryBoxes titles="Total Players" customer={total_register_emp} loading={summaryLoading} />
                <SummaryBoxes titles="Partcipated Employees" summaryTotal={overall_offer_total} customer={participants_emp} loading={summaryLoading} />
            </div>
        </div>
    )
}

export default OfferSummary