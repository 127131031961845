
// import AchieveJobSummaryId from "../TableCom/AchieveJobSummaryId"


import { ColumnDef } from "@tanstack/react-table"
import { Check, Table, X } from "lucide-react"
import { ReactNode } from "react"
import TableCelldata from "./TableCelldata";
import { IPointscollection } from "../Core/modules";
import { original } from "@reduxjs/toolkit";
import { Input } from "src/components/ui/input";
import dayjs from "dayjs";



export const columnsForOtherActivity: ColumnDef<IPointscollection>[] = [
  {
    accessorKey: "dt",
    header: "Dates",
    cell: (({ row }) => {
      let startDate: string = row.getValue('dt');

      return dayjs(startDate).format('MMM DD YYYY')
    })

  },
  {
    accessorKey: "today_collection",
    header: "Collection Per Day",


    cell: ({ row: { original } }) => {
      return <TableCelldata {...original} />
    }
  },
  {
    accessorKey: "cumulative_collection",
    header: "Cumulative Collection",
    cell: ({ row }) => {
      return parseFloat(row.getValue("cumulative_collection")).toFixed(2)


    },
  },
  {
    accessorKey: "x",
    header: "Percentage",
    cell: ({ row }) => {
      return (parseFloat(row.getValue("x")) * 100).toFixed(2)
    },
  },
  {
    accessorKey: "actual_points",
    header: "Performance Points",
    cell: ({ row }) => {
      return parseFloat(row.getValue("actual_points")).toFixed(2)
    },
  },
  //   {
  //     accessorKey: "total_bonus",
  //     header: "Bonus Points",
  //   },
  {
    accessorKey: "total_points",
    header: "Achived Points",
    cell: ({ row }) => {
      return parseFloat(row.getValue("total_points")).toFixed(2)
    },
  },


]
