import LineCharts from './LineCharts';
import { useQueryResponse } from '../Core/QueryResponseProvider';
import { useQueryResponseLoading } from '../index';
import React, { useMemo } from 'react'
import { Button } from 'src/components/ui/button'
import { Input } from 'src/components/ui/input'
import { Label } from 'src/components/ui/label'
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover'
import SingleLineChart from './SingleLineChart';


const SingleGraphColEff = () => {

    const { response } = useQueryResponse();

    const isLoading = useQueryResponseLoading();
    const apiResponse: any = useMemo(() => response, [response])

    const tableData: any = useMemo(() => isLoading ? [] : (apiResponse ? apiResponse?.data : []), [isLoading, apiResponse])

    const details_1: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.first_to_ten_detailed : []), [isLoading, tableData])
    const t_n_a: any = useMemo(() => isLoading ? [] : (tableData && tableData.target ? [tableData.target] : []), [isLoading, tableData])
    const details_2: any = useMemo(
        () => (isLoading ? [] : tableData ? tableData?.last_ten_days_detailed : []),
        [isLoading, tableData]
    );
    const details_3: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.eleven_to_twenty_detailed : []), [isLoading, tableData])

    const details = useMemo(() => details_1 && details_2 && details_3 ? [...details_1, ...details_3, ...details_2] : [], [details_1, details_2, details_3])
    // console.log(details)
    // console.log(t_n_a)
    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button className="text-xs "
                    disabled={isLoading}>Single Graph</Button>
            </PopoverTrigger>
            <PopoverContent className="w-full bg-gray-100">
                {/* <LineCharts details={details} summary={t_n_a} color='rgb(2 132 199)' textColor='text-sky-600' /> */}
                <SingleLineChart details={details} summary={t_n_a} color='rgb(2 132 199)' textColor='text-sky-600' />

            </PopoverContent>
        </Popover>
    )
}

export default SingleGraphColEff
