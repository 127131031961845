import React, { useMemo } from "react";
import { useQueryResponse } from "../Context";
import ReportSummaryTable from "../Context/TableCom/ReportSummaryTable";
import { columns } from "../Context/TableCom/columns";
import { useQueryResponseLoading } from "../Context/core/QueryResponseProvider";
import Loader from "../../Loader/Loader";
import { useSelector } from "react-redux";
import { dashboardReducerState } from "src/redux/slicer/dashboardSlicer";
import { RootState } from "src/redux/store";
interface Data {
  id: number;
  uuid: string;
  start_date: string;
  end_date: string;
  is_success: number;
  bym: string;
  dt: string;
}
interface JobStatusResponse {
  code: number;
  status: boolean;
  data: Data[] | [];
}

const ReportTable = () => {
  const { response } = useQueryResponse();
  const isLoading = useQueryResponseLoading();
  const apiResponse: any = useMemo(() => response, [response]);
  const tableData: any = useMemo(
    () => (isLoading ? [] : apiResponse ? apiResponse?.data : []),
    [apiResponse, isLoading]
  );

  const { dashBoardState } = useSelector((state: RootState) =>
    dashboardReducerState(state)
  );

  return (
    <>
      <div
        className={`mt-4 ${!dashBoardState ? "ml-20  duration-300" : "ml-60 duration-300"
          }`}
      >

        <h2 className="text-gray-700 font-medium text-lg mt-2 ml-5">
          {" "}
          Jobs Status
        </h2>


        <ReportSummaryTable columns={columns} data={tableData || []} />
      </div>
    </>
  );
};

export default ReportTable;
