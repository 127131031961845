import React, { useEffect, useState } from 'react'
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from 'src/components/ui/select'
import { Input } from 'src/components/ui/input'
import { Button } from 'src/components/ui/button'
import { FileDown, Search } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { RewardsEmployeeId, RewardsEmployeeName, reward_summury_table, rewardsReducerState } from 'src/redux/slicer/rewardsDashboard'
import { stringifyObjectToRequestQuery } from 'src/helpers/index'
import { GET_DASHBOARD_EMPLOYEE_REWARDS_TABLE } from 'src/service/apis'
import { RootState } from 'src/redux/store'
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer'

interface nameProps {
    playerName: string,
    employeeId: string
}
const SearchFilter = () => {
    const [selValue, setSelVal] = useState<string>('all');
    const [inp, setInp] = useState<string>('')
    const { rewardsTableData, rewardsTableLoading, employeeName, employeeId, items_per_page, page } = useSelector((state: RootState) => rewardsReducerState(state))
    const { districtId, monthYear } = useSelector((state: RootState) => dashboardReducerState(state))
    const dispatch = useDispatch()
    useEffect(() => {
        selValue === 'playerName' ? dispatch(RewardsEmployeeName(inp)) : dispatch(RewardsEmployeeId(inp))
        if (selValue === 'playerName') {
            dispatch(RewardsEmployeeName(inp))
        } else if (selValue === 'employeeId') {
            dispatch(RewardsEmployeeId(inp))
        } else {
            dispatch(RewardsEmployeeId(undefined))
            dispatch(RewardsEmployeeName(undefined))
            dispatch(reward_summury_table())
        }
        return () => { }
    }, [inp, selValue])


    const handleClick = () => {
        dispatch(reward_summury_table())
    }

    const handleOnClickExport = () => {
        let table_query = { download: 'excel' };
        let obj = {
            // page: page,
            // items_per_page: items_per_page,
            dist_id: districtId,
            bym: monthYear?.toUpperCase(),
            name: employeeName,
            employee_id: employeeId,
        }
        const newstate = obj && JSON.parse(JSON.stringify(obj));
        // console.log(newstate)
        // console.log({ newstate });
        // // delete newstate["_fkey"];
        // delete newstate["items_per_page"];
        // delete newstate["page"];
        table_query = { ...table_query, ...newstate };

        // query = stringifyObjectToRequestQuery(table_query);
        const queryString = stringifyObjectToRequestQuery(table_query);
        // console.log({ queryString });

        const baseUrl = GET_DASHBOARD_EMPLOYEE_REWARDS_TABLE + "?" + queryString;
        // console.log(baseUrl);
        var win: any = window.open(baseUrl, "_blank");
        win.focus();
    }
    return (
        <div className='flex w-full justify-end p-2 space-x-3'>
            <Select
                onValueChange={(value: string) => {
                    setSelVal(value);
                    setInp('')
                }}
                defaultValue={"all"}
                value={selValue}
            >
                <SelectTrigger className="w-[180px]">
                    <SelectValue placeholder="Select " />
                </SelectTrigger>
                <SelectContent>
                    <SelectGroup>
                        <SelectItem value="all">All</SelectItem>
                        <SelectItem value="playerName">Player Name</SelectItem>
                        <SelectItem value="employeeId">Employee Id</SelectItem>

                    </SelectGroup>
                </SelectContent>
            </Select>
            <Input type='text' placeholder='Enter to search' disabled={selValue === 'all'} className='w-1/6' value={inp} onChange={(e) => setInp(e.target.value)} />
            <Button variant='outline' disabled={selValue === 'all'} onClick={handleClick}>      <Search /></Button>
            <Button variant='outline' onClick={handleOnClickExport}> <FileDown /></Button>

        </div>
    )
}

export default SearchFilter