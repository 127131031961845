

import { Badge } from "src/components/ui/badge"
import { ColumnDef } from "@tanstack/react-table"
import { Check, X } from "lucide-react"
import { ReactNode } from "react"
import RedirectToUserActivity from "./RedirectToUserActivity"
import DurationJobStatus from "./DurationJobStatus"
import moment from "moment"
import dayjs from "dayjs"


export type TargetOverflowTable = {
    batch_job_id: number,
    start_date: string,
    end_date: string,
    is_success: number,
    bym: string
}


export const jobStatusColumns: ColumnDef<TargetOverflowTable>[] = [
    {
        accessorKey: "batch_job_id",
        header: "Job Id",
        cell({ row: { original } }) {
            return <RedirectToUserActivity {...original}>
                <div>{original?.batch_job_id || ""}</div>
            </RedirectToUserActivity>
        },
    },
    {
        accessorKey: "bym",
        header: "Date",
        cell: (({ row }) => {
            let startDate: string = row.getValue('bym');

            return dayjs(startDate).format('MMM DD YYYY')
        })
    },
    {
        accessorKey: "start_date",
        header: "Start Ts",
        cell: (({ row }) => {
            let startDate: string = row.getValue('start_date');

            return dayjs(startDate).format('hh:mm:ss A ')
        })
    },
    {
        id: 'duration',
        header: "Duration",
        cell: ({ row: { original } }) => {
            return <DurationJobStatus {...original}
            />
        }
    },

    // {
    //     accessorKey: "bym",
    //     header: "Month-Year",

    // },
    {
        accessorKey: "is_success",
        header: "Status",

        cell: ({ row }) => {
            const match = parseFloat(row.getValue("is_success"))

            if (match === 1) {
                // return <Check className=" text-green-600"/>
                return <Badge
                    className=" bg-transparent border-primary text-primary hover:bg-transparent hover:text-primary  rounded-full">Success</Badge>
                //
            } else {
                // return <X className=" text-red-600"/>

                return <Badge className=" bg-transparent border-destructive text-destructive hover:bg-transparent hover:text-destructive rounded-full"  >Fail</Badge>
            }
        },
    },
    {
        id: "action",
        header: "Action",
        cell: ({ row: { original } }) => {
            return <RedirectToUserActivity {...original}>

                <div>
                    <Badge>Go</Badge>
                </div>


            </RedirectToUserActivity>
        },
    }
]
