import { configureStore } from "@reduxjs/toolkit";
import editableSlicer from "./slicer/editableSlicer";
import dropDownSlicer from "./slicer/dropDownSlicer";
import dashboardSlicer from "./slicer/dashboardSlicer";
import offerSlicer from "./slicer/offerSlicer";
import rewardsDashboard from "./slicer/rewardsDashboard";
import punchEditableSlicer from "./slicer/punchEditableSlicer";

export const store = configureStore({
  reducer: {
    editable: editableSlicer,
    dropDown: dropDownSlicer,
    dashboard: dashboardSlicer,
    offer: offerSlicer,
    reward: rewardsDashboard,
    punch: punchEditableSlicer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
