import { Card, CardContent, CardHeader, CardTitle } from 'src/components/ui/card'
import React, { useMemo } from 'react'
import { Equal, Plus } from 'lucide-react'
import Niara from './Niara.svg';
import { cn } from 'src/helpers';
import { useQueryResponseLoading } from '../index';
import { XaxisVal } from './LineCharts';

interface Datas {
    actual_points: number;
    bonus_points: number;
    total_points: number;
    cumulative_collection: string;
    user_id?: number,
    actual_collection?: number,
    dt?: string
}

interface Targets {
    target: string,
    collection: string
}
interface ChartsSummaryProps {
    datas: Datas[]
    details: any[],
    targets?: Targets[]
    color: string;
    word?: string;

}



const ChartsSummary: React.FC<ChartsSummaryProps> = ({ datas, color, word, targets, details }) => {
    const isLoading = useQueryResponseLoading();

    const actual_points: any = useMemo(
        () => (isLoading ? 'N/A' : datas && datas[0] ? datas[0]?.actual_points.toFixed(2) : 'N/A'),
        [isLoading, datas]
    );

    const bonus_points: any = useMemo(
        () => (isLoading ? 'N/A' : datas && datas[0] ? datas[0]?.bonus_points.toFixed(2) : 'N/A'),
        [isLoading, datas]
    );
    const total_points: any = useMemo(
        () => (isLoading ? 'N/A' : datas && datas[0] ? datas[0]?.total_points.toFixed(2) : 'N/A'),
        [isLoading, datas]
    );

    const collection: any = useMemo(
        () => (isLoading ? 'N/A' : targets && targets[0] ? Number(targets[0]?.collection).toFixed(2) : 'N/A'),
        [isLoading, targets]
    );

    const target: any = useMemo(
        () => (isLoading ? 'N/A' : targets && targets[0] ? Number(targets[0]?.target).toFixed(2) : 'N/A'),
        [isLoading, targets]
    );
    const xAxis = useMemo(() => details ? details : [], [details])
    const xAxisValues = useMemo(() => {
        return xAxis.map((cur: XaxisVal) => ({
            "xAxisValue": Number(((Number(cur.cumulative_collection) / Number(target)) * 100).toFixed(2)),
        }));
    }, [xAxis]);

    const achivedPercentage = useMemo(() => xAxisValues && Array.isArray(xAxisValues) ? xAxisValues[xAxisValues?.length - 1]?.xAxisValue : 0, [xAxisValues])

    return (
        <Card className={cn(`w-full rounded-none text-gray-500`)}>

            <CardContent className='flex items-center justify-between mt-2'>
                <div className={cn(`  rounded-lg p-2`)}>
                    {/* <h3 className='text-xs font-medium mt-1'>{word} Performance Point</h3> */}
                    <h3 className='text-sm font-bold text-center text-gray-800' >{Intl.NumberFormat('en-US').format(actual_points)}</h3>

                </div>
                <div > <Plus color='#000000' size={16} /></div>
                <div className={` p-2  rounded-lg`}>
                    {/* <h3 className='text-xs font-medium mt-1'>{word} Bonus Point</h3> */}
                    <h3 className={`text-sm font-bold text-center ${color}`}>{Intl.NumberFormat('en-US').format(bonus_points)}</h3>

                </div>
                <div> <Equal color='#000000' size={16} /></div>
                <div className={cn(` p-2   rounded-lg`)}>
                    {/* <h3 className='text-xs font-medium mt-1'>Total Points</h3> */}
                    <h3 className=' text-sm font-bold text-center text-gray-800'>{Intl.NumberFormat('en-US').format(total_points)}</h3>

                </div>
                <div>
                </div>

            </CardContent>

            <div className={cn(`flex justify-between px-2 -mt-3 text-gray-700}`)}>
                <h3 className='flex text-xs font-bold text-gray-800 justify-center items-center mb-2'>Achived Value: <span className='ml-1 text-gray-600'>₦{Intl.NumberFormat('en-US').format(collection)}</span> </h3>

                <h3 className='flex text-xs font-bold text-gray-800 justify-center items-center mb-2'> Target Value : <span className='ml-1 text-gray-600'> {Intl.NumberFormat('en-US').format(target)}</span></h3>
            </div>
            <div>
                <h3 className='flex text-xs font-bold text-gray-800 justify-center items-center mb-2'>Achived Percentage:  <span className='ml-1 text-gray-600'>{Intl.NumberFormat('en-US').format(achivedPercentage)}%</span> </h3>
            </div>
        </Card>
    )
}

export default ChartsSummary