import React from 'react';
import { QueryRequestProvider } from './Context/Core/QueryRequestProvider';
import { QueryResponseProvider } from './Context/Core/QueryResponseProvider';
import CollectionSummaryTable  from './Components/CollectionSummaryTable';

type Props = {}

const Main = (props: Props) => {
  return (
    <div className='mt-14 w-full'>
      <CollectionSummaryTable />
    </div>
  )
}

const CollectionSummaryMain: React.FC<Props> = ({ }) => {
  return <>
    <QueryRequestProvider >
      <QueryResponseProvider >
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  </>
}

export default CollectionSummaryMain

