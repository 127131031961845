import axios, { AxiosResponse } from 'axios'
import { COLLECTION_SUMMARY} from '../../../../service/apis'

const getCollectionSummury = (query: string) => {
  return axios
    .get(`${COLLECTION_SUMMARY}?${query}`
        
    )
    .then((d: AxiosResponse<any>) => d.data)
}

export {  getCollectionSummury }

