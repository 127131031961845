
import { Button } from 'src/components/ui/button'
import DistrictMain from 'src/components/ui/common/District/DistrictMain'
import DateFilter from '../../TableFilters/FilterComp/DateFilter'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardReducerState, resetState, summary_data } from 'src/redux/slicer/dashboardSlicer'
import { RootState } from 'src/redux/store'
import { cn } from 'src/helpers/index'
import OfferMain from '../Component/Offer/OfferMain'
import { offer_Summary_data, offer_summary_api_call, offer_summury_table, offer_ui_data, setResetStateOffer, updateDropdownCategoryState, updateDropdownOfferState } from 'src/redux/slicer/offerSlicer'
import DashboardMain from './Dashborard/DashboardMain'
import { reward_api_call, reward_summary_data, reward_summury_table, setResetState } from 'src/redux/slicer/rewardsDashboard'
import { FilterX, RefreshCcw } from 'lucide-react'
import { Link } from 'react-router-dom'


const Rewards = () => {
    const { dashBoardState, districtId, monthYear } = useSelector((state: RootState) => dashboardReducerState(state))
    const [dates, setDate] = useState<string>('')
    const [tabs, setTabs] = useState<string>('dashboard')
    const dispatch = useDispatch()
    useEffect(() => {
        const date = new Date();

        const year = date.getFullYear();
        const month = date.getMonth() + 1
        setDate(`${month}-${year}`)
    }, [])

    const handleSubmit = () => {
        if (tabs === 'offer') {
            dispatch(offer_summary_api_call())
        } else {
            dispatch(reward_api_call())
        }


    }

    const resetClick = () => {
        dispatch(resetState())
        if (tabs === 'offer') {
            dispatch(setResetStateOffer())
            dispatch(offer_summary_api_call())
        } else {
            dispatch(setResetState())
            dispatch(reward_api_call())
        }
    }
    return (
        <div className={` mt-4 px-4 ${!dashBoardState ? 'ml-20  duration-300' : 'ml-60 duration-300'}`}>
            <div className='flex items-center justify-between'>
                <h1 className='text-gray-800 font-semibold text-lg mt-2 ml-5'>Employee Reward Dashboard
                </h1>
            </div>

            <div className='w-full my-3 flex justify-between items-center'>
                <ul className='flex items-center space-x-4 text-gray-500 font-medium text-base'>
                    <li onClick={() => setTabs('dashboard')} className={cn(`hover:text-indigo-600  underline-offset-8`, { 'text-indigo-600 underline font-semibold': (tabs === 'dashboard') })}>Dashboard</li>
                    <li onClick={() => setTabs('offer')} className={cn(`hover:text-indigo-600 underline-offset-8`, { 'text-indigo-600 underline font-semibold': (tabs === 'offer') })}>Holiday Offer</li>
                </ul>
                <div className='w-1/2 flex space-x-4 justify-end'>
                    <div className={cn(`duration-400 w-1/3 -mt-3`, { 'hidden duration-400': (tabs === 'offer') })}><DistrictMain /></div>
                    <div className='w-1/3'>
                        <DateFilter monthYear={monthYear} />
                    </div>


                    <Button className='mt-2'
                        onClick={handleSubmit}>Submit</Button>

                    <Button className='mt-2' variant='outline' onClick={resetClick}>
                        <RefreshCcw />
                    </Button>
                </div>

            </div>

            <div>
                {tabs === 'dashboard' ? (<div className='duration-300'><DashboardMain /></div>) : (<div className='w-full duration-300'><OfferMain /></div>)}
            </div>
        </div >
    )
}

export default Rewards