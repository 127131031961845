
import { Badge } from "src/components/ui/badge"
import { ColumnDef } from "@tanstack/react-table"
import {  Check, X } from "lucide-react"
import moment from "moment"



export type ReportTable = {
  dt: string,
  dump_data_achieved: string,
  cumulative_activity_achieved: string,
  match: number
}


export const columnByUserActivity: ColumnDef<ReportTable>[] = [
  {
    accessorKey: "dt",
    header: "Date",
    cell:(({row})=>{
      let startDate:string=row.getValue('dt');
      return moment(startDate).format('MMM Do YYYY')
    })
  },
  {
    accessorKey: "dump_data_achieved",
    header: "Data Received From TopUPP",
  },
  {
    accessorKey: "cumulative_activity_achieved",
    header: "Data In ERRS",
  },
  {
    accessorKey: "match",
    header: "Match",
    cell: ({ row }) => {
      const match = parseFloat(row.getValue("match"))

      if (match === 1) {
        return <Check className=" text-green-600 m-auto"/>
        // return  <Badge
        // className=" bg-transparent border-primary text-primary hover:bg-transparent hover:text-primary ">Success</Badge>
      } else {
        return <X className=" text-red-600 m-auto"/>
        // return <Badge className=" bg-transparent border-destructive text-destructive hover:bg-transparent hover:text-destructive"  >Fail</Badge>
      }
    },
  },
]

