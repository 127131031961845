import { Input } from 'src/components/ui/input'
import UserActivityDropDown from '../../TableFilters/FilterComp/UserActivityDropDown'
import { punchdROPDownModel } from '../../TableFilters/FilterModule/editableDropDownModel'
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer'
import { RootState } from 'src/redux/store'
import MonthYearFilter from '../../TableFilters/FilterComp/MonthYearFilter'
import { Button } from 'src/components/ui/button'
import { punchInPoints, punchReducerState, resetDailyData, updateSetCollectionPerValue, updateTargetValuePunch } from 'src/redux/slicer/punchEditableSlicer'
import { Loader2 } from 'lucide-react'
import { getDaysInMonth } from '../../EditableComponent/Component/EditableComponent'
import EditableTable from '../Context/PunchInComp/EditableTable'
import { columnsForOtherActivity } from '../Context/PunchInComp/columnsForOtherActivity'
import DateBoxesUser from '../../EditableComponent/Context/UserEditableComponent/DateBoxesUser'
import OtherActivityChart from '../Context/PunchInComp/OtherActivityUserCharts';


interface PostData {
    dt: string,
    achieved: string,
}

export interface IDataPost {
    bym: string,
    jsonInput: {
        activity: string,
        target: number,
        daily_data: PostData[]
    }
}

const EdiatblePunchIn = () => {
    const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state));

    const { data, postData,
        dataChanged, isLoading,
        targetValue,
        dateFilter, activityDropDown } = useSelector((state: RootState) => punchReducerState(state))

    const [activity, setActivity] = useState<string>();
    const dispatch = useDispatch();

    const apiResponse: any = useMemo(() => data, [data]);
    const totalData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? apiResponse?.daily_data : []),
        [apiResponse, isLoading]
    );

    const targets: number = useMemo(() => isLoading ? 0 : (apiResponse ? (typeof apiResponse?.target_points === 'number' ? apiResponse?.target_points.toFixed(2) : 0) : 0), [isLoading, apiResponse])
    const total_points: number = useMemo(() => isLoading ? 0 : (apiResponse ? apiResponse?.total_points?.toFixed(2) : 0), [isLoading, apiResponse])
    const tartgetVal: number = useMemo(() => isLoading ? 0 : (apiResponse ? apiResponse?.target : 0), [isLoading, apiResponse])
    const collection: number = useMemo(() => isLoading ? 0 : (apiResponse ? (typeof apiResponse?.total_collection === 'number' ? apiResponse?.total_collection.toFixed(2) : 0) : 0), [isLoading, apiResponse])

    // console.log(apiResponse?.target)

    const xAxisValues: any = useMemo(() => totalData ? totalData?.map((cur: any) => ({

        "xAxisValue": cur?.x * 100,
    })) : [], [totalData])

    const achivedPercentage = useMemo(() => xAxisValues && Array.isArray(xAxisValues) ? xAxisValues[xAxisValues?.length - 1]?.xAxisValue.toFixed(2) : 0, [xAxisValues])

    let preparePostData = useMemo(() => {
        return {
            bym: dateFilter,
            jsonInput: {

                activity: activityDropDown,
                target: targetValue || 0,
                daily_data: postData.jsonInput.daily_data
            }
        }
    }, [dateFilter, activityDropDown, targetValue, postData])


    useEffect(() => {
        let newpostData = {};
        if (data && Array.isArray(data) && data.length > 0) {
            newpostData = postData
        }
        else {
            newpostData = preparePostData
        }
        dispatch(punchInPoints(newpostData))
    }, [dateFilter, activityDropDown, targetValue])



    const date = new Date(dateFilter);

    const daysInCurrentMonth = getDaysInMonth(
        date.getFullYear(),
        date.getMonth() + 1,
    );
    return (
        <>


            <div className={` ${!dashBoardState ? 'ml-20  duration-300 z-0' : 'ml-60 duration-300'}`} >
                <h2 className='text-gray-700 font-medium text-lg mt-2 ml-5'>Sandbox For Punch</h2>

                <div className='space-y-4 flex items-center justify-center flex-col'>
                    <div className='mt-4 px-10'>
                        <DateBoxesUser data={totalData || []} arrInd={daysInCurrentMonth} ind={0} color='bg-green-600' />
                    </div>
                    <OtherActivityChart color='rgb(22 163 74)' />

                    <div className='flex flex-col justify-center items-center'>
                        <div className=" text-sm font-semibold text-center text-gray-700 my-2 space-x-6">
                            <span>
                                <span className="text-gray-800 font-bold dark:text-white"> Achived Value:</span>
                                {"  "}₦{Intl.NumberFormat('en-US').format(collection)}
                            </span>
                            <span>
                                <span className="text-gray-800 font-bold dark:text-white">Target Value:</span>
                                {"  "}
                                {Intl.NumberFormat('en-US').format(tartgetVal)}
                            </span>

                            <span>
                                <span className="text-gray-800 font-bold dark:text-white">Achived Percentage:</span>
                                {"  "}
                                {achivedPercentage} %
                            </span>
                        </div>
                        <h1 className=' text-sm font-bold text-center text-gray-800 my-2'>Achived Points {' '}
                            {Intl.NumberFormat('en-US').format(total_points) || 0}{" "}<span className='font-semibold'>Out Of</span>  {Intl.NumberFormat('en-US').format(targets)}</h1>
                    </div>
                </div>

                <div className='flex px-4 items-center space-x-3 justify-between mt-2'>
                    <p className='text-sm font-medium text-gray-800  px-3 w-1/3 h-9 rounded-md flex justify-center items-center'>
                        Maximum Performance Points Can Be Earned : {targets} </p>
                    <div className='w-1/5 -mt-2'>
                        <h3 className=' text-center text-sm text-gray-800 font-medium'>Target Value</h3>
                        <Input placeholder='Target collection for a given month'
                            className=' placeholder:text-sm placeholder:font-medium placeholder:text-gray-400'
                            type='number'
                            value={targetValue}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(updateTargetValuePunch(e.target.value))}
                        />
                    </div>
                    <div className='w-1/5 -mt-2'>
                        <h3 className='-mb-2 text-center text-sm text-gray-800 font-medium'>Select Activity</h3>
                        <UserActivityDropDown
                            setActivity={setActivity}
                            dropdownModel={punchdROPDownModel}
                            activity={activityDropDown}
                        />
                    </div>
                    <div className='w-1/6'>
                        <h3 className='-mb-2 text-center text-sm text-gray-800 font-medium'>Select Month/Year</h3>
                        <MonthYearFilter
                            monthYear={dateFilter}
                        />
                    </div>



                </div>
                <div className='flex justify-center my-4  space-x-32'>
                    <Input className='w-1/5 ml-36 placeholder:text-xs placeholder:text-gray-400 placeholder:font-medium'
                        type="text"
                        placeholder='Common Collection'

                        onChange={e => {
                            dispatch(updateSetCollectionPerValue(e.target.value))

                        }}
                    />
                    <div className='flex  space-x-2 '>

                        <Button
                            variant={"secondary"}
                            onClick={() => dispatch(resetDailyData())}
                        >Reset</Button>
                        <Button
                            disabled={isLoading}
                            onClick={() => dispatch(punchInPoints(preparePostData))}
                        >
                            {isLoading && isLoading && (
                                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                            )}
                            Go
                        </Button>
                    </div>
                </div>
                <EditableTable columns={columnsForOtherActivity} data={totalData || []} />
            </div>
        </>
    )
}

export default EdiatblePunchIn