import { setDate } from 'date-fns';

import React, { useEffect, useState } from 'react'
import { endOfDay, format, startOfDay } from 'date-fns'
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { editableReducerState } from 'src/redux/slicer/editableSlicer';

interface DetailedItem {
  user_id: number;
  dt: string;
  actual_collection: number;
  cumulative_collection: string;
  actual_points: number;
  bonus_points: number;
  total_points: number;
  final_points: number;
  today_collection: number
}

function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}

const DateBoxesUser = ({ data, arrInd, ind, color }: any) => {
  const [dates, setDates] = useState<string[]>([]);
  const [currentMonthDates, setcurrentMonthDates] = useState<string[]>([]);
  const [actualPoints, setActualPointes] = useState<number[]>([])

  const { dateFilter } = useSelector((state: RootState) => editableReducerState(state))


  const date = new Date(dateFilter);
  const currentYear = date.getFullYear();
  const currentMonth = date.getMonth() + 1;


  const daysInCurrentMonth = getDaysInMonth(
    currentYear,
    currentMonth,
  );




  useEffect(() => {
    if (Array.isArray(data) && data?.length > 0) {

      const uniqueDates = new Set<string>(dates);
      const points = new Set<number>(actualPoints)
      data.forEach((cur: any) => {
        uniqueDates.add(cur?.dt);
        points.add(cur?.today_collection)
      });

      setDates(Array.from(uniqueDates));
      setActualPointes(Array.from(points));
    }
  }, [data]);




  useEffect(() => {
    let arr = [];
    for (let i = 1; i <= daysInCurrentMonth; i++) {
      arr.push(i.toString());
    }

    setcurrentMonthDates(arr);
  }, [daysInCurrentMonth])


  // console.log(data)
  return (


    <div className="w-full flex space-x-2">
      {currentMonthDates.slice(ind, arrInd).map((cur, index) => {
        const currentDate = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${cur.padStart(2, '0')}`;

        const foundDate = Array.isArray(dates) && dates.length > 0 && dates.find((date) => date === currentDate);
        const foundData = Array.isArray(data) && data.length > 0 && data.find((item: any) => item?.dt === currentDate);

        const actualPoints = Number(foundData?.today_collection) || 0;

        return (
          <div key={index} className=" ">
            {foundDate && actualPoints !== 0 ? (
              <div
                className={`w-6 h-6 border rounded-xm text-xs font-medium flex items-center justify-center rounded-sm ${color} text-white`}
              >
                {cur}
              </div>
            ) : (
              <div className={`w-6 h-6 border text-xs font-medium border-gray-500 rounded-xm flex items-center justify-center rounded-sm`}>
                {cur}
              </div>
            )}
          </div>
        );
      })}
    </div>
  )
}

export default DateBoxesUser



