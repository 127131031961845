import React, { useEffect } from 'react';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue
} from "../../../components/ui/select";
// import { dropdownModel } from '../FilterModule/dropdownModel';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { updateActivityDropDown } from 'src/redux/slicer/editableSlicer';
import { updateActivityDropDownPunch } from 'src/redux/slicer/punchEditableSlicer';

interface ActivityProps {
    dropdownModel?: any,
    setActivity: (value: string) => void;
    loading?: boolean,
    activity?: string;
}

const UserActivityDropDown = ({ setActivity, dropdownModel, loading, activity }: ActivityProps) => {
    const { search } = useLocation();
    const params = search && search?.split('?')[1].split("&");
    // console.log({ params });
    let searchParams: any = {};

    params && params.forEach((o: any) => {
        let splitParam = o?.split("=");
        searchParams[splitParam[0]] = splitParam[1];

    });
    const dispatch = useDispatch()

    useEffect(() => {
        setActivity(searchParams.activity ? searchParams.activity : 'collection_coverage')

        return () => { }
    }, [searchParams.activity])

    useEffect(() => {
        dispatch(updateActivityDropDown(activity ? activity : 'collection_coverage'))

        dispatch(updateActivityDropDownPunch(activity ? activity : 'is_on_time'))
    }, [activity])

    const activityChange = (value: any) => {
        setActivity(value);
        dispatch(updateActivityDropDown(value))

        dispatch(updateActivityDropDownPunch(value))
    }

    return (
        <Select onValueChange={(value: any) => activityChange(value)}
            defaultValue={`${activity ? activity : ''}`}
            disabled={loading}>
            <SelectTrigger className="w-full mt-2 font-medium">
                <SelectValue placeholder="Select a category" />
            </SelectTrigger>
            <SelectContent>
                <SelectGroup>
                    {dropdownModel.map((cur: any) => (
                        <SelectItem value={cur.value} key={`${cur.value}-value`}>
                            {cur.name}
                        </SelectItem>
                    ))}
                </SelectGroup>
            </SelectContent>
        </Select>
    );
};

export default UserActivityDropDown;


