import { convertToCamelCase } from 'src/helpers/index'
import React from 'react'
import { GET_PROFILE_PICTURE } from 'src/service/apis'

const ProfileImage = ({ profile_image, name }: any) => {
    return (
        <div className="flex space-x-4 w-3/4 items-center justify-start">
            <img src={`${GET_PROFILE_PICTURE}${profile_image}`} className='w-10 h-10 rounded-full p-1' />
            <span className="text-left">{convertToCamelCase(name)}</span> </div>
    )

}

export default ProfileImage