import React from 'react';
import { FC, createContext, useContext, useState } from 'react'
import {
  QueryRequestContextProps,
  QueryState,
  initialQueryRequest
} from 'src/helpers'

import { ReactNode } from 'react'

export type propsWithChildren = {
  children?: ReactNode,
  props?: any,
  type?: string,
  postdata?: any,
  initialQueryparams?: any
}

const QueryRequestContext = createContext<QueryRequestContextProps>(initialQueryRequest)

const QueryRequestProvider: FC<propsWithChildren> = ({ children, initialQueryparams }) => {
  const [state, setState] = useState<QueryState>({ ...initialQueryRequest.state, ...initialQueryparams })

  const updateState = (updates: Partial<QueryState>) => {
    const updatedState = { ...state, ...updates } as QueryState
    setState(updatedState)
  }

  return (
    <QueryRequestContext.Provider value={{ state, updateState }}>
      {children}
    </QueryRequestContext.Provider>
  )
}

const useQueryRequest = () => useContext(QueryRequestContext)
export { QueryRequestProvider, useQueryRequest }

