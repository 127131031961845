const API_URL = process.env.REACT_APP_API_URL_GAMIFICATION_SERVICE + "/api";
const FAIL_JOB_API_URL =
  process.env.REACT_APP_API_URL_FAIL_JOB_MAKE_SUCCESS + "/api";

const PROFILE_PICTURE_URL =
  process.env.REACT_APP_API_URL_PROFILE_PICTURE + "/uploads/c120x120/";

const DASHBOARD_URL =
  process.env.REACT_APP_API_URL_DASHBOARD_EMPLOYEE_REWARDS +
  "/report-service/api";

const DASHBOARD_SUMMARY =
  process.env.REACT_APP_API_URL_DASHBOARD_EMPLOYEE_SUMMARY + "/api";

const WALL_OF_FAME =
  process.env.REACT_APP_API_URL_GAMIFICATION_SERVICE + "/api";

// ACHIEVE JOB SUMMARY
export const ACHIEVE_JOB_SUMMARY = API_URL + "/achieved-job-summary-status";

// Collection Summary

export const COLLECTION_SUMMARY = API_URL + "/collection-summary-by-bym";

// Summary By Activity

export const SUMMARY_BY_ACTIVITY = API_URL + "/summary-by-activity";

// Collection By User Activity

export const COLLECTION_BY_USER_ACTIVITY =
  API_URL + "/get-collection-by-user-activity";

// Get Bucket Wise Point

export const GET_BUCKET_WISE_POINT = API_URL + "/get-bucket-wise-point";

//Target overflow pointes

export const GET_TARGET_OVERFLOW = API_URL + "/get-target-overflow";

// Target OverFlow Job Status
export const GET_TARGET_OVERFLOW_JOB_STATUS =
  API_URL + "/target-overflow-job-status";

// Target Overflow by Job Id
export const GET_TARGET_OVERFLOW_BY_JOB_ID =
  API_URL + "/target-overflow-by-job-id";

// Editable Table Url

export const POINTS_EXCEL_CALCULATION = API_URL + "/points-excel-calculation";

// Editable Url for is_on_time and event_count

export const POINTS_EXCEL_CALCULATION_IS_ON_TIME =
  API_URL + "/event-points-excel-calculation";

// Get all players User Id

export const GET_ALL_PLAYERS_USERID = API_URL + "/get-all-payers";

// Get all job id

export const GET_ALL_JOB_ID = API_URL + "/get-all-job-id";

// fail job make success

export const GET_FAIL_JOB_MAKE_SUCCESS =
  FAIL_JOB_API_URL + "/fail-job-make-success";

// PROFILE PICTURE URL

export const GET_PROFILE_PICTURE = PROFILE_PICTURE_URL;

// district url

export const GET_DISTRICT_URL = DASHBOARD_URL + "/v1/dd/districts";

// dashboard summary url

export const GET_DASHBOARD_SUMMARY_API = DASHBOARD_SUMMARY + "/summury-report";

export const GET_DASHBOARD_EMPLOYEE_REWARDS_TABLE =
  DASHBOARD_SUMMARY + "/player-report";

// Holiday Offer ui
export const OFFER_UI = DASHBOARD_SUMMARY + "/offer-ui";

// Holiday offer summary

export const OFFER_SUMMARY = DASHBOARD_SUMMARY + "/offer-summury";

// Holiday offer-summary-report

export const OFFER_SUMMARY_REPORT = DASHBOARD_SUMMARY + "/offer-summary-report";

// Wall of Fame

export const URL_WALL_OF_FAME = WALL_OF_FAME + "/wall-of-fame";

export const REWARDS_DETAILS_REPORT = DASHBOARD_SUMMARY + "/all-users-points";
