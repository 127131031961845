import React from 'react'
import { GET_PROFILE_PICTURE } from 'src/service/apis'

interface RankProps {
    name: string,

    dist_name: string,
    sc_name: string
}

const ConsumerComp = ({ name, dist_name, sc_name }: RankProps) => {
    return (
        <div className='flex items-center space-x-2'>

            <div className='text-left'>
                <h1 className='text-xs font-bold text-gray-800'>{name}</h1>
                <h1 className='text-gary-500 text-xs font-medium'>{dist_name}</h1>
                <h1 className='text-gary-500 text-xs font-medium'>{sc_name}</h1>
            </div>
        </div>
    )
}

export default ConsumerComp