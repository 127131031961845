import { ColumnDef } from "@tanstack/react-table"
import dayjs from "dayjs";

export interface DetailReportProps {
    dt: string,
    user_id: number | null,
    collection_coverage_daily_value: number | null,
    collection_coverage_daily_point: null | number,
    coverage_collection_cumulative_point: number | null,
    collection_efficeincy_daily_value: number | null,
    collection_efficeincy_cumulative_value: string,
    collection_efficeincy_daily_point: null | number,
    collection_efficeincy_cumulative_point: number | null,
    desconnection_daily_value: number | null,
    desconnection_cumulative_value: string,
    desconnection_daily_point: null | number,
    desconnection_cumulative_point: number,
    reconnection_daily_value: number | null,
    reconnection_cumulative_value: string,
    reconnection_daily_point: null | number,
    reconnection_cumulative_point: number | null,
    is_on_time_daily_value: null | number,
    is_on_time_cumulative_value: null | number,
    is_on_time_daily_point: null | number,
    is_on_time_cumulative_point: null | number,
    event_count_daily_value: number | null,
    event_count_cumulative_value: null | number,
    event_count_daily_point: null | number,
    event_count_cumulative_point: number | null,
    total: null | number,
    cumulative_total: number | null
}


export const columnDetailReport: ColumnDef<DetailReportProps>[] = [
    {
        accessorKey: "dt",
        header: "",
        columns: [
            {
                accessorKey: "dt",
                header: "Date",

                cell: (({ row }) => {
                    let startDate: string = row.getValue('dt');
                    return dayjs(startDate).format('MMM DD YYYY')
                })
            }
        ],

    },
    {
        accessorKey: "collection_coverage_daily_value",

        header: ({ column }) => (
            <div className="text-pink-600 text-center" >COLLECTION COVERAGE</div>
        ),
        columns: [
            {
                accessorKey: "collection_coverage_daily_value",

                header: ({ column }) => (
                    <div className="text-pink-600 text-center" >VALUE</div>
                ),
                cell: ({ row }: any) => {
                    let val: number = row.getValue("collection_coverage_daily_value")?.toFixed(1)
                    return <div className="text-center">{val !== null ? (Intl.NumberFormat("en-US").format(val)) : '-'}</div>

                }
            },
            {
                accessorKey: "collection_coverage_daily_point",

                header: ({ column }) => (
                    <div className="text-pink-600 text-center" >POINTS</div>
                ),
                cell: ({ row }) => {
                    const val = parseFloat(row.getValue("collection_coverage_daily_point"))
                    return <div className="text-center">{isNaN(val) ? '-' : val?.toFixed(1)}</div>

                }
            },]
    },
    {
        accessorKey: "collection_efficeincy_daily_value",

        header: ({ column }) => (
            <div className="text-green-600 text-center" >COLLECTION EFFICIENCY</div>
        ),
        columns: [
            {
                accessorKey: "collection_efficeincy_daily_value",

                header: ({ column }) => (
                    <div className="text-green-600 text-center" >VALUE</div>
                ),
                cell: ({ row }: any) => {
                    const val: number = row.getValue("collection_efficeincy_daily_value")?.toFixed(1)
                    return <div className="text-center">{val !== null ? (Intl.NumberFormat("en-US").format(val)) : 0}</div>

                }
            },
            {
                accessorKey: "collection_efficeincy_daily_point",

                header: ({ column }) => (
                    <div className="text-green-600 text-center" >POINTS</div>
                ),
                cell: ({ row }) => {
                    const val = parseFloat(row.getValue("collection_efficeincy_daily_point"))

                    return <div className="text-center">{isNaN(val) ? '-' : val?.toFixed(1)}</div>

                }
            },]
    },
    {
        accessorKey: "desconnection_daily_value",

        header: ({ column }) => (
            <div className="text-amber-600 text-center" >DISCONNECTION</div>
        ),
        columns: [
            {
                accessorKey: "desconnection_daily_value",

                header: ({ column }) => (
                    <div className="text-amber-600 text-center" >VALUE</div>
                ),
                cell: ({ row }) => {
                    const val: number = parseFloat(row.getValue("desconnection_daily_value"))
                    return <div className="text-center">{val !== null ? (Intl.NumberFormat("en-US").format(val)) : '-'}</div>

                }
            },
            {
                accessorKey: "desconnection_daily_point",

                header: ({ column }) => (
                    <div className="text-amber-600 text-center" >POINTS</div>
                ),
                cell: ({ row }) => {
                    const val: number = parseFloat(row.getValue("desconnection_daily_point"))
                    return <div className="text-center">{isNaN(val) ? '-' : val?.toFixed(1)}</div>

                }
            },]
    },
    {
        accessorKey: "reconnection_daily_value",

        header: ({ column }) => (
            <div className="text-blue-600 text-center" >RECONNECTION</div>
        ),
        columns: [
            {
                accessorKey: "reconnection_daily_value",

                header: ({ column }) => (
                    <div className="text-blue-600 text-center" >VALUE</div>
                ),
                cell: ({ row }) => {
                    const val: number = parseFloat(row.getValue("reconnection_daily_value"))
                    return <div className=" text-center">{val !== null ? (Intl.NumberFormat("en-US").format(val)) : '-'}</div>

                }
            },
            {
                accessorKey: "reconnection_daily_point",

                header: ({ column }) => (
                    <div className="text-blue-600 text-center" >POINTS</div>
                ),
                cell: ({ row }) => {
                    const val = parseFloat(row.getValue("reconnection_daily_point"))
                    return <div className=" text-center">{isNaN(val) ? '-' : val?.toFixed(1)}</div>

                }
            },]
    },
    {
        accessorKey: "is_on_time_daily_value",

        header: ({ column }) => (
            <div className="text-pink-600 text-center" >IS ON TIME</div>
        ),
        columns: [
            {
                accessorKey: "is_on_time_daily_value",

                header: ({ column }) => (
                    <div className="text-pink-600 text-center" >VALUE</div>
                ),
                cell: ({ row }) => {
                    const val = parseFloat(row.getValue("is_on_time_daily_value"))
                    return <div className="text-center">{isNaN(val) ? '-' : val?.toFixed(1)}</div>

                }
            },
            {
                accessorKey: "is_on_time_daily_point",

                header: ({ column }) => (
                    <div className="text-pink-600 text-center" >POINTS</div>
                ),
                cell: ({ row }) => {
                    const val = parseFloat(row.getValue("is_on_time_daily_point"))
                    return <div className="text-center">{isNaN(val) ? '-' : val?.toFixed(1)}</div>

                }
            },]
    },
    {
        accessorKey: "event_count_daily_value",

        header: ({ column }) => (
            <div className="text-lime-600 text-center" >Event Count</div>
        ),
        columns: [
            {
                accessorKey: "event_count_daily_value",

                header: ({ column }) => (
                    <div className="text-lime-600 text-center" >VALUE</div>
                ),
                cell: ({ row }) => {
                    const val = parseFloat(row.getValue("event_count_daily_value"))?.toFixed(1)
                    return <div className="text-center">{val}</div>

                }
            },
            {
                accessorKey: "event_count_daily_point",

                header: ({ column }) => (
                    <div className="text-lime-600 text-center" >POINTS</div>
                ),
                cell: ({ row }) => {
                    const val = parseFloat(row.getValue("event_count_daily_point"))
                    return <div className="text-center">{isNaN(val) ? '-' : val?.toFixed(1)}</div>

                }
            },]
    },
    {
        accessorKey: "total",
        header: "",
        columns: [
            {
                accessorKey: "total",
                header: "TOTAL",

                cell: ({ row }) => {
                    const val = parseFloat(row.getValue("total"))

                    return <div className="text-center">{isNaN(val) ? '-' : val?.toFixed(1)}</div>

                }
            }
        ],

    },
    {
        accessorKey: "cumulative_total",
        header: "",
        columns: [
            {
                accessorKey: "cumulative_total",
                header: "CUMMULATIVE",

                cell: ({ row }) => {
                    const val = parseFloat(row.getValue("cumulative_total"))

                    return <div className="text-center">{isNaN(val) ? '-' : val?.toFixed(1)}</div>

                }
            }
        ],

    },


]