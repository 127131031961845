const QUERIES = {
  USERS_LIST: "users-list",
};

const months: any = [
  "JAN",
  "FEB",
  "MAR",
  "APR",
  "MAY",
  "JUN",
  "JUL",
  "AUG",
  "SEP",
  "OCT",
  "NOV",
  "DEC",
];
const MonthNumbers: any = [
  "01",
  "02",
  "03",
  "04",
  "05",
  "06",
  "07",
  "08",
  "09",
  "10",
  "11",
  "12",
];

const startCase = (string: any) => {
  return (
    string &&
    string
      ?.replace(/[\W_]+/g, " ")
      .replace(/(?<=[a-z])([A-Z])/g, " $1")
      .replace(/^[a-z]/, (match: any) => match.toUpperCase())
  );
};

const camelCase = (string: any) => {
  return (
    string &&
    string
      ?.replace(/[\W_]+(.|$)/g, (_: any, chr: any) => " " + chr.toUpperCase())
      .trim()
  );
};

const isNumeric = (num: any) =>
  (typeof num === "number" || (typeof num === "string" && num.trim() !== "")) &&
  !isNaN(num as number);

const isValidStr = (str: any, isCampleCase = false) => {
  if (str != null && str != undefined) {
    if (isNumeric(str)) {
      return str;
    }

    if (isCampleCase) {
      return startCase(camelCase(str));
    } else return str;
  } else return "-";
};

function convertToCamelCase(str: any) {
  // const words = str.split('_');
  // const camelCaseString = words.map((word: any, index: any) => {
  //   return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  // }).join(' ');
  // return camelCaseString;

  return startCase(camelCase(str));
}

function convertToCamelCaseNormal(str: any) {
  // const result = str.replace(/([A-Z])/g, " $1");
  // const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
  // return finalResult;
  return startCase(camelCase(str));
}

const bussinessDaysInMonth = (m: any, y: any) => {
  var days = new Date(y, m, 0).getDate();

  var sundays = [8 - new Date(m + "/01/" + y).getDay()];
  var saturday = [7 - new Date(m + "/01/" + y).getDay()];

  var allDates = [...Array(days)].map((_, i) => i + 1);
  allDates.splice(
    allDates.findIndex((d: any) => d == sundays),
    1
  );
  allDates.splice(
    allDates.findIndex((d: any) => d == saturday),
    1
  );

  for (var i = sundays[0] + 7; i < days; i += 7) {
    sundays.push(i);
    allDates.splice(
      allDates.findIndex((d: any) => d == i),
      1
    );
  }

  for (var i = saturday[0] + 7; i < days; i += 7) {
    saturday.push(i);
    allDates.splice(
      allDates.findIndex((d: any) => d == i),
      1
    );
  }

  var working_until_days = [];
  var remaining_days = [];
  var currentDate = new Date();
  var curr_date = currentDate.getDate();

  for (let index = 0; index < allDates.length; index++) {
    if (allDates[index] < curr_date) {
      working_until_days.push(allDates[index]);
    } else {
      remaining_days.push(allDates[index]);
    }
  }

  //console.log({ sundays, saturday, allDates, working_until_days, remaining_days });

  return {
    holydays: [...sundays, ...saturday].sort(),
    total_working_days: allDates,
    working_until_days,
    remaining_days,
  };
};

const getMonthNumber = (date: any) => {
  var dt = new Date(date);
  // console.log({ dt, date });
  // console.log("MonthNumbers :", MonthNumbers[dt.getMonth()])
  return MonthNumbers[dt.getMonth()];
};

const getFormatDate = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return y + "-" + m + "-" + d;
};

const getFormattedDate = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate();
  var m = MonthNumbers[dt.getMonth()];
  var y = dt.getFullYear();

  // Ensure day and month have leading zeros if needed
  var formattedDay = d < 10 ? "0" + d : d;
  var formattedMonth = m < 10 ? "0" + m : m;

  return y + "-" + formattedMonth + "-" + formattedDay;
};

// const getStartDateAndEndDateOfMonth = (date?: any) => {
//   var dt = date ? new Date(date) : new Date();

//   var firstDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
//   var d = firstDate.getDate(), m = MonthNumbers[dt.getMonth()], y = firstDate.getFullYear();
//   firstDate = getFormattedDate(new Date(y + "-" + m + "-" + d));

//   var lastDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 0);
//   var d = lastDate.getDate(), m = MonthNumbers[dt.getMonth()], y = lastDate.getFullYear();
//   lastDate = getFormattedDate(new Date(y + "-" + m + "-" + d));

//   return { firstDate, lastDate }

// }

const calculateMonthsFromBym = (billYearMonth?: any) => {
  if (!billYearMonth || typeof billYearMonth !== "string") {
    // Handle invalid or missing input
    return { current: "", previous: "", twoMonthsAgo: "" };
  }

  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUN",
    "JUL",
    "AUG",
    "SEP",
    "OCT",
    "NOV",
    "DEC",
  ];
  const [month, year] = billYearMonth.split("-");

  if (months.indexOf(month) === -1 || isNaN(parseInt(year, 10))) {
    // Handle invalid month or year format
    return { current: "", previous: "", twoMonthsAgo: "" };
  }

  const currentMonthIndex = months.indexOf(month);
  const currentYear = parseInt(year, 10);

  const current = billYearMonth;

  let previousMonthIndex = currentMonthIndex - 1;
  let previousYear = currentYear;
  if (previousMonthIndex < 0) {
    previousMonthIndex = 11; // Set to December
    previousYear--;
  }
  const previous = `${months[previousMonthIndex]}-${previousYear
    .toString()
    .slice(-2)}`;

  let twoMonthsAgoIndex = currentMonthIndex - 2;
  let twoMonthsAgoYear = currentYear;
  if (twoMonthsAgoIndex < 0) {
    twoMonthsAgoIndex += 12;
    twoMonthsAgoYear--;
  }
  const twoMonthsAgo = `${months[twoMonthsAgoIndex]}-${twoMonthsAgoYear
    .toString()
    .slice(-2)}`;

  return { current, previous, twoMonthsAgo };
};

const getStartDateAndEndDateOfMonth = (date?: any) => {
  const dt = date ? new Date(date) : new Date();
  const year = dt.getFullYear();
  const month = dt.getMonth();

  const firstDate = getFormattedDate(new Date(year, month, 1));

  const lastDay = new Date(year, month + 1, 0).getDate();
  const lastDate = getFormattedDate(new Date(year, month, lastDay));

  return { firstDate, lastDate };
};

const getStartDate = (date?: any) => {
  var dt = date ? new Date(date) : new Date();

  var firstDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
  var d = firstDate.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = firstDate.getFullYear();
  firstDate = y + "-" + m + "-" + d;

  return { firstDate };
};

const getStartDateWithoutObject = (date?: any) => {
  var dt = date ? new Date(date) : new Date();

  var firstDate: any = new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
  var d = firstDate.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = firstDate.getFullYear();
  // firstDate =

  return y + "-" + m + "-" + d;
};

const getCurrentMonthYearNo = (_date?: any) => {
  var dt = _date ? new Date(_date) : new Date();
  var date = dt.getDate(),
    month = MonthNumbers[dt.getMonth()],
    year = dt.getFullYear();
  return { month, year, date };
};

const converMonthYearToMonthYearNo = (shortMonthYear?: any) => {
  const [m, y] = shortMonthYear && shortMonthYear.split("-");
  const getMonthIndex = months.findIndex((mm: any) => mm == m);
  var dt = shortMonthYear
    ? new Date("20" + y + "-" + (getMonthIndex + 1) + "-01")
    : new Date();
  var d = dt.getDate(),
    month = MonthNumbers[dt.getMonth()],
    year = dt.getFullYear();
  return { month, year };
};

const getFormatDateKLocale = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return d + "/" + m + "/" + y;
};

const convertShortMonthToDate = (shortMonthYear?: any) => {
  const [month, year] = shortMonthYear.split("-");
  const getMonthIndex = months.findIndex((m: any) => m == month);
  var m = getMonthIndex + 1;
  return 20 + year + "-" + MonthNumbers[m - 1] + "-" + "01";
};

const getFormatToTime = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var h = dt.getHours(),
    m = dt.getMinutes(),
    s = dt.getSeconds(),
    mm = dt.getMilliseconds();
  var ampm = h >= 12 ? "pm" : "am";
  return h + ":" + m + ":" + s + " " + ampm;
};

const getFormatToDateTime = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var h = dt.getHours(),
    min = dt.getMinutes(),
    s = dt.getSeconds(),
    mm = dt.getMilliseconds(),
    d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  var ampm = h >= 12 ? "pm" : "am";
  return (
    y + "-" + m + "-" + d + " " + "," + h + ":" + min + ":" + s + " " + ampm
  );
};

const getFormatToDateTimeWithoutSecondsTogether = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var h = dt.getHours(),
    min = dt.getMinutes(),
    s = dt.getSeconds(),
    mm = dt.getMilliseconds(),
    d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  var ampm = h >= 12 ? "pm" : "am";
  return y + "-" + m + "-" + d + " " + ", " + h + ":" + min + " " + ampm;
};

const getFormatToDateTimeWithoutSeconds = (date?: any) => {
  const dt = date ? new Date(date) : new Date();
  const hours = dt.getHours();
  const minutes = dt.getMinutes();
  const seconds = dt.getSeconds();
  const milliseconds = dt.getMilliseconds();
  const day = dt.getDate();
  const month = MonthNumbers[dt.getMonth()];
  const year = dt.getFullYear();
  const ampm = hours >= 12 ? "pm" : "am";

  const formattedDate = `${year}-${month}-${day}`;
  const formattedTime = `${hours}:${minutes} ${ampm}`;
  // const formattedTime = `${hours}:${minutes}:${seconds}.${milliseconds} ${ampm}`;

  return { date: formattedDate, time: formattedTime };
};

// const getFormatToDays = (date?: any) => {
//   const currentDate = new Date();
//   const providedDate = date ? new Date(date) : new Date();

//   const differenceInTime = currentDate.getTime() - providedDate.getTime();
//   console.log({differenceInTime});

//   const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24)); // Use Math.floor to get integer days

//   return `${differenceInDays === 0 ? 'Today' : `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`}`;
// }

// const getFormatToDays = (date?: any) => {
//   const currentDate = new Date();
//   const providedDate = date ? new Date(date) : new Date();

//   const differenceInTime = currentDate.getTime() - providedDate.getTime();

//   const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));
//   const differenceInHours = Math.floor(differenceInTime / (1000 * 3600)); // Calculate difference in hours

//   if (differenceInDays === 0) {
//     return differenceInHours <= 1 ? '1 hour ago' : `${differenceInHours} hours ago`;
//   } else {
//     return `${differenceInDays} day${differenceInDays > 1 ? 's' : ''} ago`;
//   }
// };

const getFormatToDays = (date?: any) => {
  const currentDate = new Date();
  const providedDate = date ? new Date(date) : new Date();

  const differenceInSeconds = Math.floor(
    (currentDate.getTime() - providedDate.getTime()) / 1000
  );

  if (differenceInSeconds < 60) {
    return `${differenceInSeconds} second${
      differenceInSeconds !== 1 ? "s" : ""
    } ago`;
  }

  const differenceInMinutes = Math.floor(differenceInSeconds / 60);
  if (differenceInMinutes < 60) {
    return `${differenceInMinutes} minute${
      differenceInMinutes !== 1 ? "s" : ""
    } ago`;
  }

  const differenceInHours = Math.floor(differenceInMinutes / 60);
  if (differenceInHours < 24) {
    return `${differenceInHours} hour${differenceInHours !== 1 ? "s" : ""} ago`;
  }

  const differenceInDays = Math.floor(differenceInHours / 24);
  return `${differenceInDays} day${differenceInDays !== 1 ? "s" : ""} ago`;
};

const getShortMonthYear = (date?: any) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return (
    months[dt.getMonth()].toString().toUpperCase() +
    "-" +
    y.toString().slice(-2)
  );
};

const subtractMonthsFromDate = (date?: any, subtractMonths = 1) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = dt.getMonth() + 1 - subtractMonths,
    y = dt.getFullYear();
  return y + "-" + MonthNumbers[m - 1] + "-" + "01";
};

const subtractYearsFromDate = (date?: any, subtractMonths = 1) => {
  var dt = date ? new Date(date) : new Date();
  var d = dt.getDate(),
    m = MonthNumbers[dt.getMonth()],
    y = dt.getFullYear();
  return y - subtractMonths + "-" + m + "-" + "01";
};

function convetMMYYYYToMMMMYYYY(dateString: any) {
  const [month, year] = dateString.split("-");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthIndex = parseInt(month, 10) - 1;
  const formattedDate = `${monthNames[monthIndex]} ${year}`;
  return formattedDate;
}

function convertMM_YYYY(data: string) {
  let dt = data.split("-");
  let [month, year] = dt;

  let v = new Date(`0${month} 1, ${year}`).getMonth() + 1;
  return `${v}-20${year}`;
}

// const color = [
//   "#1FC777", "#7750CE", "#CAB54B", "#117E4D", '#FBAA49', "#FD5EC3", "#333333", "#FC228A", "#7E5348", "#E0465E", "#C4C4C4",
//   "#B93555", "#A161D9", "#808080", "#5A9DF9", "#1487BE", "#0b4c8c", "##de4e2b", '#e8912d', '#e8912d', '#007a5a'
// ]

const blue = ["#d6e3f8", "#5a9df9", "#2146c7", "#0b4c8c", "#002a5c"];
const green = ["#bceed6", "#7bd5b8", "#1fc777", "#1e9870", "#117e4d"];
const red = ["#ffd3d3", "#ffa6a6", "#e5383b", "#c42348", "#d90429"];
const orange = ["#fdceb3", "#fa9d66", "#ff773d", "#de4e2b", "#e62314"];
const yellow = ["#ffffb7", "#ffea86", "#ffd454", "#fdb833", "#e8912d"];
const pink = ["#ffebf8", "#feafe1", "#fc228a", "#f20089", "#da007b"];
const voilet = ["#f2ebf8", "#c9ace2", "#9258c5", "#5b377b", "#3d0e61"];

let ERROR_CODES = [400, 500, 404];

export {
  QUERIES,
  bussinessDaysInMonth,
  convertToCamelCase,
  getShortMonthYear,
  getFormatDate,
  getFormatToTime,
  getFormatToDateTime,
  getFormatDateKLocale,
  convertShortMonthToDate,
  getCurrentMonthYearNo,
  converMonthYearToMonthYearNo,
  getStartDateAndEndDateOfMonth,
  subtractMonthsFromDate,
  subtractYearsFromDate,
  convertToCamelCaseNormal,
  isValidStr,
  getStartDate,
  ERROR_CODES,
  getFormatToDateTimeWithoutSeconds,
  getStartDateWithoutObject,
  getFormattedDate,
  getFormatToDays,
  getFormatToDateTimeWithoutSecondsTogether,
  calculateMonthsFromBym,
  convetMMYYYYToMMMMYYYY,
  convertMM_YYYY,
};
