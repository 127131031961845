import React from 'react'
import { QueryRequestProvider, QueryResponseProvider, } from './Context'

import SummaryTable from '../../modules/SummaryByActivity/Component/SummaryTable'



type Props = {}

const Main = (props: Props) => {
  return (
    <div className='mt-14 w-full'>
      <SummaryTable />
    </div>
  )
}

const SummaryByActivityMain: React.FC<Props> = ({ }) => {
  return <>
    <QueryRequestProvider >
      <QueryResponseProvider >
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  </>
}

export default SummaryByActivityMain