import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"

import { RootState } from "../store"
import { stringifyObjectToRequestQuery } from "src/helpers/index"
import axios, { AxiosError } from "axios"
import { getHolidayOfferUi, getHolidaySummary, getSummary } from "src/service/services"
interface playerSummaryProp {

    collection_coverage_summary: null | number,

    collection_efficeincy_summary: null | number
    desconnection_summary: null | number
    event_summary: null | number
    is_on_time_summary: null | number
    reconnection_summary: null | number
    total_customers: null | number
    total_summary: null | number
}
export interface summmaryDataProps {
    playerSummary: playerSummaryProp

}


export interface OfferUIProps {
    date: string[],
    offer_names: string[],
    category: string[]
}

interface DashboardState {
    dashBoardState: boolean,
    districtId: string | undefined
    monthYear: string
    message: string | undefined
    error: boolean,
    dataChanged: boolean,
    isLoading: boolean,
    summmaryData: summmaryDataProps
    month: string,
    year: number,
    holidaySummaryData: any,
    offerUIData: OfferUIProps,

}

const currentDate = new Date();
const year = currentDate.toLocaleString('default', { year: '2-digit' });
const currentMonth = currentDate.toLocaleString('default', { month: 'short' });

const initialState: DashboardState = {
    dashBoardState: true,
    districtId: undefined,
    monthYear: `${currentMonth}-${year}`,
    isLoading: false,
    message: undefined,
    error: false,
    dataChanged: false,
    summmaryData: {
        playerSummary: {
            collection_coverage_summary: null,
            collection_efficeincy_summary: null,
            desconnection_summary: null,
            event_summary: null,
            is_on_time_summary: null,
            reconnection_summary: null,
            total_customers: null,
            total_summary: null
        }
    },
    month: "",
    year: 0,
    holidaySummaryData: {},
    offerUIData: {
        date: [],
        offer_names: [],
        category: []
    }
}


export const summary_data: any = createAsyncThunk(
    'chart/summary_data',
    async (_, thunkApi: any,) => {
        try {


            const getFilterState = thunkApi.getState().dashboard
            const query = {
                dist_id: getFilterState.districtId,
                bym: getFilterState.monthYear,

            }

            const queryparams = stringifyObjectToRequestQuery(query)
            // console.log(queryparams)
            const response = await getSummary(queryparams);

            return response.data;

        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)

export const holiday_Summary_data: any = createAsyncThunk(
    'chart/holiday_summary_data',
    async (_, thunkApi: any,) => {
        try {


            const getFilterState = thunkApi.getState().dashboard
            // const query = {
            //     month: getFilterState.month,


            // }

            // const queryparams = stringifyObjectToRequestQuery(query)
            // console.log(queryparams)
            const response = await getHolidaySummary(getFilterState.month);

            return response.data;

        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)

export const offer_ui: any = createAsyncThunk(
    'chart/offerui',
    async (_, thunkApi: any,) => {
        try {


            const getFilterState = thunkApi.getState().dashboard
            const query = {

                bym: getFilterState.monthYear,

            }

            const queryparams = stringifyObjectToRequestQuery(query)
            // console.log(queryparams)
            const response = await getHolidayOfferUi(queryparams);

            return response.data;

        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)



export const dashboardSlicer = createSlice({
    name: "dashboardSlicer",
    initialState,
    reducers: {
        updateOpen(state, action: PayloadAction<any>) {
            state.dashBoardState = action.payload
            // console.log(state?.dashBoardState)
        },
        districtIdState(state, action: PayloadAction<any>) {
            state.districtId = action.payload
        },
        monthYearState(state, action: PayloadAction<any>) {
            state.monthYear = action.payload
        },
        monthState(state, action: PayloadAction<any>) {
            state.month = action.payload
        },
        YearState(state, action: PayloadAction<any>) {
            state.year = action.payload
        },
        updateSummaryData(state, action: PayloadAction<any>) {
            state.summmaryData = action.payload
        },
        updateHolidaySummaryData(state, action: PayloadAction<any>) {
            state.holidaySummaryData = action.payload
        },

        updateHolidayOfferUIDataData(state, action: PayloadAction<any>) {
            state.holidaySummaryData = action.payload
        },
        resetChangedState(state) {
            state.dataChanged = false;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        resetState(state) {
            // state.monthYear = initialState.monthYear;
            state.districtId = initialState.districtId;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(summary_data.fulfilled, (state, action: PayloadAction<any>) => {
            state.summmaryData = action.payload;
            state.isLoading = false

        });
        builder.addCase(summary_data.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(summary_data.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = action.payload || 'Error Occured'
        });

        builder.addCase(holiday_Summary_data.fulfilled, (state, action: PayloadAction<any>) => {
            state.holidaySummaryData = action.payload;
            state.isLoading = false;
            // console.log(action.payload)

        });
        builder.addCase(holiday_Summary_data.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(holiday_Summary_data.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = action.payload || 'Error Occured'
        });


        builder.addCase(offer_ui.fulfilled, (state, action: PayloadAction<any>) => {
            state.offerUIData = action.payload;
            state.isLoading = false;
            // console.log(action.payload)

        });
        builder.addCase(offer_ui.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(offer_ui.rejected, (state, action: PayloadAction<any>) => {
            state.isLoading = false;
            state.message = action.payload || 'Error Occured'
        });
    }
})

export const { updateOpen, setError, resetError, resetChangedState, districtIdState, monthYearState, updateSummaryData, monthState, YearState, updateHolidayOfferUIDataData, resetState } = dashboardSlicer.actions;
export const dashboardReducerState = (state: RootState) => state.dashboard;
export default dashboardSlicer.reducer;

