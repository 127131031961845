import { Link } from 'react-router-dom'

import React from 'react'
import { convertToCamelCase } from 'src/helpers'





const activities = {
    "collection_efficeincy": "Collection Efficency ₦ ",
    "desconnection": "Disconnection Count",
    "is_on_time": "Punctual Attendance",
    "reconnection": "Reconnection Count",
    "event_count": "Visits",
    "collection_coverage": "Collection Coverage",
}


const RedirectToActivity = ({ activity }: any) => {

    return (
        <div >
            {activities[activity as keyof typeof activities] || convertToCamelCase(activity)}
        </div>
    )
}

export default RedirectToActivity