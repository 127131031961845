import { Button } from 'src/components/ui/button'
import { Card, CardHeader } from '../../../components/ui/card'
import React from 'react'
import { Minus } from 'lucide-react'

const Headers = () => {
  return (
    <div className='w-full text-white fixed  
    top-0 left-0 z-50 cursor-default'>
      <Card className='w-full h-14 flex items-center rounded-none px-6 '>

        <h1 className='transition delay-150 hover:-translate-y-1 hover:scale-110 hover:text-green-700 duration-300 ease-in-out text-2xl text-green-600 font-bold flex items-center cursor-pointer'>MIS <Minus className='w-2 mt-1' strokeWidth='5px' />
          <span className='text-sm font-semibold mt-1'>Employee Rewards & Recognition</span></h1>

      </Card>
    </div>
  )
}

export default Headers