

import { Badge } from "src/components/ui/badge"
import { ColumnDef } from "@tanstack/react-table"
import { Check, X } from "lucide-react"
import { ReactNode } from "react"
import { convertToCamelCase } from "src/helpers/index"
import moment from "moment"
import dayjs from "dayjs"
import RedirectToActivity from "./RedirectToActivity"
import ProfileImage from "../StatusByIdComp/ProfileImage"



export type TargetOverflowTable = {
  user_id: number,
  dt: string,
  target: string,
  is_overflow: number,
  atcual_collection: string,
  activity: string,
  name: string,
  profile_image: string
}


export const byIdColumn: ColumnDef<TargetOverflowTable>[] = [

  {
    accessorKey: "dt",
    header: "Date",
    cell: (({ row }) => {
      let startDate: string = row.getValue('dt');

      return dayjs(startDate).format('MMM DD YYYY')
    })
  },
  {
    accessorKey: "job_id",
    header: "Job Id",
  },
  {
    accessorKey: "user_id",
    header: "User Id",
  },

  {
    accessorKey: "name",
    header: "Users",
    cell: ({ row: { original } }) => {
      return <ProfileImage {...original} />
    }
  },

  {
    accessorKey: "activity",
    header: "Activity",
    cell: ({ row: { original } }) => {

      return <RedirectToActivity {...original} />

    }
  },
  {
    accessorKey: "atcual_collection",
    header: "Achieved Value",
  },
  {
    accessorKey: "target",
    header: "Target Value",

  },
  {
    accessorKey: "is_overflow",
    header: "Overflow",

    cell: ({ row }) => {
      const match = parseFloat(row.getValue("is_overflow"))

      if (match === 1) {
        // return <Check className=" text-green-600"/>
        return <Badge
          className=" bg-transparent border-primary text-primary hover:bg-transparent hover:text-primary  rounded-full">Success</Badge>
        //
      } else {
        // return <X className=" text-red-600"/>

        return <Badge className=" bg-transparent border-destructive text-destructive hover:bg-transparent hover:text-destructive rounded-full"  >Fail</Badge>
      }
    },
  }
]
