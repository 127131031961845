import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import { useQueryResponse } from '../Core/QueryResponseProvider';
import React, { useEffect, useMemo, useState } from 'react';
import { convertToCamelCase } from '../../../../helpers';


import UserCharts from './UserCharts';
import UserChartsSummary from './UserChartsSummary';
import DateBoxesUser from '../UserEditableComponent/DateBoxesUser';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { editableReducerState } from 'src/redux/slicer/editableSlicer';
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer';
export interface IdailyData {
  dt?: string,
  x?: number,
  actual_points?: number,
  total_bonus?: number,
  today_collection?: string,
  cumulative_collection?: number,
  total_points?: number
}
export interface IymaxValue {
  xAxisValue: number,
  yAxisxValue: number
}
export interface Summary {
  bucket: number,
  acheived: number,
  actual_points: number,
  total_bonus: number
}
type Props = {}

const UserEditableChartOnetoTen = (props: Props) => {
  const { data, isLoading } = useSelector((state: RootState) => editableReducerState(state))
  const [dataValue, setDataValue] = useState<IdailyData | null>();
  const [y_maxValue, setYmaxValue] = useState<IymaxValue[] | null>()
  const [targets, setTargets] = useState<number>(0);
  const [summary, setSummary] = useState<Summary | null>()

  // const dataValues:IdailyData|null=useMemo(()=>isLoading?[]:(Array.isArray(data?.daily_data)?data?.daily_data.slice(0, 10) :[]),[isLoading,data])
  // const summrys:Summary|null=useMemo(()=>isLoading?[]:(data && Array.isArray(data?.week_wise)?data?.week_wise[0]:{}),[isLoading,data])
  // const target:number|undefined=useMemo(()=>isLoading?undefined:(data?data?.target:0),[isLoading,data])
  // const y_maxValues:IymaxValue[]|null=useMemo(()=>isLoading?[]:(data?data?.y_max:[]),[isLoading,data])

  useEffect(() => {
    if (data && Array.isArray(data?.daily_data)) {
      setDataValue(data?.daily_data.slice(0, 10))
    }

    if (data && data?.week_wise && Array.isArray(data?.week_wise)) {
      setSummary(data?.week_wise[0])
    }

    if (data && data?.target) {
      setTargets(data?.target)
    }

    if (data && Array.isArray(data?.y_max)) {
      setYmaxValue(data?.y_max)
    }
  }, [data])

  const datas = useMemo(() => dataValue && Array.isArray(dataValue) ? dataValue[dataValue.length - 1] : {}, [dataValue])

  const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state))


  return (
    <>

      <div className={`mt-2 px-4 ${dashBoardState ? 'ml-1' : 'ml-8'}`}>
        <DateBoxesUser data={dataValue || []} arrInd={10} ind={0} color='bg-green-600' />
      </div>


      <div className='text-center mt-2'>

        <UserCharts details={dataValue || []} summary={summary}
          targets={targets} color='rgb(22 163 74)'
          y_maxValue={y_maxValue || []}
          textColor='text-green-600' />
      </div>

      <div className=''>

        <UserChartsSummary datas={datas} color='text-green-600' targets={targets} details={dataValue || []} />

      </div>

    </>
  )
}

export default UserEditableChartOnetoTen;


