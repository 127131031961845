import { Card, CardContent, CardHeader, CardTitle } from 'src/components/ui/card'
import React, { useMemo } from 'react'
import { Equal, Plus } from 'lucide-react'
import Niara from './Niara.svg';
import { cn } from 'src/helpers';
import { useQueryResponse } from '../../Context/Core/QueryResponseProvider';
import { useQueryResponseLoading } from '../index';

interface ChartsSummaryProps {
    datas?: {
        actual_points: number;
        bonus_points1: number;
        bonus_points2: number;
        bonus_points3: number;
        total_points: number;
        cumulative_collection: string;
        user_id?: number,
        actual_collection?: number,
        dt?: string,
        final_points: number,
    };


    color?: string;
    word?: string;
}
const TotalChartSummary: React.FC<ChartsSummaryProps> = ({ color, word }) => {

    const { response } = useQueryResponse();



    const apiResponse: any = useMemo(() => response, [response])
    const isLoading = useQueryResponseLoading();
    const tableData: any = useMemo(() => isLoading ? [] : (apiResponse ? apiResponse?.data : []), [isLoading, apiResponse])
    const axisData12 = useMemo(() => isLoading ? [] : (tableData ? tableData?.y_max : []), [tableData, isLoading])


    const valueMax = useMemo(() => axisData12 ? axisData12[axisData12?.length - 1].yAxisxValue.toFixed(2) : 0, [axisData12])
    const totals: any = useMemo(() => isLoading ? {} : (tableData && tableData?.total ? tableData?.total[0] : {}), [isLoading, tableData])

    return (
        <Card className={cn(`w-full rounded-none  text-gray-500`)}>


            <CardContent className='flex items-center justify-center space-x-2 mt-2'>
                <div className='border-[2px] border-gray-400 p-3  rounded-lg text-gray-900'>
                    <h3 className='text-xs font-bold text-gray-800 mt-1'>{word} Performance Point</h3>
                    <h3 className='  text-sm font-semibold text-center text-gray-900' >
                        {totals?.actual_points ? Intl.NumberFormat('en-US').format(totals.actual_points.toFixed(2)) : "0.00"}</h3>

                </div>
                <div > <Plus color='#000000' size={16} /></div>
                <div className='border-2 border-green-600 p-3  rounded-lg text-gray-900'>
                    <h3 className='text-xs font-bold text-gray-800 mt-1'> Bonus Point 1</h3>
                    <h3 className=' text-sm font-semibold text-center text-gray-900'>
                        {totals?.bonus_points1 ? Intl.NumberFormat('en-US').format(totals.bonus_points1.toFixed(2)) : "0.00"}</h3>


                </div>
                <div > <Plus color='#000000' size={16} /></div>
                <div className='border-2 border-sky-600 p-3  rounded-lg text-gray-900'>
                    <h3 className='text-xs font-bold text-gray-800 mt-1'> Bonus Point 2</h3>
                    <h3 className='text-sm font-semibold text-center text-gray-900'>
                        {totals?.bonus_points2 ? Intl.NumberFormat('en-US').format(totals.bonus_points2.toFixed(2)) : "0.00"}</h3>


                </div>
                <div > <Plus color='#000000' size={16} /></div>
                <div className='border-2 border-amber-600 p-3 rounded-lg amber-600 text-gray-900'>
                    <h3 className='text-xs font-bold text-gray-800 mt-1'> Bonus Point 3</h3>
                    <h3 className=' text-sm font-semibold text-center text-gray-900'>
                        {totals?.bonus_points3 ? Intl.NumberFormat('en-US').format(totals.bonus_points3.toFixed(2)) : "0.00"}</h3>


                </div>
                <div> <Equal color='#000000' size={16} /></div>
                <div className='border-[2px] border-gray-700 p-3  rounded-lg text-gray-900'>
                    <h3 className='text-xs font-bold text-gray-800 mt-1  text-center'>Achived Points </h3>
                    <h3 className=' text-sm font-semibold text-center'> {totals?.final_points ? Intl.NumberFormat('en-US').format(totals.final_points.toFixed(2))
                        : "0.00"} Out of {Intl.NumberFormat('en-US').format(valueMax)}</h3>

                </div>
                <div>
                </div>

            </CardContent>



        </Card>
    )
}

export default TotalChartSummary
