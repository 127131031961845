import React from 'react'
import { useMainContext } from './MainContext'
import { optionsType } from './modules'
import { X } from 'lucide-react'

type Props = {

}

const RenderSelectedValues = ({ }: Props) => {

    const { selectedValues, toggleValue } = useMainContext()

    return (
        <div className='flex flex-wrap flex-1 '>
            {selectedValues && selectedValues.icons ? <span >{selectedValues.icons}</span> : null}
            {selectedValues && selectedValues.value ? <span className='text-xs font-medium text-gray-800'>{selectedValues.label}</span> : null}
        </div>
    )
}

export default RenderSelectedValues