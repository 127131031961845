import React, { useEffect, useMemo, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState, VisibilityState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { DataTablePagination } from './data-table-pagination';

import { ScrollArea } from 'src/components/ui/scroll-area';
import { Loader2 } from 'lucide-react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { OfferUIProps, offerReducerState, offer_summury_table, setPaggination } from 'src/redux/slicer/offerSlicer';
import ConsumerComp from './ConsumerComp';



type Props = {}
interface DataTableProps<TData, TValue> {
  // columns: ColumnDef<TData, TValue>[]
  data: TData[]
}


const OfferTable = <TData, TValue>({ data }: DataTableProps<TData, TValue>) => {
  const { tableLoading, offerUIData, items_per_page, page, total_records, total } = useSelector((state: RootState) => offerReducerState(state))

  const dropDownData: OfferUIProps = useMemo(() => offerUIData ? offerUIData : { date: [], category: [], offer_names: [] }, [offerUIData]);
  const columndata: string[] | [] = useMemo(() => dropDownData ? (Array.isArray(dropDownData.date) && dropDownData.date.length > 0 ? dropDownData.date : []) : [], [dropDownData])
  const dispatch = useDispatch()

  const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 50,
    })

  useEffect(() => {
    // console.log({ pageIndex, pageSize });

    dispatch(setPaggination({ pageIndex: pageIndex + 1, pageSize }))
    dispatch(offer_summury_table())
    return () => { }
  }, [pageIndex, pageSize])

  const pagination = React.useMemo(
    () => ({
      pageIndex: page - 1,
      pageSize: items_per_page,
    }),
    [page, items_per_page]
  )

  const columnOffer: ColumnDef<any>[] = [
    {
      accessorKey: "name",
      header: "Consumer",

      cell: ({ row: { original } }) => {
        return <ConsumerComp {...original} />;
      },
    },
    {
      accessorKey: "total_points",
      header: "Total",
    },
  ];



  const columnDyanmic: ColumnDef<any>[] = useMemo(() =>
    Array.isArray(columndata) && columndata.length > 0
      ? columndata.map((cur: string) => ({
        accessorKey: cur,
        header: cur,
      }))
      : [],
    [columndata]
  );

  const columns: ColumnDef<any>[] = useMemo(() => [...columnOffer, ...columnDyanmic], [columnOffer, columnDyanmic]);


  const pageCounts = useMemo(() => total ? Number(total) : 1, [total])

  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },
    pageCount: pageCounts,
    enableRowSelection: true,
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    onPaginationChange: setPagination,
    //  onPaginationChange: ()=> updateS,
  })



  return (
    <>


      <ScrollArea className="w-full h-[445px] relative rounded-md ">
        <Table className="text-sm  rtl:text-right text-gray-500 dark:text-gray-400 relative">
          <TableHeader className=" text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-center hover:bg-gray-400  sticky top-0 left-0 right-0 table-auto">
            {table.getHeaderGroups().map((headerGroup) => <TableRow key={`${headerGroup.id}`} className='w-full'>
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead key={header.id} className="text-left cursor-default text-gray-900 text-xs font-bold" scope='column'>
                    {header.isPlaceholder ? null :
                      flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </TableHead>
                )
              })}
            </TableRow>)}

          </TableHeader>

          <TableBody
            className="">
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                  // onClick={() =>handleClick(row.original)}
                  className='odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 hover:bg-gray-200' >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id} className=" text-left overflow-clip" scope="row" >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={columns.length} className="h-24 text-center">
                  {tableLoading ? <div className='flex justify-center items-center '>
                    <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
                  </div> : "No results."}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </ScrollArea>
      <DataTablePagination table={table} />

    </>
  )
}

export default OfferTable

