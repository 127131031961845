

// import { useQueryResponse } from '../Core/QueryResponseProvider';
import React, { useEffect, useMemo, useState } from 'react'
import { cn, convertToCamelCase } from '../../../../helpers';
// import { dataAxis } from './dataAxis';
import UserCharts from './UserCharts';
import UserChartsSummary from './UserChartsSummary';
import { endOfDay, format, startOfDay } from 'date-fns'
import DateBoxesUser from '../UserEditableComponent/DateBoxesUser';
import { useSelector } from 'react-redux';
import { editableReducerState } from 'src/redux/slicer/editableSlicer';
import { RootState } from 'src/redux/store';
import { IdailyData, IymaxValue, Summary } from './UserEditableChartOnetoTen';
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer';
type Props = {}

function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}

const UserEditableChartTwentyOneEnd = (props: Props) => {
  let fullMonth = format(new Date(), "MMM")
  let currentYears = format(new Date(), "yyyy")


  const { data, isLoading } = useSelector((state: RootState) => editableReducerState(state))
  const [dataValue, setDataValue] = useState<IdailyData | null>();
  const [y_maxValue, setYmaxValue] = useState<IymaxValue[] | null>()
  const [targets, setTargets] = useState<number>(0);
  const [summary, setSummary] = useState<Summary | null>();
  const [noOfDays, setNoOfDaya] = useState<number>(30)



  useEffect(() => {
    if (data && Array.isArray(data?.daily_data)) {
      setDataValue(data?.daily_data.slice(20))
    }

    if (data && data?.week_wise && Array.isArray(data?.week_wise)) {
      setSummary(data?.week_wise[0])
    }

    if (data && data?.target) {
      setTargets(data?.target)
    }

    if (data && Array.isArray(data?.y_max)) {
      setYmaxValue(data?.y_max)
    }
  }, [data])


  useEffect(() => {
    ;
    const date = new Date(new Date(data && Array.isArray(data) ? data[0]?.dt : `${fullMonth}-${currentYears}`));
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1;


    const daysInCurrentMonth = getDaysInMonth(
      currentYear,
      currentMonth,

    )
    setNoOfDaya(daysInCurrentMonth)
  }, [data])

  const datas = useMemo(() => dataValue && Array.isArray(dataValue) ? dataValue[dataValue.length - 1] : {}, [dataValue])
  const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state))
  return (
    <>

      <div className={cn('mt-2 px-1', {
        'px-4': noOfDays <= 30 && noOfDays !== 28,
        'px-12': noOfDays === 28,
        'ml-6': !dashBoardState
      })}>
        <DateBoxesUser data={dataValue || []} arrInd={noOfDays} ind={20} color='bg-amber-600' />
      </div>

      <div className='text-center mt-2'>



        <UserCharts details={dataValue || []}
          summary={summary}
          targets={targets}
          y_maxValue={y_maxValue || []}
          color='rgb(217 119 6)'
          textColor='text-amber-600' />
      </div>
      <div className=''>

        <UserChartsSummary datas={datas} color='text-amber-600' targets={targets} details={dataValue || []} />

      </div>
    </>
  )
}

export default UserEditableChartTwentyOneEnd


