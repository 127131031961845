export interface optionsType {
  value: string;
  label: string;
  icons?: string;
  disabled?: boolean;
}

export interface ContextProps {
  selectedValues: optionsType | undefined;
  toggleValue: (e: any) => void;
}

export const initialContext = {
  selectedValues: undefined,
  toggleValue: () => {},
};
