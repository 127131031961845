import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import { Button } from 'src/components/ui/button';
import { cn } from 'src/helpers';

import { punchReducerState } from 'src/redux/slicer/punchEditableSlicer';
import { RootState } from 'src/redux/store';

interface LineChartsProps {
    details?: any,
    summary?: any,
    color?: string,
    textColor?: string
    targets?: number
    y_maxValue?: any
}

const OtherActivityUserCharts = ({ color, y_maxValue }: LineChartsProps) => {
    const [opacity, setOpacity] = useState<any>({
        performancePoints: 1,
    });
    const { data, isLoading } = useSelector((state: RootState) => punchReducerState(state))

    const apiResponse: any = useMemo(() => data, [data]);
    const totalData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? apiResponse?.daily_data : []),
        [apiResponse, isLoading]
    );

    const y_max: any = useMemo(
        () => (apiResponse ? apiResponse?.max_points : []),
        [apiResponse]
    );

    // console.log(y_max)

    // console.log(y_max)
    const maxVals: number = useMemo(() => y_max ? Number(y_max[y_max.length - 1]?.yAxisxValue) : 0, [y_max])
    // console.log(maxVals)

    const xAxisValues: any = useMemo(() => totalData ? totalData?.map((cur: any) => ({

        "xAxisValue": (cur?.x * 100).toFixed(2),
        "Performance Points": cur?.actual_points.toFixed(2),
        "Date": cur?.dt
    })) : [], [totalData])


    const CustomTooltip = ({ active, payload, label }: any) => {
        if (active && payload && payload.length) {
            return (
                <div className="custom-tooltip text-left">
                    {/* <p>{`Percentage: ${label * 100}`}</p> */}

                    {payload.map((point: any) => (
                        <p key={point.name} style={{ color: point.color }} className='text-sm font-medium '>
                            {`${point.name}: ${point.value}`}
                        </p>
                    ))}
                    <p className='text-sm font-medium ' style={{ color: `${color}` }}>{payload[0].payload.Date === undefined ? "" : (`Date: ${payload[0].payload.Date}`)}</p>
                    <p className='text-sm font-medium ' style={{ color: `blue` }}>{payload[0].payload.xAxisValue === undefined ? "" : (`Achived Percentage: ${payload[0].payload.xAxisValue}%`)}</p>
                </div>
            );
        }

        return null;
    };



    return (
        <>
            <LineChart
                width={400}
                height={300}
                margin={{
                    top: 5,
                    right: 10,
                    left: 20,
                    bottom: 5,
                }}
                data={y_max}
            >
                <XAxis
                    dataKey='xAxisValue'
                    type='number'
                    style={{ "fontSize": "12px" }}

                    domain={[0, 100]} tickCount={15}
                />

                <YAxis
                    style={{ "fontSize": "12px" }}
                    domain={[0, maxVals]}
                    dataKey='yAxisValue'
                    tickCount={15} />

                <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

                <Tooltip content={<CustomTooltip />} />


                <Line
                    type="monotone"
                    dataKey="yAxisxValue"
                    name="Base Line"
                    stroke="rgb(229 231 235)"
                    legendType='none'
                    data={y_max}
                />

                <Line
                    type="monotone"
                    data={xAxisValues}
                    dataKey='Performance Points'
                    stroke='#8b0a50'
                    strokeWidth={1}
                    opacity={opacity.performancePoints} />
            </LineChart>
            <div className='flex space-x-6 px-4 justify-center items-center'>
                <span onClick={() => {
                    setOpacity((prevOpacity: any) => ({
                        ...prevOpacity,
                        performancePoints: prevOpacity.performancePoints === 1 ? 0 : 1,
                    }));
                }}
                    className={cn('cursor-pointer text-sm hover:underline text-pink-600 font-medium',
                        { 'text-gray-400': opacity.performancePoints === 0 })}>
                    Peformance Points</span>
            </div>

        </>
    )
}

export default OtherActivityUserCharts



