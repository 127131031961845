


import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
// import { useQueryResponse } from '../Core/QueryResponseProvider';
import React, { useEffect, useMemo, useState } from 'react';
import { convertToCamelCase } from '../../../../helpers';
// import {  dataAxis_2 } from './dataAxis';
import UserCharts from './UserCharts';
import UserChartsSummary from './UserChartsSummary';
import DateBoxesUser from '../UserEditableComponent/DateBoxesUser';
import { useSelector } from 'react-redux';
import { editableReducerState } from 'src/redux/slicer/editableSlicer';
import { RootState } from 'src/redux/store';
import { IdailyData, IymaxValue, Summary } from './UserEditableChartOnetoTen';
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer';


type Props = {}

const UserEditableChartsElevenTwenty = (props: Props) => {
  const { data, isLoading } = useSelector((state: RootState) => editableReducerState(state))
  const [dataValue, setDataValue] = useState<IdailyData | null>();
  const [y_maxValue, setYmaxValue] = useState<IymaxValue[] | null>()
  const [targets, setTargets] = useState<number>(0);
  const [summary, setSummary] = useState<Summary | null>()

  const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state))

  useEffect(() => {
    if (data && Array.isArray(data?.daily_data)) {
      setDataValue(data?.daily_data.slice(10, 20))
    }

    if (data && data?.week_wise && Array.isArray(data?.week_wise)) {
      setSummary(data?.week_wise[0])
    }

    if (data && data?.target) {
      setTargets(data?.target)
    }

    if (data && Array.isArray(data?.y_max)) {
      setYmaxValue(data?.y_max)
    }
  }, [data])
  const datas = useMemo(() => dataValue && Array.isArray(dataValue) ? dataValue[dataValue.length - 1] : {}, [dataValue])
  return (
    <>

      <div className={`mt-2 px-6 ${dashBoardState ? '' : 'ml-6'}`}>
        <DateBoxesUser data={dataValue || []} arrInd={20} ind={10} color='bg-sky-600' />
      </div>

      <div className='text-center mt-2'>

        <UserCharts details={dataValue || []}
          summary={summary} targets={targets}
          color='rgb(2 132 199)'
          y_maxValue={y_maxValue || []}
          textColor='text-sky-600' />
      </div>

      <div className=''>

        <UserChartsSummary datas={datas} color='text-sky-600' targets={targets} details={dataValue || []} />

      </div>
    </>
  )
}

export default UserEditableChartsElevenTwenty;


