import RewarsTable from './DashboardComp/RewarsTable'
import DashboardSummary from './DashboardComp/DashboardSummary'
import EffExp from './DashboardComp/EffExp'
import SearchFilter from './DashboardComp/SearchFilter'
import React, { useEffect, useMemo } from 'react'
import { EmployeeColums } from './DashboardComp/EmployeeColums'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { reward_api_call, reward_summury_table, rewardsReducerState } from 'src/redux/slicer/rewardsDashboard'
import { toast } from 'react-toastify'

const DashboardMain = () => {

    const { rewardsTableData, rewardsTableLoading, employeeName, employeeId, error, message } = useSelector((state: RootState) => rewardsReducerState(state))
    const dispatch = useDispatch()
    useEffect(() => {

        dispatch(reward_api_call())

    }, [])

    useEffect(() => {
        if (error) {
            toast.error(message)
        }
        return () => { }
    }, [error, message])

    const tableData = useMemo(() => rewardsTableLoading ? [] : (Array.isArray(rewardsTableData) && rewardsTableData?.length > 0 ? rewardsTableData : []), [rewardsTableData, rewardsTableLoading])
    // console.log(tableData)
    return (
        <div className='w-full'>
            <SearchFilter />
            <EffExp />
            <DashboardSummary />
            <RewarsTable columns={EmployeeColums} data={tableData || []} />
        </div>
    )
}

export default DashboardMain