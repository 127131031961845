
interface DropDoenModel {
    name: string,
    value: string
}

export const editableDropDownModel: DropDoenModel[] = [
    {
        name: " Collection Coverage",
        value: "collection_coverage",
    },
    {
        name: "Collection Efficency ₦",
        value: "collection_efficeincy",
    },
    {
        name: "Disconnection Count",
        value: "desconnection",
    },
    {
        name: "Reconnection Count",
        value: "reconnection",
    },

]


export const punchdROPDownModel: DropDoenModel[] = [
    {
        name: " Punctual Attendance",
        value: "is_on_time",
    },
    {
        name: "Visits",
        value: "event_count",
    },
]
