import { IPointscollection } from "../Core/modules";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "src/components/ui/input";

import { punchReducerState, updateDailyData } from "src/redux/slicer/punchEditableSlicer";
import { RootState } from "src/redux/store";

const TableCelldata = ({ dt, today_collection }: IPointscollection) => {
  const [value, setValue] = useState<string | undefined>(today_collection)


  const { dateFilter, setCollectionPerValue } = useSelector((state: RootState) => punchReducerState(state))
  const dispatch = useDispatch();
  const achievedValue = setCollectionPerValue !== undefined && setCollectionPerValue !== null ? setCollectionPerValue : today_collection;
  useEffect(() => {
    setValue(today_collection);
  }, [dateFilter, today_collection]);

  useEffect(() => {
    if (setCollectionPerValue !== undefined && setCollectionPerValue !== null) {
      setValue(setCollectionPerValue);
      dispatch(updateDailyData({ dt, achieved: setCollectionPerValue }))
    }
  }, [setCollectionPerValue])
  return (
    <Input
      value={value}
      onChange={e => {
        setValue(e.target.value)

        if (today_collection !== e.target.value) {
          dispatch(updateDailyData({ dt, achieved: e.target.value }))
        }
      }}
      type="text"
    />
  )
}

export default TableCelldata