
import React, { useEffect, useState } from 'react';
import { format, getYear, setYear, setMonth } from 'date-fns';
import { Button } from 'src/components/ui/button';
import { cn } from 'src/helpers/index';
import { Popover, PopoverContent, PopoverTrigger } from 'src/components/ui/popover';
import { Select, SelectContent, SelectGroup, SelectItem, SelectLabel, SelectTrigger, SelectValue } from '../../../components/ui/select';
import { useDispatch, useSelector } from 'react-redux';

import { RootState } from 'src/redux/store';
import { monthYearState } from 'src/redux/slicer/dashboardSlicer';

export function MonthPicker({ onChange }: { onChange: (date: Date) => void }) {
    const [year, setYearState] = React.useState<number>(getYear(new Date()));

    const handleMonthClick = (monthIndex: number) => {
        const selectedDate = setMonth(setYear(new Date(), year), monthIndex);
        onChange(selectedDate);
    };

    const monthsArray = Array.from({ length: 12 }, (_, index) => index);
    const chunks = (array: any[], size: number) =>
        Array.from({ length: Math.ceil(array.length / size) }, (_, index) =>
            array.slice(index * size, index * size + size)
        );
    const monthsInRows = chunks(monthsArray, 4);

    const yearArray = [];
    for (let i = 2022; i <= 2024; i++) {
        yearArray.push(i);
    }

    return (
        <div className="my-2">
            <div className="p-1">
                <Select onValueChange={(value: any) => setYearState(value)}>
                    <SelectTrigger className="w-full"
                        defaultValue={year?.toString()}
                        value={year?.toString()}>

                        <SelectValue placeholder="Select Year" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectLabel>Select Year</SelectLabel>
                            {yearArray.map((cur, index) => (
                                <SelectItem value={`${cur}`} key={`${cur}-${index}`} className='text-sm font-medium text-gray-800'>
                                    {`${cur}`}
                                </SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>

                {monthsInRows.map((row, rowIndex) => (
                    <div key={rowIndex} className="grid grid-cols-4 gap-1 mt-1">
                        {row.map((index: any) => (
                            <div
                                key={index}
                                className="p-2 text-center cursor-pointer text-gray-800 text-xs font-medium hover:bg-gray-400 rounded-lg"
                                onClick={() => handleMonthClick(index)}
                            >
                                {format(setMonth(new Date(), index), 'MMMM').slice(0, 3)}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
        </div>
    );
}

interface DateMonthFilterProps {
    setMonthFilter?: (formattedDate: string) => void;
    monthYear?: string;
    loading?: boolean
}

function DateFilter({ setMonthFilter, monthYear, loading }: DateMonthFilterProps) {
    const [selectedMonth, setSelectedMonth] = React.useState<Date | undefined>(undefined);



    const currentDate = new Date();
    const year = currentDate.toLocaleString('default', { year: '2-digit' });
    const currentMonth = currentDate.toLocaleString('default', { month: 'short' });

    // console.log(monthYear, "yearmonth")

    const [date, setDate] = useState<string>(`${currentMonth}-${year}`);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const dispatch = useDispatch()
    useEffect(() => {
        setDate(monthYear ? monthYear : `${currentMonth}-${year}`);
    }, [monthYear, currentMonth, year]);

    const handleMonthChange = (date: Date) => {
        setSelectedMonth(date);
    };

    const formattedDate = selectedMonth ? format(selectedMonth, 'MMM-yy') : date;

    React.useEffect(() => {
        // setMonthFilter(formattedDate);
        dispatch(monthYearState(formattedDate))
        if (selectedMonth) {
            setIsOpen(false);
        }
    }, [formattedDate, selectedMonth, setMonthFilter, dispatch]);

    return (
        <Popover open={isOpen} onOpenChange={(open: boolean) => setIsOpen(open)}>
            <PopoverTrigger asChild>
                <Button
                    variant={'outline'}
                    className={cn('w-full justify-center text-left font-normal mt-2  mr-10',
                        !selectedMonth && 'text-muted-foreground')}
                    disabled={loading}>
                    <span className="font-medium text-black">{formattedDate}</span>
                </Button>
            </PopoverTrigger>
            <PopoverContent className="w-auto p-0">
                <MonthPicker onChange={handleMonthChange} />
            </PopoverContent>
        </Popover>
    );
}

export default DateFilter;
