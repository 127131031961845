import { SummaryByActivityMainState } from '../index'
import { ReportTable } from './columnsSummaryByActivity'
import React, { ReactElement, ReactNode, ReactPropTypes } from 'react'
import { Link } from 'react-router-dom'


interface props extends ReportTable {
    children: ReactNode
}

const RedirectToUserActivity = ({
    user_id, children
}: props) => {
    const { state } = SummaryByActivityMainState()
    return (

        <Link to={`/dashboard/dv/bucketwise-point?userId=${user_id}&bym=${state.filter?.bym}&activity=${state.filter?.activity}`}>
            {children}
        </Link>
    )
}

export default RedirectToUserActivity