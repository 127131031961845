import axios, { AxiosResponse } from "axios";
import { REWARDS_DETAILS_REPORT } from "src/service/apis";

const getDetailReport = (post: any) => {
  return axios
    .post(`${REWARDS_DETAILS_REPORT}`, post)
    .then((d: AxiosResponse<any>) => d.data);
};

export { getDetailReport };
