import React, { useEffect } from 'react'

import EditableComponent from './Component/EditableComponent'
import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { editableReducerState } from 'src/redux/slicer/editableSlicer'
import { toast } from 'react-toastify'

type Props = {}

const Main = (props: Props) => {
  return (
    <div className='mt-[56px] w-full '>
      <EditableComponent />
    </div>
  )
}

const EditableComponentMains: React.FC<Props> = ({ }) => {
  const { error, message } = useSelector((state: RootState) => editableReducerState(state))
  useEffect(() => {
    if (error) {
      toast.error(message)
    }
    return () => { }
  }, [error, message])
  return <>
    <Main />
  </>
}

export default EditableComponentMains