import axios, { AxiosResponse } from "axios";
import { GET_DISTRICT_URL } from "src/service/apis";

const getDistrictDropDown = () => {
  return axios
    .get(`${GET_DISTRICT_URL}`)
    .then((d: AxiosResponse<any>) => d.data);
};

export { getDistrictDropDown };
