import React, { useMemo } from 'react'
import card from './Images/rm209-adj-20.jpg'
import { GET_PROFILE_PICTURE } from 'src/service/apis';
const List_2 = ({ data }: any) => {

    const profile_image = useMemo(() => Object.entries(data)?.length > 0 ? data?.profile_image : '', [data]);
    const name = useMemo(() => Object.entries(data)?.length > 0 ? data?.name : '', [data]);
    const total = useMemo(() => Object.entries(data)?.length > 0 ? data?.total : 0, [data]);
    const target_points = useMemo(() => Object.entries(data)?.length > 0 ? data?.target_points : 0, [data]);
    const rank = useMemo(() => Object.entries(data)?.length > 0 ? data?.rank : 0, [data]);


    return (
        <>
            <div style={{ backgroundImage: `url(${card})` }} className="w-full flex items-center  space-x-10 justify-center z-10 px-6 py-8 relative overflow-hidden rounded-lg bg-cover bg-no-repeat  text-center ">
                <div className='w-full '>
                    <div className='w-32 h-32 rounded-full flex items-center justify-center text-center'>
                        <img alt='Profile' src={profile_image !== '' ? `${GET_PROFILE_PICTURE}${profile_image}` : 'https://www.w3schools.com/howto/img_avatar.png'} className='w-32 h-32 rounded-full' />
                    </div>
                </div>


                <div className='w-full text-left font-mono text-base text-gray-800'>
                    <h1 className="w-full  whitespace-nowrap  font-bold">{name}</h1>
                    <div className="w-full  font-semibold">Rank - <span className=' font-bold text-base'>{rank}</span> </div>
                    <div className='w-full mr-10 flex items-center  space-x-2 mt-2'>
                        <div className='w-3 h-3 rounded-full bg-yellow-600 flex justify-center items-center text-left '><div className='w-1 h-1 rounded-full bg-white'></div></div>
                        <h1 className='text-base font-bold text-blue-800 font-mono'>{`${Math.floor(Number(total))}`}</h1>
                    </div>

                    <div className='w-full mr-10 flex items-center  space-x-2'>
                        <div className='w-3 h-3 rounded-full bg-green-600 flex justify-center items-center text-left'><div className='w-1 h-1 rounded-full bg-white'></div></div>
                        <h1 className='text-base font-bold text-blue-800 font-mono'>{`${Math.floor(Number(target_points))}`} </h1>
                    </div>
                </div>
            </div>
        </>

    )
}

export default List_2