import { Check, X } from "lucide-react";
import React, { useEffect } from "react";
import { Button } from "src/components/ui/button";
import { Badge } from "src/components/ui/badge";
import { getFailJobMakeSuccess } from "src/service/services";

import { useQueryResponse } from "../core/QueryResponseProvider";
import axios, { AxiosError } from "axios";
import { AchievedJobSummaryMainState } from "../index";
import { toast } from "react-toastify";
interface AchievedSummary {
  id: number;
  is_success: number;
  onClick?: (value: number) => void;
}
const AchieveJobSummaryId = ({ id, is_success }: AchievedSummary) => {
  const { state, updateState } = AchievedJobSummaryMainState();
  const { refetch } = useQueryResponse();

  const handleClick = async (
    id: number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
    try {
      const response = await getFailJobMakeSuccess(id);
      refetch();

    } catch (err) {
      const error = err as Error | AxiosError;
      if (axios.isAxiosError(error)) {
        toast.error(error.response?.data?.message)
      }
      else {
        toast.error(error.message)
      }
    }
  };

  return (
    <div>
      {is_success === 1 ? (
        <Badge className=" bg-transparent border-primary text-primary hover:bg-transparent hover:text-primary rounded-full">
          Success
        </Badge>
      ) : (
        <div className="space-x-3">
          <Badge className=" bg-transparent border-destructive text-destructive hover:bg-transparent hover:text-destructive rounded-full">
            Fail
          </Badge>
          <Button
            variant="error"
            className="text-xs font-normal w-10 h-5"
            onClick={(event) => handleClick(id, event)}
          >
            Retry
          </Button>
        </div>
      )}
    </div>
  );
};

export default AchieveJobSummaryId;
