import { TopThreeProps } from '../../Component/WallOfFame'
import React, { useMemo } from 'react'
import slider from '../WallOfFameComponent/Images/ScSliderCardBackground.jpg'
import { GET_PROFILE_PICTURE } from 'src/service/apis'
import { Badge } from 'src/components/ui/badge'
import { useNavigate } from 'react-router-dom'
import { WallOfFameMainState } from '../index'
const DistrictwiseList = ({ data }: any) => {
    const { state, updateState } = WallOfFameMainState();
    const dist_name: any = useMemo(() => Object.entries(data)?.length > 0 ? data?.dist_name : '', [data])

    const mainData: any = useMemo(() => Object.entries(data)?.length > 0 ? data?.data : [], [data]);
    // profile_image, name, total,target_points
    const profile_image_1 = useMemo(() => Array.isArray(mainData) && mainData?.length > 0 ? mainData[0]?.profile_image : '', [mainData])
    const profile_image_2 = useMemo(() => Array.isArray(mainData) && mainData?.length > 1 ? mainData[1]?.profile_image : '', [mainData])
    const profile_image_3 = useMemo(() => Array.isArray(mainData) && mainData?.length > 2 ? mainData[2]?.profile_image : '', [mainData])

    const name_1 = useMemo(() => Array.isArray(mainData) && mainData?.length > 0 ? mainData[0]?.name : '_', [mainData])
    const name_2 = useMemo(() => Array.isArray(mainData) && mainData?.length > 1 ? mainData[1]?.name : '_', [mainData])
    const name_3 = useMemo(() => Array.isArray(mainData) && mainData?.length > 2 ? mainData[2]?.name : '_', [mainData])

    const total_1 = useMemo(() => Array.isArray(mainData) && mainData?.length > 0 ? mainData[0]?.total : 0, [mainData])
    const total_2 = useMemo(() => Array.isArray(mainData) && mainData?.length > 1 ? mainData[1]?.total : 0, [mainData])
    const total_3 = useMemo(() => Array.isArray(mainData) && mainData?.length > 2 ? mainData[2]?.total : 0, [mainData])

    const target_points_1 = useMemo(() => Array.isArray(mainData) && mainData?.length > 0 ? mainData[0]?.target_points : 0, [mainData])
    const target_points_2 = useMemo(() => Array.isArray(mainData) && mainData?.length > 1 ? mainData[1]?.target_points : 0, [mainData])
    const target_points_3 = useMemo(() => Array.isArray(mainData) && mainData?.length > 2 ? mainData[2]?.target_points : 0, [mainData])

    const navigate = useNavigate();

    let ym = useMemo(() => Object.entries(state?.filter)?.length > 0 ? state?.filter?.ym : '', [state?.filter?.ym]);


    const handleClick = () => {
        navigate(`/dashboard/wall-of-fame/wl/district?dist=${dist_name}&ym=${state?.filter?.ym}`)
    }


    return (
        <>
            {dist_name !== undefined ? (<div className='w-4/5 py-4'
                onClick={handleClick}>
                <div className='w-full flex justify-center mb-2'>
                    <Badge className='z-50 -mt-3 bg-green-700 absolute text-white font-medium text-sm rounded-full px-4 py-2'>{dist_name}</Badge>
                </div>
                <div
                    style={{ backgroundImage: `url(${slider})` }} className="w-full z-10 h-[270px] relative overflow-hidden rounded-lg bg-cover bg-no-repeat  text-center ">

                    <div className='w-full grid grid-cols-3 space-x-2 mt-8 px-8'>
                        <div className=''>

                            <div className='w-full flex justify-center h-24 items-center'>

                                <div className='flex items-center justify-center border-2 border-amber-600 rounded-full w-16 h-16 text-center'>
                                    <img alt='Profile' src={profile_image_2 !== '' ? `${GET_PROFILE_PICTURE}${profile_image_2}` : 'https://www.w3schools.com/howto/img_avatar.png'} className=' w-12 h-12 rounded-full' />
                                </div>
                            </div>


                            <h1 className='text-base font-semibold text-gray-800 font-mono text-center my-2'>{name_2}</h1>
                            <div className='w-full mr-10 flex items-center justify-center space-x-2 '>
                                <div className='w-3 h-3 rounded-full bg-yellow-600 flex justify-center items-center  '><div className='w-1 h-1 rounded-full bg-white'></div></div>
                                <h1 className='text-base font-bold text-blue-800 font-mono'>{`${Math.floor(Number(total_2))}`}</h1>
                            </div>

                            <div className='w-full mr-10 flex items-center justify-center space-x-2 my-2'>
                                <div className='w-3 h-3 rounded-full bg-green-600 flex justify-center items-center'><div className='w-1 h-1 rounded-full bg-white'></div></div>
                                <h1 className='text-base font-bold text-blue-800 font-mono'>{`${Math.floor(Number(target_points_2))}`} </h1>
                            </div>
                        </div>

                        <div className=''>

                            <div className='w-full flex justify-center  h-24 items-center'>
                                <div className='w-24 h-24 flex items-center justify-center   border-4 border-lime-600 rounded-full '>
                                    <img alt='Profile' src={profile_image_1 !== '' ? `${GET_PROFILE_PICTURE}${profile_image_1}` : 'https://www.w3schools.com/howto/img_avatar.png'} className='w-20 h-20 rounded-full' />
                                </div>
                            </div>

                            <h1 className='text-base font-semibold text-gray-800 font-mono text-center my-2'>{name_1}</h1>

                            <div className='w-full mr-10 flex items-center justify-center space-x-2'>
                                <div className='w-3 h-3 rounded-full bg-yellow-600 flex justify-center items-center'><div className='w-1 h-1 rounded-full bg-white'></div></div>
                                <h1 className='text-base font-bold text-blue-800 font-mono'>{`${Math.floor(Number(total_1))}`}</h1>
                            </div>

                            <div className='w-full mr-10 flex items-center justify-center space-x-2 my-2 '>
                                <div className='w-3 h-3 rounded-full bg-green-600 flex justify-center items-center'><div className='w-1 h-1 rounded-full bg-white'></div></div>
                                <h1 className='text-base font-bold text-blue-800 font-mono'>{`${Math.floor(Number(target_points_1))}`} </h1>
                            </div>
                        </div>

                        <div className=''>
                            <div className='w-full flex justify-center  h-24 items-center'>
                                <div className=' flex justify-center items-center border-2 border-amber-600 rounded-full w-16 h-16'>
                                    <img alt='Profile' src={profile_image_3 !== '' ? `${GET_PROFILE_PICTURE}${profile_image_3}` : 'https://www.w3schools.com/howto/img_avatar.png'} className='w-12 h-12 rounded-full' />
                                </div>
                            </div>
                            <h1 className='text-base font-semibold text-gray-800 font-mono text-center my-2 '>{name_3}</h1>
                            <div className='w-full mr-10 flex items-center justify-center space-x-2 '>
                                <div className='w-3 h-3 rounded-full bg-yellow-600 flex justify-center items-center'><div className='w-1 h-1 rounded-full bg-white'></div></div>
                                <h1 className='text-base font-bold text-blue-800 font-mono'>{`${Math.floor(Number(total_3))}`} </h1>
                            </div>

                            <div className='w-full mr-10 flex items-center justify-center space-x-2 my-2'>
                                <div className='w-3 h-3 rounded-full bg-green-600 flex justify-center items-center'><div className='w-1 h-1 rounded-full bg-white'></div></div>
                                <h1 className='text-base font-bold text-blue-800 font-mono'>{`${Math.floor(Number(target_points_3))}`} </h1>
                            </div>
                        </div>
                    </div>

                </div></div>) : (<></>)}

        </>
    )
}

export default DistrictwiseList