import React, { useMemo } from 'react'
import bgImage1 from './Images/frameNo1.png';
import bgImage2 from './Images/frameNo2.png';
import bgImage3 from './Images/frameNo3.png';
import { TopThreeProps } from '../../Component/WallOfFame';
import { GET_PROFILE_PICTURE } from 'src/service/apis';
const TopThree = ({ rank, dist_name, name, player_id, profile_image, sc_name, target_points, total }: any) => {

    const renderImage = useMemo(() => {
        if (rank == 1) return bgImage1
        if (rank == 2) return bgImage2
        if (rank == 3) return bgImage3

    }, [rank])



    return (
        <>
            <div style={{ backgroundImage: `url(${renderImage})` }} className="w-full h-[270px] relative overflow-hidden rounded-lg bg-cover bg-no-repeat  text-center ">
                <div className='p-4 flex flex-col items-center '>

                    <h1 className='mr-10 mt-2 text-base font-semibold text-gray-800 font-mono'>Best Award</h1>

                    <div className='flex justify-center mr-10'>
                        <img alt='Profile' src={profile_image !== '' ? `${GET_PROFILE_PICTURE}${profile_image}` : 'https://www.w3schools.com/howto/img_avatar.png'} className='w-14 h-14 rounded-full' />
                    </div>
                    <div className='w-2/5 mr-10 p-2 h-14 text-nowrap '>

                        <h1 className='  text-xs font-medium text-gray-800 text-wrap  '>{name}</h1>
                    </div>
                    <div className='w-full mr-10 flex items-center justify-center space-x-2 '>
                        <div className='w-3 h-3 rounded-full bg-yellow-600 flex justify-center items-center'><div className='w-1 h-1 rounded-full bg-white'></div></div>
                        <h1 className='text-xs font-medium text-gray-800'>{`${Math.floor(Number(total))}`} </h1>
                    </div>
                    <div className='w-full mr-10 flex items-center justify-center space-x-2 '>
                        <div className='w-3 h-3 rounded-full bg-green-600 flex justify-center items-center'><div className='w-1 h-1 rounded-full bg-white'></div></div>
                        <h1 className='text-xs font-medium text-gray-800'>{`${Math.floor(Number(target_points))}`}</h1>
                    </div>
                </div>



            </div>
        </>
    )
}

export default TopThree