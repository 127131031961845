import { convetMMYYYYToMMMMYYYY } from 'src/helpers/index';
import { WallOfFameMainState, useQueryResponseLoading } from '../Context';
import dayjs from 'dayjs';
import React, { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useQueryResponse } from '../Context/Core/QueryResponseProvider';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer';
import { Loader2 } from 'lucide-react';
import TopThree from '../Context/WallOfFameComponent/TopThree';
import List from '../Context/WallOfFameComponent/List';

const WallOfFameDistrict = () => {
    const { state, updateState } = WallOfFameMainState();
    const [ymDates, setYmDates] = useState<string>('');
    const [dist, setDist] = useState<string>('');
    let dates = new Date();
    let dateFormate = dayjs(dates).format('MMMM - YYYY');
    let ym = dayjs(dates).format('MM-YYYY');
    const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state));
    const { search } = useLocation();

    useEffect(() => {
        const params = search && search?.split('?')[1].split("&");

        let searchParams: any = {};

        params && params.forEach((o: any) => {
            let splitParam = o?.split("=");
            searchParams[splitParam[0]] = splitParam[1];
        });

        setYmDates(searchParams?.ym);
        setDist(searchParams?.dist)

        return () => { }
    }, [search])



    useEffect(() => {

        updateState({
            filter: {
                ym: ymDates,
                dist: dist
            }
        })
        return () => { }
    }, [ymDates, dist])


    let selected_Date = convetMMYYYYToMMMMYYYY(ymDates)


    const { response } = useQueryResponse()

    const isLoading = useQueryResponseLoading();

    const apiResponseData: any = useMemo(() => response, [response]);

    const tableData: any = useMemo(() => isLoading ? {} : (apiResponseData ? apiResponseData?.data : {}), [isLoading, apiResponseData])

    const top_three: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.top_three : []), [isLoading, tableData])

    return (
        <div className={`z-10 mt-2  ${!dashBoardState ? "ml-20  duration-300" : "ml-60 duration-300"
            }`}>
            {isLoading ? <div className='flex justify-center items-center '>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
            </div> : (Array.isArray(top_three) && top_three?.length > 0 ? <>
            <div></div>
                <h1 className='text-center my-2 text-3xl font-bold font-mono '>Top 3 Performers of {ymDates !== '' ? selected_Date : dateFormate} </h1>
                <div className='w-full flex space-x-6 justify-center px-4'>


                    {top_three.map((data: any, ind: any) => <TopThree {...data} key={ind} />)}
                </div>


                <List />
            </> : (<></>))}

        </div>
    )
}

export default WallOfFameDistrict