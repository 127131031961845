// import { QueryRequestProvider,QueryResponseProvider } from './Context'

import { QueryRequestProvider } from './Context/Core/QueryRequestProvider'

import React from 'react'
import { QueryResponseProvider } from './Context/Core/QueryResponseProvider'
import StatusById from './Component/StatusById'




type Props = {}

const Main = (props: Props) => {
  return (
    <div className='mt-14 w-full'>
      
        <StatusById/>
    </div>
  )
}

const StatusByIdMain: React.FC<Props> = ({ }) => {
  
    return <>
        <QueryRequestProvider >
            <QueryResponseProvider >
                    <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default StatusByIdMain