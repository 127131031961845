

import React, { useEffect } from 'react'


import { useSelector } from 'react-redux'
import { RootState } from 'src/redux/store'
import { editableReducerState } from 'src/redux/slicer/editableSlicer'
import { toast } from 'react-toastify'
import EdiatblePunchIn from './Component/EdiatblePunchIn'
import { punchReducerState } from 'src/redux/slicer/punchEditableSlicer'

type Props = {}

const Main = (props: Props) => {
    return (
        <div className='mt-[56px] w-full '>
            <EdiatblePunchIn />
        </div>
    )
}

const EditablePunchInMain: React.FC<Props> = ({ }) => {
    const { error, message, } = useSelector((state: RootState) => punchReducerState(state))

    useEffect(() => {
        if (error) {
            toast.error(message)
        }
        return () => { }
    }, [error, message])
    return <>
        <Main />
    </>
}

export default EditablePunchInMain