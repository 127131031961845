
import { ColumnDef, ColumnFiltersState, SortingState, VisibilityState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
import { useState } from 'react';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { DataTablePagination } from './data-table-pagination';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { editableReducerState } from 'src/redux/slicer/editableSlicer';
import { Loader2 } from 'lucide-react';

type Props = {}
interface TablePost {
  dt: string,
  achieved: string
}
interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[],

}
const EditableTable = <TData, TValue>({ columns, data, }: DataTableProps<TData, TValue>) => {
  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])


  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination: {
        pageIndex: 0,
        pageSize: 50
      }
    },
    getCoreRowModel: getCoreRowModel(),
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  });


  // if (!data || !Array.isArray(data) || data.length === 0 || !table || !table.getRowModel().rows) {
  //   return <div></div>;
  // }
  const { isLoading } = useSelector((state: RootState) => editableReducerState(state))
  return (
    <>
      <div className=" w-full relative overflow-x-auto shadow-md mt-4 sm:rounded-lg px-8 ">
        {data ? (
          <ScrollArea className="w-full h-[400px] relative rounded-md ">
            <Table className="w-full text-sm  text-left rtl:text-right text-gray-900 dark:text-gray-400 relative">
              <TableHeader className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-center hover:bg-gray-400 sticky top-0 left-0 right-0">
                {table.getHeaderGroups().map((headerGroup: any) => <TableRow key={`${headerGroup.id}`}>
                  {headerGroup.headers.map((header: any) => {
                    return (
                      <TableHead key={header.id} className="text-left cursor-default text-gray-900 text-xs font-bold">
                        {header.isPlaceholder ? null :
                          flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                      </TableHead>
                    )
                  })}
                </TableRow>)}

              </TableHeader>

              <TableBody>
                {table.getRowModel().rows?.length ? (
                  table.getRowModel().rows.map((row: any) => (
                    <TableRow
                      key={row.id}
                      data-state={row.getIsSelected() && 'selected'}
                      className='odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 hover:bg-gray-200'
                    >
                      {row.getVisibleCells().map((cell: any) => {

                        return (
                          <TableCell key={cell.id}
                            className="text-left overflow-clip">
                            {(
                              flexRender(cell.column.columnDef.cell, cell.getContext())
                            )}
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={columns?.length} className="h-24 text-center text-sm text-gray-400 font-normal">
                      {isLoading ? <div className='flex justify-center items-center '>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
                      </div> : "No results."}
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </ScrollArea>) : (<div></div>)}

      </div>
      {/* <div className='mt-2'>
        <DataTablePagination table={table} />
      </div> */}
    </>

  )

}

export default EditableTable



