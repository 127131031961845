import React, { useEffect, useMemo, useState } from 'react'
import TotalUserChartSummary from '../Context/UserEditableComponent/TotalUserChartSummary'
import UserEditableChartOnetoTen from '../Context/UserEditableComponent/UserEditableChartOnetoTen'
import UserEditableChartTwentyOneEnd from '../Context/UserEditableComponent/UserEditableChartTwentyOneEnd'
import UserEditableChartsElevenTwenty from '../Context/UserEditableComponent/UserEditableChartsElevenTwenty'
import { Button } from 'src/components/ui/button'
import { Input } from 'src/components/ui/input'
import DateMothFilter from '../../TableFilters/FilterComp/DateMothFilter'
import { ProgressBar } from '../../TableFilters/FilterComp/ProgressBar'
import EditableTable from '../Context/UserEditableComponent/EditableTable'
import { columnsUserEditable } from '../Context/UserEditableComponent/columnsUserEditable'
import { endOfDay, format, startOfDay } from 'date-fns'
import { useDispatch, useSelector } from 'react-redux'
import {
  calculatePoints,
  editableReducerState,
  resetDailyData,
  updateAConstFormula,
  updateBenmark,
  updateMxPerPoints,
  updateSetCollectionPerValue,
  updateTargetValue
} from 'src/redux/slicer/editableSlicer'
import { RootState } from 'src/redux/store'
import UserActivityDropDown from '../../TableFilters/FilterComp/UserActivityDropDown'
import { columnsForOtherActivity } from '../Context/UserEditableComponent/columnsForOtherActivity'
import { Loader2 } from 'lucide-react'
import { editableDropDownModel } from '../../TableFilters/FilterModule/editableDropDownModel'
import DateBoxesUser from '../Context/UserEditableComponent/DateBoxesUser'
import OtherActivityChart from '../Context/UserEditableComponent/OtherActivityUserCharts'
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer'
import MonthYearFilter from '../../TableFilters/FilterComp/MonthYearFilter'
// import Loader from '../../Loader/Loader'

export function getDaysInMonth(year: number, month: number) {
  return new Date(year, month, 0).getDate();
}


interface PostData {
  dt: string,
  achieved: string,
}

export interface DataPost {
  bym: string,
  jsonInput: {
    A: number,
    bmark: number,
    per_million: number,
    activity: string,
    target: number,
    daily_data: PostData[]
  }
}

const data: any = []

const EditableComponent = () => {

  const { data, postData,
    dataChanged, isLoading,
    benmark, aConstFormula,
    mxPerPoints, targetValue,
    dateFilter, activityDropDown } = useSelector((state: RootState) => editableReducerState(state))

  const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state))
  const apiResponse: any = useMemo(() => data, [data]);
  const totalData: any = useMemo(
    () => (isLoading ? [] : apiResponse ? apiResponse?.daily_data : []),
    [apiResponse, isLoading]
  );

  const targets: number = useMemo(() => isLoading ? 0 : (apiResponse ? (typeof apiResponse?.target_points === 'number' ? apiResponse?.target_points.toFixed(2) : 0) : 0), [isLoading, apiResponse])
  const total_points: number = useMemo(() => isLoading ? 0 : (apiResponse ? apiResponse?.total_points?.toFixed(2) : 0), [isLoading, apiResponse])
  const tartgetVal: number = useMemo(() => isLoading ? 0 : (apiResponse ? (typeof apiResponse?.target === 'number' ? apiResponse?.target.toFixed(2) : Number(apiResponse?.target)?.toFixed(2)) : 0), [isLoading, apiResponse])
  const valMax: number = useMemo(() => isLoading ? 0 : (apiResponse ? (typeof apiResponse?.target_points === 'number' ? apiResponse?.target_points.toFixed(2) : 0) : 0), [isLoading, apiResponse])
  const collection: number = useMemo(() => isLoading ? 0 : (apiResponse ? (typeof apiResponse?.total_collection === 'number' ? apiResponse?.total_collection.toFixed(2) : 0) : 0), [isLoading, apiResponse])
  console.log(tartgetVal)
  const xAxisValues: any = useMemo(() => totalData ? totalData?.map((cur: any) => ({

    "xAxisValue": cur?.x * 100,
  })) : [], [totalData])

  const achivedPercentage = useMemo(() => xAxisValues && Array.isArray(xAxisValues) ? xAxisValues[xAxisValues?.length - 1]?.xAxisValue.toFixed(2) : 0, [xAxisValues])

  const currentMonth = format(new Date(), "MM")
  const fullMonth = format(new Date(), "MMM")
  const currentYear = format(new Date(), "yyyy")
  const startDate = format(startOfDay(new Date()), "dd")
  const endDate = format(endOfDay(new Date()), "dd")

  const [monthFilter, setMonthFilter] = useState<string>()
  const [activity, setActivity] = useState<string>();

  const [progressBarVal, setProgressBarVal] = useState<{ zPerce: number, hPerce: number }>({
    zPerce: 2000,
    hPerce: 4000,
  })




  const dispatch = useDispatch();

  const mxHandelCahnge = (val: number) => {
    dispatch(updateMxPerPoints(val))
  }

  const aConstHandelCahnge = (val: number) => {
    dispatch(updateAConstFormula(val))
  }

  const benchmarkHandelCahnge = (val: number) => {
    dispatch(updateBenmark(val))
  }
  // setProgress Bar value 
  useEffect(() => {
    if (activityDropDown === 'collection_efficeincy') {
      setProgressBarVal({
        zPerce: 2000,
        hPerce: 4000,
      })
    } else if (activityDropDown === 'collection_coverage') {
      setProgressBarVal({
        zPerce: 0,
        hPerce: 20,
      })
    } else if (activityDropDown === 'desconnection') {
      setProgressBarVal({
        zPerce: 40,
        hPerce: 60,
      })
    } else if (activityDropDown === 'reconnection') {
      setProgressBarVal({
        zPerce: 180,
        hPerce: 220,
      })
    } else {
      setProgressBarVal({
        zPerce: 2000,
        hPerce: 4000,
      })
    }

  }, [activityDropDown])

  let preparePostData = useMemo(() => {
    return {
      bym: dateFilter,
      jsonInput: {
        A: aConstFormula || 0,
        bmark: mxPerPoints || 0,
        per_million: benmark || 0,
        activity: activityDropDown,
        target: targetValue || 0,
        daily_data: postData.jsonInput.daily_data
      }
    }
  }, [dateFilter, activityDropDown, benmark, mxPerPoints, aConstFormula, targetValue, postData])


  useEffect(() => {
    let newpostData = {};
    if (data && Array.isArray(data) && data.length > 0) {
      newpostData = postData
    }
    else {
      newpostData = preparePostData
    }
    dispatch(calculatePoints(newpostData))
  }, [dateFilter, activityDropDown, benmark, mxPerPoints, aConstFormula, targetValue])



  const date = new Date(dateFilter);

  const daysInCurrentMonth = getDaysInMonth(
    date.getFullYear(),
    date.getMonth() + 1,
  );

  return (
    <>


      <div className={` ${!dashBoardState ? 'ml-20  duration-300 z-0' : 'ml-60 duration-300'}`} >
        <h2 className='text-gray-700 font-medium text-lg mt-2 ml-5'>Sandbox</h2>
        {activityDropDown === 'collection_efficeincy' ? (<div>
          <div className='w-full grid grid-cols-3 text-center mt-2'>

            <div className='w-full border border-gray-200'>
              <UserEditableChartOnetoTen />
            </div>
            <div className='border  border-gray-300 w-full'>
              <UserEditableChartsElevenTwenty />
            </div>
            <div className='border  border-gray-300 w-full'>
              <UserEditableChartTwentyOneEnd />
            </div>

          </div>
          <div className=''>
            <TotalUserChartSummary color='bg-purple-500' word='Total' />
          </div>
        </div>) : (<div className='space-y-4 flex items-center justify-center flex-col'>
          <div className='mt-4 px-10'>
            <DateBoxesUser data={totalData || []} arrInd={daysInCurrentMonth} ind={0} color='bg-green-600' />
          </div>

          <div className='flex flex-col justify-center items-center'>

            <OtherActivityChart color='rgb(22 163 74)' />
            <div className=" text-sm font-semibold text-center text-gray-700 my-2 space-x-6">
              <span>
                <span className="text-gray-800 font-bold dark:text-white"> Achived Value:</span>
                {"  "}₦{Intl.NumberFormat('en-US').format(collection)}
              </span>
              <span>
                <span className="text-gray-800 font-bold dark:text-white">Target Value:</span>
                {"  "}
                {Intl.NumberFormat('en-US').format(tartgetVal)}
              </span>

              <span>
                <span className="text-gray-800 font-bold dark:text-white">Achived Percentage:</span>
                {"  "}
                {achivedPercentage} %
              </span>
            </div>
            <h1 className=' text-sm font-bold text-center text-gray-800 my-2'>Achived Points {' '}
              {Intl.NumberFormat('en-US').format(total_points) || 0}{" "}<span className='font-semibold'>Out Of</span>  {Intl.NumberFormat('en-US').format(targets)}</h1>
          </div>

        </div>)}

        <div className='flex px-4 items-center space-x-3 justify-between mt-2'>
          <p className='text-sm font-medium text-gray-800  px-3 w-1/3 h-9 rounded-md flex justify-center items-center'>
            Maximum Performance Points Can Be Earned : {targets} </p>
          <div className='w-1/5 -mt-2'>
            <h3 className=' text-center text-sm text-gray-800 font-medium'>Target Value</h3>
            <Input placeholder='Target collection for a given month'
              className=' placeholder:text-sm placeholder:font-medium placeholder:text-gray-400'
              type='number'
              value={targetValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => dispatch(updateTargetValue(e.target.value))} />
          </div>
          <div className='w-1/5 -mt-2'>
            <h3 className='-mb-2 text-center text-sm text-gray-800 font-medium'>Select Activity</h3>
            <UserActivityDropDown setActivity={setActivity}
              dropdownModel={editableDropDownModel}
              activity={activityDropDown} />
          </div>
          <div className='w-1/6'>
            <h3 className='-mb-2 text-center text-sm text-gray-800 font-medium'>Select Month/Year</h3>
            <MonthYearFilter monthYear={dateFilter} />
          </div>



        </div>
        <div className='flex px-12 items-center space-x-6  my-8 '>

          <ProgressBar label='A (Constant In Formula)'
            zPerce={140} hPerce={180}
            val={aConstFormula}
            setVal={(val) => aConstHandelCahnge(val)}
            defaultValue={[50]} />

          <ProgressBar label='Max Performance Points Per Benchmark'
            zPerce={progressBarVal.zPerce}
            hPerce={progressBarVal.hPerce}
            val={mxPerPoints}
            setVal={(value) => mxHandelCahnge(value)}
            defaultValue={[50]} />
          {activityDropDown !== 'collection_efficeincy' ? (<></>) : (<ProgressBar label='Benchmark'
            zPerce={1000000} hPerce={10000000}
            val={benmark}
            setVal={(value) => benchmarkHandelCahnge(value)} defaultValue={[0]}
            activity={activity} />)}


        </div>
        <div className='flex  space-x-32'>
          <Input className='w-1/5 ml-36 placeholder:text-xs placeholder:text-gray-400 placeholder:font-medium'
            type="text"
            placeholder='Common Collection'
            //  onChange={(e=>updateSetCollectionPerValue(e.target.value)}/>
            onChange={e => {
              dispatch(updateSetCollectionPerValue(e.target.value))

            }} />
          <div className='flex justify-center space-x-2 '>

            <Button
              variant={"secondary"}
              onClick={() => dispatch(resetDailyData())}
            >Reset</Button>
            <Button
              disabled={isLoading}
              onClick={() => dispatch(calculatePoints(preparePostData))}
            >
              {isLoading && isLoading && (
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              )}
              Go
            </Button>
          </div>
        </div>
        <EditableTable columns={activityDropDown === "collection_efficeincy" ? columnsUserEditable : columnsForOtherActivity} data={totalData || []} />

      </div>

    </>
  )

}

export default EditableComponent

