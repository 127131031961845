import axios, { AxiosResponse } from "axios";
import { GET_BUCKET_WISE_POINT } from "src/service/apis";

const getBucketPoint = (query: string) => {
  return axios
    .get(`${GET_BUCKET_WISE_POINT}?${query}`)
    .then((d: AxiosResponse<any>) => d.data);
};

export { getBucketPoint };

// import axios, { AxiosError } from "axios";
// import { toast } from "react-toastify";
// import { GET_BUCKET_WISE_POINT } from "src/service/apis";

// const getBucketPoint = async (query: string) => {
//   try {
//     const response = await axios.get(`${GET_BUCKET_WISE_POINT}?${query}`);
//     // console.log(response.data,"responses111111111111")
//     return await response.data;
//   } catch (err) {
//     const error = err as Error | AxiosError;
//     if (axios.isAxiosError(error)) {
//       toast.error(error.response?.data?.message);
//     } else {
//       toast.error(error.message);
//     }
//   }
// };

// export { getBucketPoint };
