import React from 'react'

const EffExp = () => {
    return (
        <div className='w-full bg-gray-50 text-sm text-gray-600 font-medium space-x-4 p-2 rounded-md my-2'>
            <span>   <span className='font-bold text-gray-900'> CC:</span>   <span>Collection Coverage</span></span>
            <span>   <span className='font-bold text-gray-900'>CE: </span> <span>Collection Efficiency</span></span>
            <span>   <span className='font-bold text-gray-900'>DC:</span>  <span>Disconnected</span></span>
            <span>   <span className='font-bold text-gray-900'>RC:</span>  <span>Reconnected</span></span>
            <span>   <span className='font-bold text-gray-900'>IOT :</span> <span>Is On Time</span></span>
            <span>   <span className='font-bold text-gray-900'>CA : </span><span>Collection Amount</span></span>
            <span>   <span className='font-bold text-gray-900'>CP :</span> <span>Coverage Population</span></span>
            <span>   <span className='font-bold text-gray-900'>EP :</span> <span>Event Performed</span></span>
            <span>   <span className='font-bold text-gray-900'>PA :</span> <span>Puntual Attendance</span></span>
            <span>   <span className='font-bold text-gray-900'>TR :</span> <span>Total Reward</span></span>
        </div>
    )
}

export default EffExp