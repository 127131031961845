import { Card, CardContent, CardHeader, CardTitle } from 'src/components/ui/card'
import React, { useMemo } from 'react'
import { Equal, Plus } from 'lucide-react'
import Niara from '../UserEditableComponent/Niara.svg';
import { cn } from 'src/helpers';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { editableReducerState } from 'src/redux/slicer/editableSlicer';

interface ChartsSummaryProps {
    datas?: {
        dt: string,
        x: number,
        actual_points: number,
        total_bonus: number
        today_collection: string,
        cumulative_collection: number,
        total_points: number
    }[];
    target?: number;
    total?: number

    color?: string;
    word?: string;
}
const TotalUserChartSummary: React.FC<ChartsSummaryProps> = ({ word }) => {

    const { data, isLoading, } = useSelector((state: RootState) => editableReducerState(state))

    const apiResponse: any = useMemo(() => data, [data]);
    const totalData: any = useMemo(
        () => (isLoading ? [] : apiResponse ? apiResponse?.daily_data : []),
        [apiResponse, isLoading]
    );

    const performancPoint: number = useMemo(() => isLoading ? undefined : (totalData ? totalData[totalData.length - 1]?.actual_points?.toFixed(2) : 0), [isLoading, totalData])
    const bonusPoint_1: number = useMemo(() => isLoading ? undefined : (totalData ? totalData[9]?.total_bonus?.toFixed(2) : 0), [isLoading, totalData])
    const bonusPoint_2: number = useMemo(() => isLoading ? undefined : (totalData ? totalData[19]?.total_bonus?.toFixed(2) : 0), [isLoading, totalData])
    const bonusPoint_3: number = useMemo(() => isLoading ? undefined : (totalData ? totalData[totalData.length - 1]?.total_bonus?.toFixed(2) : 0), [isLoading, totalData])

    const totals: number = useMemo(() => isLoading ? 0 : (apiResponse ? (apiResponse?.total_points !== undefined && !isNaN(apiResponse?.total_points) ? apiResponse?.total_points.toFixed(2) : 0) : 0), [isLoading, apiResponse])
    const targets: number = useMemo(() => isLoading ? 0 : (apiResponse ? (typeof apiResponse?.target_points === 'number' ? apiResponse?.target_points.toFixed(2) : 0) : 0), [isLoading, apiResponse])

    // const formattedTarget = typeof target === 'number' ? target?.toFixed(2) : 'N/A';
    return (
        <Card className={cn(`w-full rounded-none  text-gray-500`)}>


            <CardContent className='flex items-center justify-center space-x-2 mt-2'>
                <div className='border-[2px] border-gray-400 p-3  rounded-lg text-gray-500'>
                    <h3 className='text-xs font-bold mt-1 text-gray-800'>{word} Performance Point</h3>
                    <h3 className='  text-base font-semibold text-center text-gray-600' >
                        {/* {datas[datas.length - 1]?.actual_points?.toFixed(2)} */}
                        {Intl.NumberFormat('en-US').format(performancPoint)}
                    </h3>

                </div>
                <div > <Plus color='#000000' size={16} /></div>
                <div className='border-2 border-green-600 p-3  rounded-lg text-gray-500'>
                    <h3 className='text-xs font-bold mt-1 text-gray-800'> Bonus Point 1</h3>
                    <h3 className=' text-base font-semibold text-center text-gray-600'>
                        {/* {datas[9]?.total_bonus?.toFixed(2)} */}
                        {Intl.NumberFormat('en-US').format(bonusPoint_1)}
                    </h3>

                </div>
                <div > <Plus color='#000000' size={16} /></div>
                <div className='border-2 border-sky-600 p-3  rounded-lg text-gray-500'>
                    <h3 className='text-xs font-bold mt-1 text-gray-800'> Bonus Point 2</h3>
                    <h3 className='text-base font-semibold text-center text-gray-600'>
                        {/* {datas[19]?.total_bonus?.toFixed(2)} */}
                        {Intl.NumberFormat('en-US').format(bonusPoint_2)}
                    </h3>


                </div>
                <div > <Plus color='#000000' size={16} /></div>
                <div className='border-2 border-amber-600 p-3 rounded-lg amber-600 text-gray-500'>
                    <h3 className='text-xs font-bold mt-1 text-gray-800'> Bonus Point 3</h3>
                    <h3 className=' text-base font-semibold text-center text-gray-600'>
                        {/* {datas[datas.length - 1]?.total_bonus?.toFixed(2)} */}
                        {Intl.NumberFormat('en-US').format(bonusPoint_3)}
                    </h3>


                </div>
                <div> <Equal color='#000000' size={16} /></div>
                <div className='border-[2px] border-gray-700 p-3  rounded-lg text-gray-600'>
                    <h3 className='text-xs font-bold mt-1 text-gray-800 text-center'>Achived Points </h3>
                    <h3 className=' text-base font-semibold text-center'>
                        {/* {total !== undefined && !isNaN(total) ? total?.toFixed(2) : 'N/A'} Out of {formattedTarget} */}
                        {Intl.NumberFormat('en-US').format(totals)} <span className='text-xs text-gray-800 font-medium'>Out Of</span>  {Intl.NumberFormat('en-US').format(targets)}
                    </h3>

                </div>
                <div>
                </div>

            </CardContent>



        </Card>
    )
}

export default TotalUserChartSummary
