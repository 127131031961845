import React from 'react'

interface RankProps {
    global_rank: number,
    group_rank: number
}

const Rank = ({ global_rank, group_rank }: RankProps) => {
    return (
        <div className='text-left'>
            <h1><span className='text-xs text-gray-500 font-medium'>EEDC:{" "}</span><span className='text-xs text-gray-900 font-bold'>{global_rank}</span></h1>
            <h1><span className='text-xs text-gray-500 font-medium'>SC:{" "}</span><span className='text-xs text-gray-900 font-bold'>{group_rank}</span></h1>
        </div>
    )
}

export default Rank
