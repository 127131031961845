import axios, { AxiosResponse } from "axios";
import { URL_WALL_OF_FAME } from "src/service/apis";

const getWallOfFame = (query: string) => {
  return axios
    .get(`${URL_WALL_OF_FAME}?${query}`)
    .then((d: AxiosResponse<any>) => d.data);
};

export { getWallOfFame };
