import React, { useEffect, useState } from 'react'
import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';

import { Button } from 'src/components/ui/button';
import { cn } from 'src/helpers';

interface LineChartsProps {
  details: any,
  summary?: any,
  color: string,
  textColor?: string
  targets?: number
  y_maxValue?: any
}

const UserCharts = ({ details, color, textColor, y_maxValue }: LineChartsProps) => {
  const [maxVal, setMaxVal] = useState<number>(0)
  const [opacity, setOpacity] = useState<any>({
    performancePoints: 1,
    bonusPoints: 1,
    totalPoints: 1,
  });





  useEffect(() => {
    if (
      y_maxValue &&
      y_maxValue.length > 0 &&
      Array.isArray(y_maxValue) &&
      typeof y_maxValue[y_maxValue.length - 1].yAxisxValue === 'number'
    ) {
      setMaxVal(Math.floor(y_maxValue[y_maxValue.length - 1].yAxisxValue));
    }
  }, [y_maxValue]);





  const xAxisValues = details?.map((cur: any) => ({

    "xAxisValue": (cur?.x * 100)?.toFixed(2),
    "Performance Points": cur?.actual_points?.toFixed(2),
    "Bonus Points": cur?.total_bonus?.toFixed(2),
    "Achived Points": cur?.total_points?.toFixed(2),
    "Date": cur?.dt
  }));







  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip text-left">
          {/* <p>{`Percentage: ${label * 100}`}</p> */}

          {payload.map((point: any) => (
            <p key={point.name} style={{ color: point.color }} className='text-sm font-medium '>
              {`${point.name}: ${point.value}`}
            </p>
          ))}
          <p className='text-sm font-medium ' style={{ color: `${color}` }}>{payload[0].payload.Date === undefined ? "" : (`Date: ${payload[0].payload.Date}`)}</p>
          <p className='text-sm font-medium ' style={{ color: `${color}` }}>{payload[0].payload.xAxisValue === undefined ? "" : (`Achived Percentage: ${payload[0].payload.xAxisValue}%`)}</p>
          {/*  */}
        </div>
      );
    }

    return null;
  };



  return (
    <>
      <LineChart
        width={350}
        height={280}
        margin={{
          top: 5,
          right: 10,
          left: 20,
          bottom: 5,
        }}
        data={y_maxValue}
      >
        <XAxis
          dataKey='xAxisValue'
          type='number'
          style={{ "fontSize": "12px" }}

          domain={[0, 100]} tickCount={15}
        />

        <YAxis
          style={{ "fontSize": "12px" }}
          domain={[0, maxVal]}
          dataKey='yAxisValue'
          tickCount={15} />

        <CartesianGrid stroke="#ccc" strokeDasharray="3 3" />

        <Tooltip content={<CustomTooltip />} />


        <Line
          type="monotone"
          dataKey="yAxisxValue"
          name="Base Line"
          stroke="rgb(229 231 235)"
          legendType='none'
          data={y_maxValue}
        />

        <Line
          type="monotone"
          data={xAxisValues}
          dataKey='Performance Points'
          stroke='#8b0a50'
          strokeWidth={1}
          opacity={opacity.performancePoints} />

        <Line
          type="monotone"
          data={xAxisValues}
          dataKey='Bonus Points'
          stroke={color}
          strokeWidth={2}
          opacity={opacity.bonusPoints} />

        <Line
          type="monotone"
          data={xAxisValues}
          dataKey='Achived Points'
          stroke='#047857'
          strokeWidth={1}
          opacity={opacity.totalPoints} />

      </LineChart>
      <div className='flex space-x-6 px-4 justify-center items-center my-1'>
        <span onClick={() => {
          setOpacity((prevOpacity: any) => ({
            ...prevOpacity,
            performancePoints: prevOpacity.performancePoints === 1 ? 0 : 1,
          }));
        }}
          className={cn('cursor-pointer text-xs hover:underline text-pink-600 font-bold', { 'text-gray-400': opacity.performancePoints === 0 })}>Peformance Points</span>

        <span onClick={() => {
          setOpacity((prevOpacity: any) => ({
            ...prevOpacity,
            bonusPoints: prevOpacity.bonusPoints === 1 ? 0 : 1,
          }));
        }}
          className={cn(`cursor-pointer text-xs hover:underline ${textColor} font-bold`, { 'text-gray-400': opacity.bonusPoints === 0 })}>Bonus Points</span>

        <span onClick={() => {
          setOpacity((prevOpacity: any) => ({
            ...prevOpacity,
            totalPoints: prevOpacity.totalPoints === 1 ? 0 : 1,
          }));
        }}
          className={cn('cursor-pointer text-xs hover:underline text-emerald-700 font-bold', { 'text-gray-400': opacity.totalPoints === 0 })}>Achived Points </span>
      </div>

    </>
  )
}

export default UserCharts



