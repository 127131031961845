export function convertQueryStringToObject(queryString: string) {
  // Split the query string into key-value pairs
  var pairs = queryString.split("&");

  // Create an object to store the key-value pairs
  var result: Record<string, string | number> = {};

  // Loop through each pair and add it to the object
  for (var i = 0; i < pairs.length; i++) {
    var pair = pairs[i].split("=");
    var key = pair[0];
    var value = decodeURIComponent(pair[1]);

    // Convert value to number if it's numeric
    result[key] = !isNaN(Number(value)) ? Number(value) : value;
  }

  return result;
}
