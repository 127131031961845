

import JsonDate from "./JsonDate"
import { ColumnDef } from "@tanstack/react-table"
import { Check, X } from "lucide-react"
import { ReactNode } from "react"


export type ReportTable = {
  user_id?: number,
  dt: string,
  actual_collection?: number,
  cumulative_collection: string,
  actual_points: number,
  bonus_points?: number,
  total_points: number,
  final_points?: number,
  dump_data_achieved: string | null,
  cumulative_activity_achieved: string | null,
  match: number | null
}



export const collectionEfficiencyColumn: ColumnDef<ReportTable>[] = [

  {
    accessorKey: "dt",
    header: "Date",
    cell: ({ row: { original } }) => {
      return <JsonDate {...original} />
    }
  },

  {
    accessorKey: "dump_data_achieved",
    header: "Data Received From TopUPP",
    cell: ({ row }) => {
      const val: any = row.getValue("dump_data_achieved");
      const parsedVal = parseFloat(val);
      if (isNaN(parsedVal)) {
        return <div>-</div>;
      } else {
        const values: any = row.getValue("cumulative_activity_achieved");
        const valParsed = parseFloat(values);
        if (valParsed === parsedVal) {
          return <div className="text-green-700 font-semibold">{parsedVal}</div>
        } else {
          return parsedVal
        }

      }
    },
  },
  {
    accessorKey: "cumulative_activity_achieved",
    header: "Data In ERRS",
    cell: ({ row }) => {
      const val: any = row.getValue("cumulative_activity_achieved");
      const parsedVal = parseFloat(val);
      if (isNaN(parsedVal)) {
        return <div>-</div>;
      } else {
        const values: any = row.getValue("dump_data_achieved");
        const valParsed = parseFloat(values);
        if (valParsed === parsedVal) {
          return <div className="text-green-700 font-semibold">{parsedVal}</div>
        } else {
          return parsedVal
        }
      }
    },
  },
  {
    accessorKey: "cumulative_collection",
    header: "Achived Value",
  },
  {
    accessorKey: "actual_points",
    header: "Performance  Points",
    cell: ({ row }) => {
      let perVal = parseFloat(row.getValue('actual_points')).toFixed(2);
      return perVal
    }
  },
  {
    accessorKey: "bonus_points",
    header: "Bonus Points",
    cell: ({ row }) => {
      let perVal = parseFloat(row.getValue('bonus_points')).toFixed(2);
      return perVal
    }
  },
  {
    accessorKey: "final_points",
    header: "Achived Points ",
    cell: ({ row }) => {
      let perVal = parseFloat(row.getValue('final_points')).toFixed(2);
      return perVal
    }
  },
  // {
  //   accessorKey: "match",
  //   header: "Match",
  //   cell: ({ row }) => {
  //     const match = parseFloat(row.getValue("match"))

  //     if (match === 1) {
  //       return <Check className=" text-green-600 m-auto"/>
  //       // return  <Badge
  //       // className=" bg-transparent border-primary text-primary hover:bg-transparent hover:text-primary ">Success</Badge>
  //     }else if(isNaN(match)){
  //       return <div>-</div>
  //     } else {
  //       return <X className=" text-red-600 m-auto"/>
  //       // return <Badge className=" bg-transparent border-destructive text-destructive hover:bg-transparent hover:text-destructive"  >Fail</Badge>
  //     }
  //   },
  // },

]