import React, { useEffect } from 'react';
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue
} from "../../../components/ui/select";
import { dropdownModel } from '../FilterModule/dropdownModel';
import { useLocation } from 'react-router-dom';

interface ActivityProps {
  setActivity: (value: string) => void;
  activity:string;
  loading?:boolean;
}

const CollectionDropDown = ({ setActivity,activity,loading }: ActivityProps) => {

  const { search } = useLocation();
  const params = search && search?.split('?')[1].split("&");
  // console.log({ params });
  let searchParams: any = {};

  params && params.forEach((o: any) => {
    let splitParam = o?.split("=");
    searchParams[splitParam[0]] = splitParam[1];
    
  });

 useEffect(() => {
//   if(searchParams.activity){
//     setActivity(searchParams.activity)
//   }else{
// setActivity(dropdownModel[0].value)
//   }
  setActivity(searchParams.activity)
 
   return () => {}
 }, [])
 


  return (
    <Select onValueChange={(value: any) => setActivity(value)} 
    defaultValue={searchParams.activity?searchParams.activity:'collection_coverage'}
    disabled={loading}>
      <SelectTrigger className="w-full mt-2 font-medium">
        <SelectValue placeholder="Select a category" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {dropdownModel.map((cur, index) => (
            <SelectItem value={cur.value} key={`${cur.value}-${index}`}>
              {cur.name}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
};

export default CollectionDropDown;
