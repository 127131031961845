import { Loader2 } from 'lucide-react'
import React from 'react'
import { useSelector } from 'react-redux'
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer'
import { RootState } from 'src/redux/store'
interface SummaryBoxesProps {
    titles?: string
    summaryTotal?: number,
    customer?: string
    loading?: boolean
}
const SummaryBoxes = ({ titles, summaryTotal, customer, loading }: SummaryBoxesProps) => {

    return (
        <div className='w-full border-dotted border-2 border-gray-200 text-left text-sm space-y-1 py-2 rounded-md '>
            {loading && <div className='flex justify-center items-center '>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
            </div>}
            <h1 className='text-sky-600 font-semibold text-xs  ml-4'>{titles}</h1>
            {summaryTotal && <h2 className='text-gray-800 text-semibold ml-4 text-base'>
                <span className='text-gray-500 font-medium'>₦</span>
                <span className='text-gray-700 font-bold text-base'>{summaryTotal}</span></h2>
            }
            <h1 className='text-gray-800 text-semibold ml-4 text-sm'>{customer}</h1>
        </div>
    )
}

export default SummaryBoxes