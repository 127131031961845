import { DataPost } from "src/modules/EditableComponent/Component/EditableComponent";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { IPointscollection } from "../../modules/EditableComponent/Context/Core/modules";
import { RootState } from "../store";
import { endOfDay, format, startOfDay } from "date-fns";
import { date } from "yup";
import { pointExcelCalculation } from "src/service/services";
import axios, { AxiosError } from "axios";

export interface IDailyData {
    dt: string | undefined | null,
    achieved: string | undefined | null
}

export interface IPostData {
    bym: string,
    jsonInput: {
        A: number,
        bmark: number,
        per_million?: number,
        activity: string,
        target: number | undefined,
        daily_data: IDailyData[]
    }
}


const fullMonth = format(new Date(), "MMM")
const currentYear = format(new Date(), "yyyy")

const INIT_POSTDATA: IPostData = {
    bym: fullMonth + "-" + currentYear,
    jsonInput: {
        A: 0,
        bmark: 0,
        per_million: 0,
        activity: "collection_coverage",
        target: 0,
        daily_data: []
    }
}

interface EditableState {
    data: any;
    postData: IPostData;
    isLoading: boolean,
    error: boolean,
    message: string | undefined,
    dataChanged: boolean
    dateFilter: string,
    activityDropDown: string,
    benmark: number
    mxPerPoints: number,
    aConstFormula: number,
    targetValue: number,
    setCollectionPerValue: string | undefined
}

const initialState: EditableState = {
    data: [],
    postData: INIT_POSTDATA,
    isLoading: false,
    error: false,
    message: undefined,
    dataChanged: false,
    dateFilter: `${fullMonth}-${currentYear}`,
    activityDropDown: 'collection_coverage',
    benmark: 0,
    mxPerPoints: 1000000,
    aConstFormula: 160,
    targetValue: 37490873,
    setCollectionPerValue: undefined
};

export const calculatePoints: any = createAsyncThunk(
    'editable/calculatePoints',
    async (posData: DataPost, thunkApi: any) => {

        try {
            // 
            const data = await pointExcelCalculation(posData)
            // console.log({ data });
            if (data) {
                thunkApi.dispatch(resetChangedState());
                return data;
            }
            return data;
        } catch (err) {

            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.msg
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.msg
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
);



const editableSlice = createSlice({
    name: 'editableSlice',
    initialState,
    reducers: {

        updateData(state, action: PayloadAction<any>) {
            state.data = action.payload;
        },
        updatePostData(state, action: PayloadAction<any>) {
            state.postData = action.payload;
        },
        updateDailyData(state, action: PayloadAction<{
            dt: string | undefined,
            achieved: string | undefined
        }>) {
            const { dt, achieved } = action.payload;
            if (state.postData.jsonInput.daily_data && state?.postData?.jsonInput?.daily_data?.length > 0) {
                let items = state.postData.jsonInput.daily_data;
                let itemIndex = items.findIndex((d: IDailyData) => d.dt == dt)
                items[itemIndex] = {
                    dt: dt, achieved: achieved
                }
                state.postData.jsonInput.daily_data = items;
                state.dataChanged = true

            }

        },
        updateDailyPostDataFromUserAct(state, action: PayloadAction<any>) {
            state.postData.jsonInput.daily_data = action.payload;
            state.data.daily_data = action.payload;
        },
        resetDailyData(state) {
            state.postData = INIT_POSTDATA;
            state.data = []
        },
        resetChangedState(state) {
            state.dataChanged = false;
        },
        updateDatefilter(state, action: PayloadAction<string>) {
            state.dateFilter = action.payload;

        },
        updateActivityDropDown(state, action: PayloadAction<string>) {
            state.activityDropDown = action.payload;
            // console.log(state?.activityDropDown)
        },
        updateBenmark(state, action: PayloadAction<any>) {
            state.benmark = action.payload;
        },
        updateAConstFormula(state, action: PayloadAction<any>) {
            state.aConstFormula = action.payload;
        },
        updateMxPerPoints(state, action: PayloadAction<any>) {
            state.mxPerPoints = action.payload;
        },
        updateTargetValue(state, action: PayloadAction<any>) {
            state.targetValue = action.payload;
        },

        updateSetCollectionPerValue(state, action: PayloadAction<any>) {
            state.setCollectionPerValue = action.payload
        },
        updateTableDataAndPostData(state, action: PayloadAction<IPostData>) {
            const { jsonInput: { daily_data } } = action.payload;
            state.data = daily_data;
            state.postData = action.payload
        },

        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(calculatePoints.fulfilled, (state, action) => {


            if (action.payload && action.payload.daily_data) {
                state.data = action.payload;
                state.postData.jsonInput.daily_data = action.payload.daily_data.map((d: any) => ({
                    dt: d?.dt,
                    achieved: d?.today_collection
                }));
            }

            state.isLoading = false;
        });
        builder.addCase(calculatePoints.pending, (state) => {
            state.isLoading = true;
        });
        builder.addCase(calculatePoints.rejected, (state, action) => {
            state.isLoading = false;
            state.message = action.payload || 'Error occurred';
        });
    },
});

export const { updateData, updatePostData,
    updateDailyData, resetDailyData,
    resetChangedState, updateAConstFormula,
    updateActivityDropDown, updateBenmark,
    updateDatefilter, updateMxPerPoints,
    updateTargetValue, updateSetCollectionPerValue,
    updateDailyPostDataFromUserAct, updateTableDataAndPostData, setError, resetError } = editableSlice.actions;

export const editableReducerState = (state: RootState) => state.editable;

export default editableSlice.reducer;

