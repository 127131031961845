import React from 'react'
import { QueryRequestProvider, QueryResponseProvider, } from './Context'
import CollectionByUserActivity from '../CollectionByUserActivity/Component/CollectionByUserActivity'


type Props = {}

const Main = (props: Props) => {
  return (

    <div className='mt-14 w-full'>
      <CollectionByUserActivity />
    </div>
  )
}

const CollectionByUserActivityMain: React.FC<Props> = ({ }) => {
  return <>
    <QueryRequestProvider >
      <QueryResponseProvider >
        <Main />
      </QueryResponseProvider>
    </QueryRequestProvider>
  </>
}

export default CollectionByUserActivityMain