import StatusByIdTable from "../Context/StatusByIdComp/StatusByIdTable";
import { useQueryResponse } from "../Context/Core/QueryResponseProvider";
import React, { useEffect, useMemo, useState } from "react";
import { byIdColumn } from "../Context/StatusByIdComp/byIdColumn";
import { useQueryResponseLoading } from "../Context/Core/QueryResponseProvider";
import Loader from "src/modules/Loader/Loader";
import DateMothFilter from "src/modules/TableFilters/FilterComp/DateMothFilter";
import CollectionDropDown from "src/modules/TableFilters/FilterComp/CollectionDropDown";
import UserActivityDropDown from "src/modules/TableFilters/FilterComp/UserActivityDropDown";
import { TargetOverflowByIdMainState } from "../Context";
import { dropdownModel } from "../../TableFilters/FilterModule/dropdownModel";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { dashboardReducerState } from "src/redux/slicer/dashboardSlicer";

interface Data {
    name: string;
    profile_image: string;
    user_id: number;
    activity: string;
    dt: string;
    actual_collection: string;
    target: string;
    is_overflow: number;
    job_id: number;
    bym: string;
}

export interface ResponseData {
    code: number;
    status: boolean;
    data: Data[] | [];
    job_id: number;
}

const StatusById = () => {
    const [monthFilter, setMonthFilter] = useState<string>("");
    const [activity, setActivity] = useState<string>("collection_efficeincy");
    const [jobId, setJobId] = useState<number>(0);
    const { response } = useQueryResponse();
    const apiResp: any = useMemo(() => response, [response]);

    const isLoading = useQueryResponseLoading();

    const tableData: any = useMemo(
        () => (isLoading ? [] : apiResp ? apiResp?.data : []),
        [apiResp, isLoading]
    );
    const jobID: any = useMemo(
        () => (isLoading ? undefined : apiResp ? apiResp?.job_id : undefined),
        [apiResp, isLoading]
    );
    const { dashBoardState } = useSelector((state: RootState) =>
        dashboardReducerState(state)
    );

    const { state, updateState } = TargetOverflowByIdMainState();

    const { search } = useLocation();

    useEffect(() => {
        const params = search && search?.split("?")[1].split("&");
        // console.log({ params });
        let searchParams: any = {};

        params &&
            params.forEach((o: any) => {
                let splitParam = o?.split("=");
                searchParams[splitParam[0]] = splitParam[1];
            });

        setMonthFilter(searchParams?.month);
        setActivity(searchParams?.activity);
        setJobId(searchParams?.job_id);

        return () => { };
    }, [search]);

    useEffect(() => {
        monthFilter !== undefined &&
            jobId !== 0 &&
            updateState({
                filter: {
                    activity: activity,
                    bym: monthFilter,
                    job_id: jobId,
                },
            });
    }, [activity, monthFilter]);

    return (
        <div
            className={`mt-4 px-4 ${!dashBoardState ? "ml-20  duration-300" : "ml-60 duration-300"
                }`}
        >
            <h1 className="text-gray-700 font-medium text-base my-2 ml-5">
                Target Overflow By Job Id
            </h1>

            <div className=" w-full flex justify-around items-center space-x-6">
                <h1 className="text-sm font-medium text-gray-500 ">
                    {jobID ? `#${jobID}` : ""}
                </h1>
                <div className="w-full flex justify-end item-center space-x-6">
                    <div className="w-1/5 ">
                        <h3 className=" text-center text-sm text-gray-800 font-medium -mb-2">
                            Select Activity
                        </h3>
                        <UserActivityDropDown
                            setActivity={setActivity}
                            dropdownModel={dropdownModel}
                            loading={isLoading}
                        />
                    </div>
                    <div className="w-1/5">
                        <h3 className="-mb-2 text-center text-sm text-gray-800 font-medium">
                            Select Month/Year
                        </h3>
                        <DateMothFilter
                            setMonthFilter={setMonthFilter}
                            monthYear={monthFilter}
                            loading={isLoading}
                        />
                    </div>
                </div>
            </div>
            <StatusByIdTable columns={byIdColumn} data={tableData || []} />
        </div>
    );
};

export default StatusById;
