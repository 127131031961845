import React, { useEffect, useMemo, useState } from "react";
import { columnsSummaryByActivity } from "../../SummaryByActivity/Context/TableComponent/columnsSummaryByActivity";
import CollectionTable from "../../CollectionSummary/Context/TableComponent/CollectionTable";

import { useQueryResponse } from "../Context/Core/QueryResponseProvider";
import SummaryByActivityTable from "../../SummaryByActivity/Context/TableComponent/SummaryByActivityTable";
import DateMothFilter from "../../TableFilters/FilterComp/DateMothFilter";
import CollectionDropDown from "../../TableFilters/FilterComp/CollectionDropDown";
import { SummaryByActivityMainState } from "../Context/";
import { useLocation } from "react-router-dom";
import Loader from "../../Loader/Loader";
import { useQueryResponseLoading } from "../Context/Core/QueryResponseProvider";
import { Input } from "src/components/ui/input";
import { Button } from "src/components/ui/button";
import { stringifyObjectToRequestQuery } from "src/helpers/index";
import { SUMMARY_BY_ACTIVITY } from "src/service/apis";
import { FileDown } from "lucide-react";
import UserIdDropDown from "../../TableFilters/FilterComp/UserIdDropDown";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import { dashboardReducerState } from "src/redux/slicer/dashboardSlicer";
import SearchBar from "../../TableFilters/FilterComp/SearchBar";
import FormikSelectPicker2 from "../../TableFilters/FilterComp/SearchBarComp/FormikSelectPicker2";
import { dropDownReducerState, userIdDropData } from "src/redux/slicer/dropDownSlicer";
import { optionsType } from "../../TableFilters/FilterComp/SearchBarComp/modules";

interface Data {
  job_id: number;
  profile_image: string;
  name: string;
  user_id: number;
  activity: string;
  total_dump_data_achieved: string;
  total_cumulative_activity_achieved: string;
  match: number;
  bym: string;
}
interface SummaryResponse {
  code: number;
  status: boolean;
  data: Data[] | [];
}

type Props = {};

const SummaryTable = (props: Props) => {
  const [monthFilter, setMonthFilter] = useState<string>("");
  const [activity, setActivity] = useState<string>("");
  const [searchId, setSearchId] = useState<number>(0);
  const [name, setName] = useState<string>("")
  const { response }: any = useQueryResponse();
  const isLoading = useQueryResponseLoading();
  const apiResponse: any = useMemo(() => response, [response]);

  const tableData: any = useMemo(
    () => (isLoading ? [] : apiResponse ? apiResponse?.data : []),
    [isLoading, apiResponse]
  );
  const responseData = response?.data || [];
  // console.log(responseData)
  const { state, updateState } = SummaryByActivityMainState();

  const { dashBoardState } = useSelector((state: RootState) =>
    dashboardReducerState(state)
  );

  const { search } = useLocation();

  useEffect(() => {
    const params = search && search?.split("?")[1].split("&");

    let searchParams: any = {};

    params &&
      params.forEach((o: any) => {
        let splitParam = o?.split("=");
        searchParams[splitParam[0]] = splitParam[1];
      });

    setMonthFilter(searchParams?.month);
    setActivity(searchParams?.activity);

    return () => { };
  }, [search]);



  const handleOnClickExport = () => {
    let table_query = { export_excel: "download" };
    const newstate = state?.filter && JSON.parse(JSON.stringify(state?.filter));
    // console.log({ newstate });
    // // delete newstate["_fkey"];
    // delete newstate["items_per_page"];
    // delete newstate["page"];
    table_query = { ...table_query, ...newstate };

    // query = stringifyObjectToRequestQuery(table_query);
    const queryString = stringifyObjectToRequestQuery(table_query);
    // console.log({ queryString });

    const baseUrl = SUMMARY_BY_ACTIVITY + "?" + queryString;
    // console.log(baseUrl);
    var win: any = window.open(baseUrl, "_blank");
    win.focus();
  };

  const { userIdData, jobIdData, loading, error, message } = useSelector((state: RootState) => dropDownReducerState(state))
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(userIdDropData())
  }, [])


  const _idData: any = useMemo(() => loading ? [] : (userIdData ? userIdData : []), [loading, userIdData])

  const dropDataUserName: any = useMemo(() => _idData && Array.isArray(_idData) ? (_idData.map((cur) => {
    return {
      value: cur.name.toLowerCase(),
      label: cur.name
    }
  })) : [], [_idData])

  const dropDataUserId: any = useMemo(() => _idData && Array.isArray(_idData) ? (_idData.map((cur) => {
    return {
      value: cur.user_id.toString().toLowerCase(),
      label: cur.user_id.toString()
    }
  })) : [], [_idData])

  useEffect(() => {
    searchId && !Number.isNaN(searchId) ?
      updateState({

        filter: {
          bym: monthFilter,
          activity: activity,
          user_id: searchId,

        },
      })
      : updateState({

        filter: {
          bym: monthFilter,
          activity: activity,
          name: name
        },
      })
  }, [monthFilter, activity, searchId, state?.page, state?.items_per_page, name]);

  // useEffect(() => {

  //   updateState({

  //     filter: {
  //       bym: monthFilter,
  //       activity: activity,
  //       name: name,

  //     },
  //   })
  // }, [monthFilter, activity, state?.page, state?.items_per_page, name]);
  return (
    <>
      <div
        className={`mt-2 px-4 ${!dashBoardState ? "ml-20  duration-300" : "ml-60 duration-300"
          }`}
      >
        {/* <h1 className='text-gray-700 font-medium text-lg mt-2 ml-5  sticky top-0'>By Activity Report </h1> */}
        <h2 className="text-gray-700 font-medium text-lg mt-2 ml-5  sticky top-0">
          Users Summary By Activity{" "}
        </h2>
        <div className="w-full flex justify-between items-center sticky top-0 mb-2">
          <div className="flex items-center space-x-6 mt-4">
            <h1 className="text-sm font-medium text-gray-500 ">
              {response && response?.job_id ? (
                <span className="text-gray-700">
                  #{response && response?.job_id}
                </span>
              ) : (
                ""
              )}{" "}
            </h1>
            <h1 className="text-sm font-medium text-gray-500 ">
              <span className="text-gray-700">{monthFilter}</span>
            </h1>
          </div>
          <div className="w-4/5 flex items-center space-x-4">
            <div className="w-full ">
              <h3 className=" text-center text-sm text-gray-800 font-medium -mb-2">
                Search By User Name
              </h3>
              {/* <UserIdDropDown
                setUserId={setSearchId}
                names="Search By User Id"
                searchId={searchId}
                loading={isLoading}
              /> */}


              <FormikSelectPicker2 options={dropDataUserName} onchange={(e) => {
                setName(e);

              }}
                disabled={searchId !== 0 && !Number.isNaN(searchId) ? true : false} />
            </div>
            <div className="w-full ">
              <h3 className=" text-center text-sm text-gray-800 font-medium -mb-2">
                Search By User Id
              </h3>
              <FormikSelectPicker2 options={dropDataUserId} onchange={(e) => {
                setSearchId(Number(e))

              }}
                disabled={name !== undefined && name !== "" ? true : false} />
            </div>
            <div className="w-4/5 ">
              <h3 className="-mb-2 text-center text-sm text-gray-800 font-medium">
                Select Activity
              </h3>
              <CollectionDropDown
                setActivity={setActivity}
                activity={activity}
                loading={isLoading}
              />
            </div>

            {/* <div className='w-1/5'>
            <h3 className='-mb-2 text-center text-sm text-gray-800 font-medium'>Select Month/Year</h3>
            <DateMothFilter setMonthFilter={setMonthFilter} monthYear={monthFilter} loading={isLoading}/>

          </div> */}

            <Button
              variant="secondary"
              onClick={handleOnClickExport}
              className="bg-white mt-4"
              disabled={isLoading}
            >
              {" "}
              <FileDown />
            </Button>
          </div>
        </div>
        <SummaryByActivityTable
          columns={columnsSummaryByActivity}
          data={tableData || []}
        />
      </div>
    </>
  );
};

export default SummaryTable;



