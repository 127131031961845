import axios, { AxiosError, AxiosResponse } from 'axios'
import { ACHIEVE_JOB_SUMMARY} from 'src/service/apis'

const getAchieveJobSummury = (query: string) => {
  return axios
    .get(`${ACHIEVE_JOB_SUMMARY}?${query}`)
    .then((d: AxiosResponse<any>) => d.data)
}

export {  getAchieveJobSummury }





