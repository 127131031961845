
import { Badge } from "src/components/ui/badge"
import { ColumnDef } from "@tanstack/react-table"
import { Check, X } from "lucide-react"
import moment from "moment"
import { number } from "yup"
import Rank from "./Rank"
import Player from "./Player"



export type ReportTable = {
    global_rank: number,
    group_rank: number,
    dist_name: string,
    designation: string,
    employee_id: string,
    name: string,
    total: number,
    target_points: number,
    collection_efficeincy_performance_points: number,
    collection_bonus_points: number,
    collection_efficeincy_total_points: number,
    collection_coverage_total_points: number,
    desconnection_total_points: number,
    reconnection_total_points: number,
    event_total_points: number,
    is_on_time_total_points: number,
    collection_efficeincy_target: number,
    collection_efficeincy_cumulative_collection: number,
    collection_coverage_target: number,
    collection_coverage_cumulative_collection: number,
    desconnection_target: number,
    desconnection_cumulative_collection: number,
    reconnection_target: number,
    reconnection_cumulative_collection: number,
    ce_target_points: number,
    cc_target_points: number,
    dc_target_points: number,
    rc_target_points: number,
    player_id: number,
    user_id: number,
    profile_image: string,
    desconnection_performance_points: number,
    reconnection_performance_points: number,
    is_on_time_performance_points: number,
    collection_coverage_performance_points: number,
    event_cumulative_collection: number,
    is_on_time_cumulative_collection: number,
    dist_id: number,
    report_month_year: string
}


export const EmployeeColums: ColumnDef<ReportTable>[] = [

    {
        accessorKey: "global_rank",
        header: "",
        columns: [
            {
                accessorKey: "global_rank",
                header: "RANK",

                cell: ({ row: { original } }) => {
                    return <Rank {...original}
                    />
                },
            }
        ],
    },
    {
        accessorKey: "employee_id",
        header: '',

        columns: [
            {
                accessorKey: "employee_id",
                header: "PLAYER",

                cell: ({ row: { original } }) => {
                    return <Player {...original}
                    />
                },
            }
        ],
    },
    {
        header: 'TOTAL REWARD',

        columns: [
            {
                accessorKey: "total",
                header: "TR POINTS",
                cell: (({ row }) => {
                    let startDate: number = row.getValue('total');

                    return startDate?.toFixed(1)
                })

            }
        ],
    },
    {
        accessorKey: "collection_efficeincy_cumulative_collection",

        header: ({ column }) => (
            <div className="text-pink-600" >COLLECTION EFFICIENCY</div>
        ),
        columns: [
            {
                accessorKey: "collection_efficeincy_cumulative_collection",

                header: ({ column }) => (
                    <div className="text-pink-600" >CA</div>
                ),
                cell: (({ row }) => {
                    let startDate: number = row.getValue('collection_efficeincy_cumulative_collection');

                    return startDate?.toFixed(1)
                })
            },
            {
                accessorKey: "collection_efficeincy_performance_points",

                header: ({ column }) => (
                    <div className="text-pink-600" >CE POINT</div>
                ),
                cell: (({ row }) => {
                    let startDate: number = row.getValue('collection_efficeincy_performance_points');

                    return startDate?.toFixed(1)
                })
            },
            {
                accessorKey: "collection_bonus_points",

                header: ({ column }) => (
                    <div className="text-pink-600" >CE BONUS POINT</div>
                ),
                cell: (({ row }) => {
                    let startDate: number = row.getValue('collection_bonus_points');

                    return startDate?.toFixed(1)
                })
            },
            {
                accessorKey: "collection_efficeincy_total_points",

                header: ({ column }) => (
                    <div className="text-pink-600" >CE TOTAL</div>
                ),
                cell: (({ row }) => {
                    let startDate: number = row.getValue('collection_efficeincy_total_points');

                    return startDate?.toFixed(1)
                })
            },
        ],
    },

    {
        accessorKey: "collection_coverage_cumulative_collection",

        header: ({ column }) => (
            <div className="text-green-600" >COLLECTION COVERAGE</div>
        ),
        columns: [
            {
                accessorKey: "collection_coverage_cumulative_collection",

                header: ({ column }) => (
                    <div className="text-green-600" >CP</div>
                ),

                cell: (({ row }) => {
                    let startDate: number = row.getValue('collection_coverage_cumulative_collection');

                    return startDate?.toFixed(1)
                })
            },
            {
                accessorKey: "collection_coverage_total_points",

                header: ({ column }) => (
                    <div className="text-green-600" >CC TOTAL</div>
                ),

                cell: (({ row }) => {
                    let startDate: number = row.getValue('collection_coverage_total_points');

                    return startDate?.toFixed(1)
                })
            },

        ],
    },
    {
        accessorKey: "desconnection_cumulative_collection",

        header: ({ column }) => (
            <div className="text-blue-600" >DISCONNECTION</div>
        ),
        columns: [
            {
                accessorKey: "desconnection_cumulative_collection",

                header: ({ column }) => (
                    <div className="text-blue-600" >DC COUNTS</div>
                ),
                cell: (({ row }) => {
                    let startDate: number = row.getValue('desconnection_cumulative_collection');

                    return startDate?.toFixed(1)
                })
            },
            {
                accessorKey: "desconnection_total_points",

                header: ({ column }) => (
                    <div className="text-blue-600" >DC TOTAL</div>
                ),
                cell: (({ row }) => {
                    let startDate: number = row.getValue('desconnection_total_points');

                    return startDate?.toFixed(1)
                })
            },

        ],
    },

    {
        accessorKey: "reconnection_cumulative_collection",
        header: ({ column }) => (
            <div className="text-amber-600" >RECONNECTION</div>
        ),
        columns: [
            {
                accessorKey: "reconnection_cumulative_collection",

                header: ({ column }) => (
                    <div className="text-amber-600" >RC COUNTS</div>
                ),
                cell: (({ row }) => {
                    let startDate: number = row.getValue('reconnection_cumulative_collection');

                    return startDate?.toFixed(1)
                })
            },
            {
                accessorKey: "reconnection_total_points",

                header: ({ column }) => (
                    <div className="text-amber-600" >RC TOTAL</div>
                ),
                cell: (({ row }) => {
                    let startDate: number = row.getValue('reconnection_total_points');

                    return startDate?.toFixed(1)
                })
            },

        ],
    },

    {
        accessorKey: "event_cumulative_collection",

        header: ({ column }) => (
            <div className="text-emerald-600" >EVENT</div>
        ),
        columns: [
            {
                accessorKey: "event_cumulative_collection",

                header: ({ column }) => (
                    <div className="text-emerald-600" >EP</div>
                ),
            },
            {
                accessorKey: "event_total_points",

                header: ({ column }) => (
                    <div className="text-emerald-600" >EP TOTAL</div>
                ),
            },

        ],
    },
    {
        header: 'IS ON TIME',
        columns: [
            {
                accessorKey: "is_on_time_total_points",
                header: "PA POINTS",
                cell: (({ row }) => {
                    let startDate: number = row.getValue('is_on_time_total_points');

                    return startDate?.toFixed(1)
                })
            },



        ],
    },
]

