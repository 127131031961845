import { Line, LineChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts';
import { useQueryResponse } from '../Core/QueryResponseProvider';
import React, { useMemo } from 'react';
import { convertToCamelCase } from '../../../../helpers';


import LineCharts from './LineCharts';
import ChartsSummary from './ChartsSummary';
import DatesBoxes from '../ChartComponent/DatesBoxes';
import { useQueryResponseLoading } from '../index';

type Props = {}

const BitwiseChartOneToTen = () => {
  const { response } = useQueryResponse();

  const isLoading = useQueryResponseLoading();
  const apiResponse: any = useMemo(() => response, [response])

  const tableData: any = useMemo(() => isLoading ? [] : (apiResponse ? apiResponse?.data : []), [isLoading, apiResponse])

  const summaryBucket_1: any = useMemo(() => isLoading ? [] : (tableData && tableData?.first_to_ten_summary ? tableData?.first_to_ten_summary : []), [isLoading, tableData])
  const targetBucket_1: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.first_to_ten_t_n_a : []), [isLoading, tableData])
  const details: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.first_to_ten_detailed : []), [isLoading, tableData])
  const first_to_ten_t_n_a: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.first_to_ten_t_n_a : []), [isLoading, tableData])




  return (
    <>

      <div className='flex flex-col justify-center items-center'>


        <div className='mt-2'>
          <DatesBoxes data={details} arrInd={10} ind={0} color='bg-green-600' />
        </div>


        <div className=' mt-2'>
          <LineCharts details={details} summary={first_to_ten_t_n_a} color='rgb(22 163 74)' textColor='text-green-600' />
        </div>
      </div>
      <div className=''>

        <ChartsSummary datas={summaryBucket_1} color='text-green-600' targets={targetBucket_1} details={details} />

      </div>

    </>
  )
}

export default BitwiseChartOneToTen;
