import React from 'react'
import { QueryRequestProvider,QueryResponseProvider,  } from './Context'
import BucketTable from '../BucketWisePoint/Component/BucketTable'




type Props = {}

const Main = (props: Props) => {
  return (
    <div className='mt-16 w-full'>
      <BucketTable/>
    </div>
  )
}

const BucketPointMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider >
            <QueryResponseProvider >
                    <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default BucketPointMain