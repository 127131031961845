import {  MainCollectionSummaryState } from '../index'
import { ReportTable } from './columnsCollection'
import React, { ReactElement, ReactNode, ReactPropTypes } from 'react'
import { Link } from 'react-router-dom'


interface props extends ReportTable {
    children: ReactNode
}

const RedirectToUserActivity = ({
    activity , children
}: props) => {
    const { state } =  MainCollectionSummaryState()
    return (
        <Link to={`/dashboard/dv/summary-act?activity=${activity}&month=${state.filter.bym}`}>
            {children}
        </Link>
    )
}

export default RedirectToUserActivity