
import { BarChart3, Calculator, CalendarCheck, FileLock2, Frame, History, LandPlot, Layers3, LayoutDashboard, ListChecks, ListFilter, LogOut, MenuIcon, Milestone, Ribbon, Settings, Sigma, User, Users, Variable, Workflow } from 'lucide-react'


export interface IMenuItem {
    name: string,
    iconName: any,
    path: string,
    options?: Array<{
        name: string,
        iconName: any,
        path: string,
    }>
}

export interface IMenu {
    heading: string,
    options?: Array<{
        name: string,
        iconName: any,
        path: string,
    }>
}
export const MenuOptions: IMenuItem[] = [
    // {
    //     name: "Dashboard",
    //     iconName: (className: string) => <LayoutDashboard size={16} className={className} />,
    //     path: "/dashboard",

    // },
    {
        name: "Jobs Status",
        iconName: (className: string) => <ListFilter size={18} className={className} />,
        path: "/pages"
    },
    {
        name: "Summary By Month/Year",
        iconName: (className: string) => <BarChart3 size={18} className={className} />,
        path: "/pages/collection-summary"
    },
    {
        name: "Users Summary By Activity",
        iconName: (className: string) => <LandPlot size={18} className={className} />,
        path: "/pages/summary-act"
    },
    {
        name: "Users Monthly Report",
        iconName: (className: string) => <Layers3 size={18} className={className} />,
        path: "/pages/user-act"
    },
    {
        name: "Points Breakup",
        iconName: (className: string) => <BarChart3 size={18} className={className} />,
        path: "/pages/bucketwise-point"
    },

    {
        name: "Sandbox",
        iconName: (className: string) => <Calculator size={18} className={className} />,
        path: "/pages/editable-comp"
    },
    {
        name: "Target Overflow",
        iconName: (className: string) => <Workflow size={18} className={className} />,
        path: "/pages/target-overflow"
    },
]


export const Menu: IMenu[] = [

    {
        heading: 'Data Validation',
        options: [
            {
                name: "Data Validation",
                iconName: (className: string) => <ListFilter size={18} className={className} />,
                path: "/dashboard/dv"
            },
            {
                name: "Observations",
                iconName: (className: string) => <Workflow size={18} className={className} />,
                path: "/dashboard/job-status/js"
            },
            {
                name: "Rewards",
                iconName: (className: string) => <Ribbon size={18} className={className} />,
                path: "/dashboard/rewards/rr"
            },
            {
                name: "Wall Of Fame",
                iconName: (className: string) => <Frame size={18} className={className} />,
                path: "/dashboard/wall-of-fame/wl"
            },
        ]
    },

    {
        heading: 'Players Points Calculation',
        options: [
            {
                name: "Sandbox",
                iconName: (className: string) => <Calculator size={18} className={className} />,
                path: "/dashboard/editable-comp"
            },
            {
                name: "Sandbox For Punch In",
                iconName: (className: string) => <Calculator size={18} className={className} />,
                path: "/dashboard/editable-punch"
            },
        ]
    },




]