
import { useQueryResponse } from "../Core/QueryResponseProvider"
import { SummaryByActivityMainState, useQueryResponsePagination } from ".."
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  DoubleArrowLeftIcon,
  DoubleArrowRightIcon,
} from "@radix-ui/react-icons"
import { Table } from "@tanstack/react-table"
import { useEffect, useState } from "react"

import { Button } from "src/components/ui/button"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "src/components/ui/select"


interface DataTablePaginationProps<TData> {
  table: Table<TData>
}

export function DataTablePagination<TData>({
  table,
}: DataTablePaginationProps<TData>) {
  const [firstPage, setFirstPage] = useState<boolean>(true)
  const { state, updateState } = SummaryByActivityMainState()
  const paginationState = useQueryResponsePagination()

  let activity = state ? state?.filter?.activity : ""
  let bym = state ? state?.filter?.bym : "";
  let perPage = state ? state?.filter?.per_page : "";


  const setPageNumber = (page: number) => {
    updateState({
      page: page,
    })
  }




  useEffect(() => {
    if (state?.filter?.page === 1) {
      setFirstPage(true)
    } else {
      setFirstPage(false)
    }
  }, [state?.page, state?.items_per_page])

  return (
    <div className="flex items-center justify-between px-2">
      <div className="flex-1 text-sm text-muted-foreground">
        {table.getFilteredSelectedRowModel().rows.length} of{" "}
        {table.getFilteredRowModel().rows.length} row(s) selected.
      </div>
      <div className="flex items-center space-x-6 lg:space-x-8">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">Rows per page</p>
          <Select
            value={`${table.getState().pagination.pageSize}`}
            onValueChange={(value: any) => {
              table.setPageSize(Number(value))
              // console.log(value, "itemperpage")
              // const pageSize = Number(value) as 10 | 30 | 50 | 100
              updateState({
                items_per_page: value,


              })

            }}
          >
            <SelectTrigger className="h-8 w-[70px]">
              <SelectValue placeholder={table.getState().pagination.pageSize} />
            </SelectTrigger>
            <SelectContent side="top">
              {[10, 30, 50, 100].map((pageSize) => (
                <SelectItem key={pageSize} value={`${pageSize}`}>
                  {pageSize}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
        <div className="flex w-[100px] items-center justify-center text-sm font-medium">
          Page {" "}
          {table.getState().pagination.pageIndex + 1}

          of{" "}
          {paginationState.last_page}
        </div>
        <div className="flex items-center space-x-2">
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => setPageNumber(1)}
            disabled={!table.getCanPreviousPage()}

          >
            <span className="sr-only">Go to first page</span>
            <DoubleArrowLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => setPageNumber(paginationState.page > 1 ? Number(paginationState.page) - 1 : 1)}
            disabled={!table.getCanPreviousPage()}

          >
            <span className="sr-only">Go to previous page</span>
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="h-8 w-8 p-0"
            onClick={() => setPageNumber(paginationState.page + 1)}
            disabled={paginationState?.last_page === state?.filter?.page ? true : false}
          >
            <span className="sr-only">Go to next page</span>
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="outline"
            className="hidden h-8 w-8 p-0 lg:flex"
            onClick={() => setPageNumber(paginationState.last_page)}

            disabled={!table.getCanNextPage()}
          >
            <span className="sr-only">Go to last page</span>
            <DoubleArrowRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    </div>
  )
}

