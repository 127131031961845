import React, { useEffect, useMemo, useState } from 'react'
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from 'src/components/ui/table';
import { ColumnDef, ColumnFiltersState, PaginationState, SortingState, VisibilityState, flexRender, getCoreRowModel, getFacetedRowModel, getFacetedUniqueValues, getFilteredRowModel, getPaginationRowModel, getSortedRowModel, useReactTable } from '@tanstack/react-table';
// import { UserByActivityMainState } from '../';
import { ScrollArea } from 'src/components/ui/scroll-area';
import { DataTablePagination } from '../TableComponent/data-table-pagination';
import { UserByActivityMainState, useQueryResponsePagination, useQueryResponseLoading } from '../index';
import { Loader2 } from 'lucide-react';


type Props = {}
interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[]
  data: TData[]
}
const UserActivityTable = <TData, TValue>({ columns, data }: DataTableProps<TData, TValue>) => {

  const { state } = UserByActivityMainState();
  const isLoading = useQueryResponseLoading()
  const paginationState = useQueryResponsePagination()

  const pagination = React.useMemo(
    () => ({
      pageIndex: state.page - 1,
      pageSize: state.items_per_page,
    }),
    [state.page, state.items_per_page]
  )


  const pageCounts = useMemo(() =>
    paginationState?.total_records ? Number(paginationState?.total_records) : 1,
    [paginationState?.total_records])


  const [rowSelection, setRowSelection] = useState({})
  const [columnVisibility, setColumnVisibility] = useState<VisibilityState>({})
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([])
  const [sorting, setSorting] = useState<SortingState>([])
  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility,
      rowSelection,
      columnFilters,
      pagination,
    },
    pageCount: pageCounts,
    enableRowSelection: true,
    // onPaginationChange: setPagination,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    onColumnVisibilityChange: setColumnVisibility,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
  })

  return (
    <>

      <div className=" w-full relative overflow-x-auto shadow-md mt-4 sm:rounded-lg">
        <ScrollArea className="w-full h-[410px] relative rounded-md ">
          <Table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 relative  ">
            <TableHeader className="text-xs text-gray-900 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 text-center hover:bg-gray-400 sticky top-0 left-0 right-0">
              {table.getHeaderGroups().map((headerGroup) => <TableRow key={`${headerGroup.id}`}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHead key={header.id} className="text-center" scope="col">
                      {header.isPlaceholder ? null :
                        flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                    </TableHead>
                  )
                })}
              </TableRow>)}
            </TableHeader>
            <TableBody >
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row.id}
                    data-state={row.getIsSelected() && "selected"}
                    className="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700 hover:bg-gray-200" >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell.id} className=" text-center overflow-clip" scope="row" >
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    {isLoading ? <div className='flex justify-center items-center '>
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
                    </div> : "No results."}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </ScrollArea>
      </div>
      <div className='mt-1'>
        <DataTablePagination table={table} />
      </div>
    </>
  )
}

export default UserActivityTable

