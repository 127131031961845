
import React from 'react';
import Headers from './modules/Layouts/Header/Headers';
import { QueryClient, QueryClientProvider } from "react-query";
import { Outlet } from 'react-router-dom';
import Sidebarlayout from './modules/Layouts/SideBar/Sidebarlayout';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';



function App() {

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Headers />
        <div className='flex cursor-default'>
          <Sidebarlayout />

          <Outlet />
          <ToastContainer
            position="top-right"
            autoClose={5000}
          />
        </div>
      </QueryClientProvider>

    </>
  );
}

export default App;
