import React from "react"
import Rewards from "../Rewards/Component/Rewards"

type Props = {}

const RewardsMain = (props: Props) => {
    return (

        <div className='mt-14 w-full mb-2'>
            <Rewards />
        </div>
    )
}



export default RewardsMain