import { PaginationState, QueryState, converMonthYearToMonthYearNo, initialQueryState, stringifyObjectToRequestQuery } from "src/helpers/index"
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import axios, { AxiosError } from "axios"
import { getHolidayOfferTableUi, getHolidayOfferUi, getHolidaySummary } from "src/service/services"
import { RootState } from "../store"

export interface OfferUIProps {
    date: string[],
    offer_names: string[],
    category: string[]
}
interface OfferState extends QueryState {
    message: string | undefined
    error: boolean,
    dataChanged: boolean,
    summaryLoading: boolean,
    offerUiLoading: boolean,
    tableLoading: boolean,
    holidaySummaryData: any,
    offerUIData: OfferUIProps,
    // paginations: PaginationState,
    page: number
    items_per_page: 10 | 30 | 50 | 100,
    tableData: any[],
    dropdownOfferState: string | undefined,
    dropdownCategoryState: string | undefined

}

const initialState: OfferState = {
    summaryLoading: false,
    offerUiLoading: false,
    tableLoading: false,
    message: undefined,
    error: false,
    dataChanged: false,
    holidaySummaryData: {},
    offerUIData: {
        date: [],
        offer_names: [],
        category: []
    },
    // paginations: initialQueryState,
    page: 1,
    items_per_page: 50,
    tableData: [],
    dropdownOfferState: undefined,
    dropdownCategoryState: undefined,
}


export const offer_Summary_data: any = createAsyncThunk(
    'chart/holiday_summary_data',
    async (_, thunkApi: any,) => {
        try {


            const getFilterState = thunkApi.getState().dashboard


            let dattes = stringifyObjectToRequestQuery(converMonthYearToMonthYearNo(getFilterState.monthYear?.toUpperCase()));
            const response = await getHolidaySummary(dattes);

            return response.data;

        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)

export const offer_ui_data: any = createAsyncThunk(
    'chart/offerui',
    async (_, thunkApi: any,) => {
        try {


            const getFilterState = thunkApi.getState().dashboard
            const query = {

                bym: getFilterState.monthYear,

            }

            const queryparams = stringifyObjectToRequestQuery(query)
            // console.log(queryparams)
            const response = await getHolidayOfferUi(queryparams);

            return response.data;

        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)

export const offer_summury_table: any = createAsyncThunk(
    'chart/offerTable',
    async (_, thunkApi: any,) => {
        try {

            const getFilterState = thunkApi.getState().dashboard
            const getFilterState_2 = thunkApi.getState().offer;
            const query = {
                page: getFilterState_2.page,
                items_per_page: getFilterState_2.items_per_page,
                offer_name: getFilterState_2.dropdownOfferState,
                activity: getFilterState_2.dropdownCategoryState
            }
            const queryparams = stringifyObjectToRequestQuery(query);

            let dattes = stringifyObjectToRequestQuery(converMonthYearToMonthYearNo(getFilterState.monthYear?.toUpperCase()));
            let ux = `${queryparams}&${dattes}`
            const response = await getHolidayOfferTableUi(ux);

            return response;

        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)

export const offer_summary_api_call: any = createAsyncThunk(
    'chart/offer_summary_api_call',
    async (_, thunkApi: any,) => {
        thunkApi.dispatch(offer_Summary_data());
        thunkApi.dispatch(offer_ui_data())
        thunkApi.dispatch(offer_summury_table())
    }
)


export const offerSlicer = createSlice({
    name: "offerSlicer",
    initialState,
    reducers: {
        HolidaySummaryData(state, action: PayloadAction<any>) {
            state.holidaySummaryData = action.payload
        },

        HolidayOfferUIDataData(state, action: PayloadAction<any>) {
            state.holidaySummaryData = action.payload
        },
        HolidayOfferTableData(state, action: PayloadAction<any>) {
            state.tableData = action.payload
        },
        // updatePagination(state, action: PayloadAction<any>) {
        //     state.paginations = action.payload
        // },
        setPage: (state, action: PayloadAction<any>) => {
            state.page = action.payload;
        },
        setItemsPerPage: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload;
        },
        setPaggination: (state, action: PayloadAction<any>) => {
            state.items_per_page = action.payload.pageSize;
            state.page = action.payload.pageIndex;
        },
        updateDropdownOfferState(state, action: PayloadAction<any>) {
            state.dropdownOfferState = action.payload
        },
        updateDropdownCategoryState(state, action: PayloadAction<any>) {
            state.dropdownCategoryState = action.payload
        },
        resetChangedState(state) {
            state.dataChanged = false;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
        setResetStateOffer(state) {
            state.items_per_page = initialState.items_per_page;
            state.page = initialState.page;
            state.dropdownOfferState = initialState.dropdownOfferState;
            state.dropdownCategoryState = initialState.dropdownCategoryState

        }
    },
    extraReducers: (builder) => {
        builder.addCase(offer_Summary_data.fulfilled, (state, action: PayloadAction<any>) => {
            state.holidaySummaryData = action.payload;
            state.summaryLoading = false;
            // console.log(action.payload)

        });
        builder.addCase(offer_Summary_data.pending, (state) => {
            state.summaryLoading = true;
        });
        builder.addCase(offer_Summary_data.rejected, (state, action: PayloadAction<any>) => {
            state.summaryLoading = false;
            state.message = action.payload || 'Error Occured'
        });


        builder.addCase(offer_ui_data.fulfilled, (state, action: PayloadAction<any>) => {
            state.offerUIData = action.payload;
            state.offerUiLoading = false;
            // console.log(action.payload)

        });
        builder.addCase(offer_ui_data.pending, (state) => {
            state.offerUiLoading = true;
        });
        builder.addCase(offer_ui_data.rejected, (state, action: PayloadAction<any>) => {
            state.offerUiLoading = false;
            state.message = action.payload || 'Error Occured'
        });


        builder.addCase(offer_summury_table.fulfilled, (state, action: PayloadAction<any>) => {
            state.tableData = action.payload?.data;
            state.tableLoading = false;
            state.dropdownOfferState = initialState.dropdownOfferState;
            state.dropdownCategoryState = initialState.dropdownCategoryState;

            state.error = false;
            state.message = undefined;
            state.items_per_page = action.payload?.payload?.pagination?.items_per_page;
            state.page = action.payload?.payload?.pagination?.page;
            state.total_records = action.payload?.payload?.pagination?.total_records;
            state.total = action.payload?.payload?.pagination?.total;
            // console.log(action.payload?.payload?.pagination?.total)
        });
        builder.addCase(offer_summury_table.pending, (state) => {
            state.tableLoading = true;
            state.error = false;
            state.message = "";
            state.tableData = []
        });
        builder.addCase(offer_summury_table.rejected, (state, action: PayloadAction<any>) => {
            state.tableLoading = false;
            state.message = action.payload || 'Error Occured'
        });
    }
})

export const { setError, resetError, resetChangedState, HolidaySummaryData, HolidayOfferUIDataData, HolidayOfferTableData, updateDropdownOfferState, updateDropdownCategoryState, setItemsPerPage, setPage, setPaggination, setResetStateOffer } = offerSlicer.actions;
export const offerReducerState = (state: RootState) => state.offer;
export default offerSlicer.reducer;