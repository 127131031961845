import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { getAllJobId, getAllPlayerUserId } from "src/service/services"
import { RootState } from "../store"
import axios, { AxiosError } from "axios"
import { GET_ALL_PLAYERS_USERID } from "src/service/apis"
interface DropdownState {
    userIdData: number[],
    jobIdData: number[],
    loading: boolean,
    message: string | undefined
    error: boolean,
    dataChanged: boolean
}

const initialState: DropdownState = {
    userIdData: [],
    jobIdData: [],
    loading: false,
    message: undefined,
    error: false,
    dataChanged: false
}

export const userIdDropData: any = createAsyncThunk(
    'dropDown/userIdDropData',
    async (thunkApi: any) => {
        try {
            const response = await getAllPlayerUserId();

            return response.data;


        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)



export const jobIdDropData: any = createAsyncThunk(
    'dropDown/jobIdDropData',
    async (thunkApi: any) => {
        try {
            const response = await getAllJobId()



            return response.data;
        } catch (err) {
            const error = err as Error | AxiosError;
            if (axios.isAxiosError(error)) {
                thunkApi.dispatch(setError({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                }));
                return thunkApi.rejectWithValue({
                    status: error.response?.data.status,
                    message: error.response?.data.message
                });
            }
            thunkApi.dispatch(setError({
                status: 0,
                message: error.message
            }));
            return thunkApi.rejectWithValue({
                status: 0,
                message: error.message
            });
        }
    }
)

export const dropdownSlicer = createSlice({
    name: "dropdownSlicer",
    initialState,
    reducers: {
        updateUserIdDropDown(state, action: PayloadAction<any>) {
            state.userIdData = action.payload
        },
        updateJobIdDropDown(state, action: PayloadAction<any>) {
            state.jobIdData = action.payload
        },
        resetChangedState(state) {
            state.dataChanged = false;
        },
        setError: (state, action: PayloadAction<any>) => {
            state.error = true;
            state.message = action.payload.message;
        },
        resetError: (state) => {
            state.error = false;
            state.message = undefined;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(userIdDropData.fulfilled, (state, action) => {
            state.userIdData = action.payload;
            state.loading = false
            // console.log(action.payload)

        });
        builder.addCase(userIdDropData.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(userIdDropData.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload || 'Error Occured'
        });

        builder.addCase(jobIdDropData.fulfilled, (state, action) => {

            state.jobIdData = action.payload;

            state.loading = false

        });
        builder.addCase(jobIdDropData.pending, (state, action) => {
            state.loading = true;
        });
        builder.addCase(jobIdDropData.rejected, (state, action) => {
            state.loading = false;
            state.message = action.payload || 'Error Occured'
        });

    }
})

export const { updateJobIdDropDown, updateUserIdDropDown, setError, resetError, resetChangedState } = dropdownSlicer.actions;
export const dropDownReducerState = (state: RootState) => state.dropDown;
export default dropdownSlicer.reducer;

