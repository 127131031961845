import dayjs from 'dayjs'
import { BitwiseUpdatedMainState } from '../index'
import moment from 'moment'
import React from 'react'
interface DateJsonProops {
  dt: string
}

const JsonDate = ({ dt }: DateJsonProops) => {
  const { state, updateState } = BitwiseUpdatedMainState()

  let startDate: string = dayjs(dt).format('MMM DD YYYY');
  const handleClick = (date: string) => {
    window.open(`${process.env.REACT_APP_API_URL_GAMIFICATION_SERVICE}/api/get-bucket-wise-point?bym=${state.filter.bym}&activity=${state?.filter?.activity}&user_id=${state?.filter?.user_id}&dt=${dt}`)
  }

  return (
    <div onClick={() => handleClick(startDate)}
      className='cursor-pointer'>{startDate}</div>
  )
}

export default JsonDate