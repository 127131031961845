import React from 'react'
import { QueryRequestProvider, QueryResponseProvider, } from './Context'
import WallOfFame from './Component/WallOfFame'
import WallOfFameDistrict from '../WallOfFame/Component/WallOfFameDistrict'


type Props = {}

const Main = (props: Props) => {
    return (
        <div className='mt-14 w-full min-h-screen  bg-gradient-to-b from-blue-50 to-blue-400 z-10'>
            <WallOfFameDistrict />
        </div>
    )
}

const WallOfFameDistrictwiseMain: React.FC<Props> = ({ }) => {
    return <>
        <QueryRequestProvider >
            <QueryResponseProvider >
                <Main />
            </QueryResponseProvider>
        </QueryRequestProvider>
    </>
}

export default WallOfFameDistrictwiseMain