import { da } from 'date-fns/locale'
import React, { useEffect, useMemo, useState } from 'react'
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts'

const OtherActivityChart = ({ data, activity }: any) => {

  const valueMax = useMemo(() => data && data?.y_max ? data?.y_max[data.y_max?.length - 1].yAxisxValue : 0, [data])

  // is_on_time event_count
  const target: any = useMemo(() => data ? data?.t_n_a?.target : 0, [data])

  const xAxisValues: any = useMemo(() => {
    return data?.detailed?.map((cur: any) => ({
      "xAxisValue": Number(((Number(cur.cumulative_collection) / Number(target)) * 100).toFixed(2)),

      "Performance Points": cur?.actual_points.toFixed(2),
      "Bonus Points": cur?.bonus_points.toFixed(2),
      "Total Points": Number(cur?.total_points).toFixed(2),
      "Date": cur?.dt
    }));
  }, [data]);



  const xAxisValues2: any = useMemo(() => {
    return data?.detailed?.map((cur: any) => ({
      "xAxisValue": Number(((Number(cur.actual_points) / Number(target)) * 100).toFixed(2)),

      "Performance Points": cur?.actual_points.toFixed(2),
      "Bonus Points": cur?.bonus_points.toFixed(2),
      "Total Points": Number(cur?.total_points).toFixed(2),
      "Date": cur?.dt
    }));
  }, [data]);



  const CustomTooltip = ({ active, payload, label }: any) => {

    if (active && payload && payload.length) {

      return (
        <div className="custom-tooltip text-left">
          {/* <p>{`Percentage: ${label}`}</p> */}

          {/* {payload.map((point: any) => (

            <p key={point.name} style={{ color: point.color }} className='text-sm font-medium '>
              {`${point.name}: ${point.value}`}

            </p>
          ))} */}
          <p className='text-sm font-medium ' style={{ color: `#E5E7EB` }}> {payload[0].name === undefined ? "" : (`Base Line: ${payload[0].value}`)} </p>
          <p className='text-sm font-medium ' style={{ color: `#8b0a50` }}>{payload[0].payload['Performance Points'] === undefined ? "" : (`Performance Points: ${payload[0].payload['Performance Points']}`)}</p>
          <p className='text-sm font-medium ' style={{ color: `blue` }}>{payload[0].payload.Date === undefined ? "" : (`Date: ${payload[0].payload.Date}`)}</p>
          <p className='text-sm font-medium ' style={{ color: `blue` }}>{payload[0].payload.xAxisValue === undefined ? "" : (`Achived Percentage: ${payload[0].payload.xAxisValue}%`)}</p>
          {/*  */}
        </div>
      );
    }

    return null;
  };
  return (
    <div className='flex justify-center'>
      <LineChart
        width={400}
        height={300}
        margin={{
          top: 5,
          right: 10,
          left: 20,
          bottom: 5,
        }}
        data={data?.y_max}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey='xAxisValue'
          type='number'
          style={{ "fontSize": "12px" }}

          domain={[0, 100]} tickCount={15}
        />
        <YAxis
          style={{ "fontSize": "12px" }}
          domain={[0, valueMax]}
          dataKey='yAxisValue'
          tickCount={15} />

        <Tooltip content={<CustomTooltip />} />
        {/* <Legend /> */}

        <Line
          type="monotone"
          dataKey="yAxisxValue"
          name="Base Line"
          stroke="rgb(229 231 235)"
          legendType='none'
          data={data?.y_max} />
        {activity === 'is_on_time' || activity === 'event_count' ? (<Line
          type="monotone"
          data={xAxisValues2}
          dataKey='Performance Points'
          stroke='#8b0a50'
          strokeWidth={1}
        // opacity={opacity.performancePoints} 
        />) : (<Line
          type="monotone"
          data={xAxisValues}
          dataKey='Performance Points'
          stroke='#8b0a50'
          strokeWidth={1}
        // opacity={opacity.performancePoints} 
        />)}
        {/* <Line
          type="monotone"
          data={xAxisValues2}
          dataKey='Performance Points'
          stroke='#8b0a50'
          strokeWidth={1}
        // opacity={opacity.performancePoints} 
        /> */}

      </LineChart>
    </div>
  )
}

export default OtherActivityChart