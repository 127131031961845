import React from "react";
import { ColumnDef } from "@tanstack/react-table"
import { ArrowUpRightSquare, Check, X } from "lucide-react"
import { convertToCamelCase } from '../../../../helpers';
import { click } from "@testing-library/user-event/dist/click";
import ActivityInfo from "../TableComponent/ActivityInfo";
import Niara from '../TableComponent/Niara.svg';
import { Badge } from "src/components/ui/badge"
import RedirectToActivity from "../TableComponent/RedirectToActivity";
import RedirectToUserActivity from "../TableComponent/RedirectToUserActivity";

export type ReportTable = {
  activity: string,
  total_dump_data_achieved: string,
  total_cumulative_activity_achieved: string,
  is_match: number
}


export const columnsCollection: ColumnDef<ReportTable>[] = [
  {
    accessorKey: "activity",
    header: "Activity",
    cell: ({ row: { original } }) => {

      return <RedirectToActivity {...original} />

    }
  },

  {
    accessorKey: "total_dump_data_achieved",
    header: " Data Received From TopUPP",
  },
  {
    accessorKey: "total_cumulative_activity_achieved",
    header: " Data In ERRS",
  },
  {
    accessorKey: "is_match",
    header: "Match",
    cell: ({ row }) => {
      const match = parseFloat(row.getValue("is_match"))

      if (match === 1) {
        return <Check className=" text-green-600 text-center"/>
        // return <Badge
        //   className=" bg-transparent border-primary text-primary hover:bg-transparent hover:text-primary ">Success</Badge>
      } else {
        return <X className=" text-red-600 text-center"/>
        // return <Badge className=" bg-transparent border-destructive text-destructive hover:bg-transparent hover:text-destructive"  >Fail</Badge>
      }
    },
  },

  {
    id: "action",
    header: "Action",
    cell: ({ row: { original } }) => {
      return <RedirectToUserActivity {...original}>
        <>
          <Badge> Go</Badge>
        </>
      </RedirectToUserActivity>
    },
  }

]

{/* <div className="w-[24px] h-[24px] flex justify-center items-center border border-1 border-gray-500 hover:border-blue-500 rounded p-1 text-xs text-gray-500 hover:text-blue-500 space-x-1  ">
<ArrowUpRightSquare size={16} />
</div> */}

