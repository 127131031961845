import CollectionSummaryMain from '../modules/CollectionSummary/CollectionSummaryMain'
import AchievedJobSummaryMain from '../modules/AchieveJobSummary/AchievedJobSummaryMain'
import { useEffect } from 'react'
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom'
import SummaryByActivityMain from 'src/modules/SummaryByActivity/SummaryByActivityMain'
import CollectionByUserActivityMain from 'src/modules/CollectionByUserActivity/CollectionByUserActivityMain'
import BucketPointMain from 'src/modules/BucketWisePoint/BucketPointMain'

import EditableComponentMains from 'src/modules/EditableComponent/EditableComponentMains'
import TargetOverflowMains from 'src/modules/TargetOverflow/TargetOverflowMains'
import JobStatusMain from 'src/modules/JobStatusTargerOverFlow/JobStatusMain'
import StatusByIdMain from '../modules/TargetOverflowByJobStatus/StatusByIdMain'
import ObservationRoutes from './ObservationRoutes'
import DataValidateRoute from './DataValidateRoute'
import PointExel from './PointExel'
// import EmployeeRewarsMains from '../modules/EmployeeRewards/EmployeeRewarsMains'
import RewardsMain from '../modules/Rewards/RewardsMain'
import EditablePunchInMain from '../modules/EditablePunchComp/EditablePunchInMain'
import WallOfFameMain from '../modules/WallOfFame/WallOfFameMain'
import WallOfFameDistrictwiseMain from '../modules/WallOfFame/WallOfFameDistrictwiseMain'
import RewardsDetailsReportMains from '../modules/RewardsDetailsReport/RewardsDetailsReportMains'



const DataValidateRoutePages = () => {
    return (
        <Routes>
            <Route element={<><Outlet /></>}></Route>
            <Route path='dv' element={<AchievedJobSummaryMain />} />
            <Route path='dv/collection-summary' element={<CollectionSummaryMain />} />
            <Route path='dv/summary-act' element={<SummaryByActivityMain />} />
            <Route path='dv/user-act' element={<CollectionByUserActivityMain />} />
            <Route path='dv/bucketwise-point' element={<BucketPointMain />} />
            <Route path={"*"} element={<>error</>} />
        </Routes>
    )
}

const ObservationsRoutePages = () => {
    return (
        <Routes>
            <Route element={<><Outlet /></>}></Route>
            <Route path='js' element={<JobStatusMain />} />
            <Route path='js/job-status-by-id' element={<StatusByIdMain />} />
            <Route path={"*"} element={<>error</>} />
        </Routes>
    )
}

const WallOfFameRoutePage = () => {
    return (
        <Routes>
            <Route element={<><Outlet /></>}></Route>
            <Route path='/wl' element={<WallOfFameMain />} />
            <Route path='wl/district' element={<WallOfFameDistrictwiseMain />} />
            <Route path={"*"} element={<>error</>} />
        </Routes>
    )
}

const RewardsRoutingPage = () => {
    return (<Routes>
        <Route element={<><Outlet /></>}></Route>
        <Route path='/rr' element={<RewardsMain />} />
        <Route path='rr/report' element={<RewardsDetailsReportMains />} />
        <Route path={"*"} element={<>error</>} />
    </Routes>)
}

const AuthRoutes = () => {

    return (
        <Routes >
            <Route path="dashboard/*" element={<DataValidateRoutePages />} />
            {/* <Route path='collection-summary' element={<CollectionSummaryMain />} />
            <Route path='summary-act' element={<SummaryByActivityMain />} />
            <Route path='user-act' element={<CollectionByUserActivityMain/>}/>
            <Route path='bucketwise-point' element={<BucketPointMain/>}/> */}
            <Route path='dashboard/editable-comp' element={<EditableComponentMains />} />
            <Route path='dashboard/editable-punch' element={<EditablePunchInMain />} />
            {/* <Route path='target-overflow' element={<TargetOverflowMains/>}/> */}
            <Route path='dashboard/job-status/*' element={<ObservationsRoutePages />} />
            <Route path='dashboard/rewards/*' element={<RewardsRoutingPage />} />
            <Route path='dashboard/wall-of-fame/*' element={<WallOfFameRoutePage />} />
            {/* <Route path='dashboard/employee/*' element={<EmployeeRewarsMains />} /> */}
            {/* <Route path='' element={<StatusByIdMain/>}/> */}

            < Route index element={<Navigate to="/dashboard/dv" />} />

        </Routes>
    )
}




export default AuthRoutes;


