import React, { useMemo } from 'react'
import { useQueryResponse } from '../Context/Core/QueryResponseProvider';
import { jobStatusColumns } from '../Context/JobStatusComp/jobStatusColumns';
import JobStatusTable from '../Context/JobStatusComp/JobStatusTable';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/store';
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer';
import { useQueryResponseLoading} from '../Context/index';

interface Data{
  batch_job_id: number,
  start_date: string,
  end_date: string,
  is_success: number,
  bym: string
}
interface ResponseData{
  code: number,
    status: boolean,
    data:Data[] |[]
}

const JobStatus = () => {
  const { response } = useQueryResponse();
  const isLoading = useQueryResponseLoading();
const apiResponse:any=useMemo(()=>response,[response])
const tableData: any = useMemo(() => isLoading ? [] : (apiResponse ? apiResponse?.data : []), [apiResponse, isLoading])
  // const responseData = response?.data || [];

  const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state))
//  console.log(responseData,"data")
  return (
    <div className={`mt-4 px-4 ${!dashBoardState?'ml-20  duration-300':'ml-60 duration-300'}`}>
  
    <h2 className='text-gray-700 font-medium text-lg mt-2 ml-5'>Target Overflow Job Status</h2>
    
    
   <JobStatusTable columns={jobStatusColumns} data={tableData || []}/>
    </div>
  )
}

export default JobStatus