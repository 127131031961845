import React, { useEffect, useMemo, useState } from 'react'
import { columnsSummaryByActivity } from '../../SummaryByActivity/Context/TableComponent/columnsSummaryByActivity'
import CollectionTable from '../../CollectionSummary/Context/TableComponent/CollectionTable'

import { useQueryResponse } from '../Context/Core/QueryResponseProvider'

import UserActivityTable from '../../CollectionByUserActivity/Context/TableComponent/UserActivityTable'
import { columnByUserActivity } from '../../CollectionByUserActivity/Context/TableComponent/columnByUserActivity'

import UserActivityDropDown from '../../TableFilters/FilterComp/UserActivityDropDown'
import DateMothFilter from '../../TableFilters/FilterComp/DateMothFilter'
import { UserByActivityMainState } from '../Context'
import { Link, useLocation } from 'react-router-dom'
import { Button } from 'src/components/ui/button'
import { useQueryResponseLoading } from '../Context/Core/QueryResponseProvider'
import Loader from '../../Loader/Loader';
import { dropdownModel } from '../../TableFilters/FilterModule/dropdownModel'
import { FileDown } from 'lucide-react'
import { convertToCamelCase, stringifyObjectToRequestQuery } from 'src/helpers/index';
import { COLLECTION_BY_USER_ACTIVITY } from 'src/service/apis'
import UserIdDropDown from '../../TableFilters/FilterComp/UserIdDropDown'
import { useDispatch, useSelector } from 'react-redux'
import { updateActivityDropDown, updateDailyPostDataFromUserAct, updateDatefilter, updateTableDataAndPostData } from 'src/redux/slicer/editableSlicer'
import { RootState } from 'src/redux/store'
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer'



type Props = {}
type ActivityData = {
  user_id: number;
  dt: string;
  activity: string;
  dump_data_achieved: string;
  cumulative_activity_achieved: string;
  match: number;

};
type UserInformation = {
  user_id: number;
  name: string;
  profile_image: string;
}
type ResponseData = {
  user_info: UserInformation
  activity_data: ActivityData[];
};
type DailyData = {
  dt: string | undefined;
  achieved: string | undefined;
}

const CollectionByUserActivity = (props: Props) => {

  const [monthFilter, setMonthFilter] = useState<string>("");
  const [activity, setActivity] = useState<string>("collection_coverage");
  const [userId, setUserId] = useState<number>(0)
  const { state, updateState } = UserByActivityMainState()

  const isLoading = useQueryResponseLoading()
  const dispatch = useDispatch()
  const { response } = useQueryResponse();
  const { dashBoardState } = useSelector((state: RootState) => dashboardReducerState(state))
  const { user_info, activity_data }: { user_info: UserInformation, activity_data: ActivityData[] } = useMemo(() => {
    if (response) {
      let data: any = response?.data;
      return {
        user_info: data?.user_info, activity_data: data?.activity_data
      }
    }
    return {
      user_info: {}, activity_data: []
    }
  }, [response])


  const dailyData: DailyData[] = useMemo(() => {
    return activity_data.map((cur: any) => {
      return {
        dt: cur?.dt,
        achieved: cur?.dump_data_achieved
      };
    })
  }, [activity_data])


  let responseData: ActivityData[] = [];

  const { search } = useLocation();

  useEffect(() => {
    const params = search && search?.split('?')[1].split("&");
    // console.log({ params });
    let searchParams: any = {};

    params && params.forEach((o: any) => {
      let splitParam = o?.split("=");
      searchParams[splitParam[0]] = splitParam[1];
    });

    setMonthFilter(searchParams?.month)
    setActivity(searchParams?.activity)
    setUserId(searchParams?.userId)
    return () => { }
  }, [search])

  useEffect(() => {
    updateState({
      filter: {
        "bym": monthFilter,
        "activity": activity,
        "user_id": userId,
      }
    })
  }, [monthFilter, activity, userId])

  const handleOnClickExport = () => {

    let table_query = { export_excel: 'download' };
    const newstate = state?.filter && JSON.parse(JSON.stringify(state?.filter));
    // console.log({ newstate });
    // // delete newstate["_fkey"];
    delete newstate["items_per_page"];
    delete newstate["page"];
    table_query = { ...table_query, ...newstate }
    console.log(table_query);

    // query = stringifyObjectToRequestQuery(table_query);
    const queryString = stringifyObjectToRequestQuery(table_query);
    // console.log({ queryString });

    const baseUrl = COLLECTION_BY_USER_ACTIVITY + "?" + queryString;
    // console.log(baseUrl);
    var win: any = window.open(baseUrl, '_blank');
    win.focus();

  }

  const activities = {
    "collection_efficeincy": "Payment Collection ₦ ",
    "desconnection": "Disconnection Count",
    "is_on_time": "Punch In Within Time",
    "reconnection": "Reconnection Count",
    "event_count": "Completed Tasks",
    "collection_coverage": "Collection Count",
  }



  const sandboxDispatch = (monthFilter: string, activity: string) => {

    // dispatch(updateDailyPostDataFromUserAct(dailyData));
    dispatch(updateTableDataAndPostData({
      bym: monthFilter,
      jsonInput: {
        A: 160,
        bmark: 10,
        per_million: 1000000,
        activity: activity,
        target: 37490873,
        daily_data: dailyData
      }
    }))
    dispatch(updateDatefilter(monthFilter));
    dispatch(updateActivityDropDown(activity))
  }


  return (
    <>

      <div className={`mt-4 px-4 ${!dashBoardState ? 'ml-20  duration-300' : 'ml-60 duration-300'}`}>
        <h1 className='text-gray-700 font-medium text-lg mt-2 ml-5'>Users Monthly Report </h1>
        {/* <h2 className='text-gray-700 font-medium text-base  ml-5'> Collection By User By Month/Year</h2> */}

        <div className='flex justify-around items-center space-x-6 mt-2'>
          <h1 className='text-sm font-medium text-gray-500 '>Job Id :{" "} <span className='text-gray-700'>{ }</span></h1>
          <h1 className='text-sm font-medium text-gray-500 '>Month-Year :{" "} <span className='text-gray-700'>{monthFilter}</span></h1>
          <h1 className='text-sm font-medium text-gray-500'>Activity :{" "}
            <span className='text-gray-700 ml-2'>{activities[activity as keyof typeof activities] || convertToCamelCase(activity)}</span>
          </h1>

          <img src={user_info?.profile_image} alt={''} className='w-10 h-5 rounded-full' />
          <h1 className='text-sm font-medium text-gray-500'>User Id : {" "}<span className='text-gray-700 ml-2'>{userId}</span> </h1>
          <h1 className='text-sm font-medium text-gray-500'>User Name : {" "}<span className='text-gray-700 ml-2'>{user_info?.name}</span></h1>
        </div>



        {/* <h1 className='w-1/4 text-base font-medium text-gray-800'>User Name: {response?.data}</h1> */}
        <div className='w-full flex justify-end items-center space-x-5 mt-4'>

          <div className={`w-1/6 ${userId ? 'mt-2' : '-mt-2'}`}>
            <h3 className={` text-center text-sm text-gray-800 font-medium ${userId ? 'hidden' : ''}`}>Select User Id</h3>
            <UserIdDropDown setUserId={setUserId} searchId={userId} loadings={isLoading} />
          </div>
          {/* <div className='w-1/5'>
            <h3 className='-mb-2 text-center text-sm text-gray-800 font-medium'>Select Activity</h3>
            <UserActivityDropDown setActivity={setActivity} dropdownModel={dropdownModel} loading={isLoading}/>
          </div>
          <div className='w-1/6 '>
            <h3 className=' text-center text-sm text-gray-800 font-medium -mb-2'>Select Month/Year</h3>
            <DateMothFilter setMonthFilter={setMonthFilter} monthYear={monthFilter} loading={isLoading}/>

          </div> */}

          <Button className='mt-2 text-sm text-gray-800' variant='outline'
            onClick={() => { window.open(`/dashboard/dv-bucketwise-point?bym=${monthFilter}&activity=${activity}&userId=${userId}`, "_self") }}
            disabled={isLoading}>
            View Points Breakup
          </Button>

          <Button className='mt-2 text-sm text-gray-800' variant='outline'
            // onClick={() => dispatch(updateDailyPostDataFromUserAct(dailyData), updateDatefilter(monthFilter))}
            onClick={() => sandboxDispatch(monthFilter, activity)}
            disabled={isLoading}>
            <Link to='/dashboard/editable-comp'> View Sandbox</Link>

          </Button>



          <Button
            variant='secondary'
            onClick={handleOnClickExport}
            className='bg-white mt-2'
            disabled={isLoading}> <FileDown /></Button>
        </div>


        <UserActivityTable columns={columnByUserActivity} data={activity_data || []} />

      </div>

    </>
  )
}

export default CollectionByUserActivity