import { cn } from "src/helpers"
import { Slider } from "src/components/ui/slider"
import { useEffect, useState } from "react";
import { Label } from "src/components/ui/label";
import { useDispatch } from "react-redux";

type SliderProps = React.ComponentProps<typeof Slider>
type Progress = SliderProps & {
  label: string;
  zPerce: number,
  hPerce: number,
  val: number
  setVal: (value: number) => void,
  defaultValue: number[],
  activity?: string
};
export function ProgressBar({ className, label, zPerce, hPerce, val, setVal, defaultValue, activity, ...props }: Progress) {
  const [sliderValue, setSliderValue] = useState<number>(defaultValue[0]);

  const handleValueChange = (newValue: number[]) => {

    console.log('Slider value changed:', newValue);
    setSliderValue(newValue[0])

  };

  const dispatch = useDispatch()


  useEffect(() => {
    if (setVal) {
      const difference = (hPerce) - (zPerce);
      const calculatedValue = ((difference * sliderValue * 0.01) + zPerce);

      setVal(calculatedValue);
    }
  }, [sliderValue, zPerce, hPerce, setVal])

  // console.log(val, 'actual val')

  return (
    <div className="w-full" style={{ position: 'relative' }}>

      <Slider
        defaultValue={defaultValue}
        max={100}
        step={1}
        className={cn("w-full cursor-pointer", className)}
        {...props}
        onValueChange={handleValueChange}
      // disabled={activity !== 'collection_efficeincy'}
      />
      <span className={cn(`-mt-8  text-xs text-green-800 font-medium`)} style={{ position: 'absolute', marginLeft: `${sliderValue > 50 ? sliderValue - 6 : sliderValue - 2}%` }}>{val}</span>
      <Label className="mt-2 flex items-center justify-center text-sm font-semibold text-gray-700">{label}</Label>
    </div>
  )
}
