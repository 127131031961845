import { Card, CardContent, CardHeader, CardTitle } from 'src/components/ui/card'
import React, { useMemo } from 'react'
import { Equal, Plus } from 'lucide-react'
import Niara from '../UserEditableComponent/Niara.svg';
import { cn } from 'src/helpers';


const UserChartsSummary = ({ datas, color, word, targets, details }: any) => {
    // console.log(datas)
    // console.log(targets)

    const xAxisValues = details?.map((cur: any) => ({

        "xAxisValue": (cur?.x * 100)?.toFixed(2),
        "Performance Points": cur?.actual_points?.toFixed(2),
        "Bonus Points": cur?.total_bonus?.toFixed(2),
        "Achived Points": cur?.total_points?.toFixed(2),
        "Date": cur?.dt
    }));

    const achivedPercentage = useMemo(() => xAxisValues && Array.isArray(xAxisValues) ? xAxisValues[xAxisValues?.length - 1]?.xAxisValue : 0, [xAxisValues])

    return (
        <Card className={cn(`w-full rounded-none text-gray-500`)}>

            <CardContent className='flex items-center justify-around space-x-2 mt-2'>
                <div className={cn(` rounded-lg p-2`)}>
                    {/* <h3 className='text-xs font-medium mt-1'>{word} Performance Point</h3> */}
                    <h3 className='text-sm font-bold text-center text-gray-600'>{datas?.actual_points ? Intl.NumberFormat('en-US').format(datas?.actual_points.toFixed(2)) : 0}</h3>

                </div>
                <div > <Plus color='#000000' size={16} /></div>
                <div className={` p-2 ${color} rounded-lg`}>
                    {/* <h3 className='text-xs font-medium mt-1'>{word} Bonus Point</h3> */}
                    <h3 className={`text-sm font-bold text-center ${color}`} >{datas?.total_bonus ? Intl.NumberFormat('en-US').format(datas?.total_bonus.toFixed(2)) : 0}</h3>

                </div>
                <div> <Equal color='#000000' size={16} /></div>
                <div className={cn(`  p-2   rounded-lg`)}>
                    {/* <h3 className='text-xs font-medium mt-1'>Total Points</h3> */}
                    <h3 className=' text-sm font-bold text-center text-gray-600'>{datas?.total_points ? Intl.NumberFormat('en-US').format(datas?.total_points.toFixed(2)) : 0}</h3>

                </div>
                <div>
                </div>

            </CardContent>
            {targets && datas ? (
                <div className={cn(`flex justify-between px-4 -mt-3 text-gray-700 }`)}>
                    <h3 className='flex text-xs font-bold justify-center items-center mb-2'> Achived Value:<img src={Niara} className='h-3 w-3 ml-1' /> <span className='ml-1'>{datas?.cumulative_collection ? Intl.NumberFormat('en-US').format(datas?.cumulative_collection?.toFixed(2)) : 0}</span> </h3>

                    <h3 className='flex text-xs font-bold justify-center items-center mb-2'> Target Value : <span className='ml-1'> {Intl.NumberFormat('en-US').format(targets)}</span></h3>
                </div>
            ) : (

                <div className={cn(`flex justify-between px-4 -mt-3 text-gray-700`)}>
                    <h3 className='flex text-xs font-bold justify-center items-center mb-2'>Achived Value:<img src={Niara} className='h-3 w-3 ml-1' /> <span className='ml-1'>0</span> </h3>

                    <h3 className='flex text-xs font-bold justify-center items-center mb-2 '> Target Value : <span className='ml-1'> 0</span></h3>
                </div>
            )}
            <div>
                <h3 className='flex text-xs font-bold text-gray-800 justify-center items-center mb-2'>Achived Percentage:  <span className='ml-1 text-gray-600'>{Intl.NumberFormat('en-US').format(achivedPercentage)}%</span> </h3>
            </div>

        </Card>

    )
}

export default UserChartsSummary