import dayjs from 'dayjs';
import { WallOfFameMainState } from '../Context';
import React, { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux';
import { dashboardReducerState } from 'src/redux/slicer/dashboardSlicer';
import { RootState } from 'src/redux/store';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import { convertMM_YYYY, convetMMYYYYToMMMMYYYY } from 'src/helpers/index';
import { useQueryResponseLoading } from '../Context/index';
import { useQueryResponse } from '../Context/Core/QueryResponseProvider';
import { Loader2 } from 'lucide-react';
import TopThree from '../Context/WallOfFameComponent/TopThree';
import DistrictWise from '../Context/WallOfFameComponent/DistrictWise';
import DateFilter from 'src/modules/TableFilters/FilterComp/DateFilter';



export interface TopThreeProps {
    dist_name: string,
    name: string
    player_id: number
    profile_image: string
    rank: 1
    sc_name: string
    target_points: number
    total: number
}

const WallOfFame = () => {
    const { state, updateState } = WallOfFameMainState();
    const [ymDates, setYmDates] = useState<string>('');
    const { dashBoardState, monthYear } = useSelector((state: RootState) => dashboardReducerState(state));
    let dates = new Date();
    let dateFormate = dayjs(dates).format('MMMM - YYYY');

    useEffect(() => {
        monthYear !== '' &&
            updateState({
                filter: {
                    ym: convertMM_YYYY(monthYear),
                    type: 'global'
                }
            })
        return () => { }
    }, [monthYear])



    let selected_Date = convetMMYYYYToMMMMYYYY(convertMM_YYYY(monthYear))


    const { response } = useQueryResponse()

    const isLoading = useQueryResponseLoading();

    const apiResponseData: any = useMemo(() => response, [response]);

    const tableData: any = useMemo(() => isLoading ? {} : (apiResponseData ? apiResponseData?.data : {}), [isLoading, apiResponseData])

    const top_three: any = useMemo(() => isLoading ? [] : (tableData ? tableData?.top_three : []), [isLoading, tableData])


    return (
        <div className={`z-10 mt-2  ${!dashBoardState ? "ml-20  duration-300" : "ml-60 duration-300"
            }`}>
            <div className='w-full flex justify-end px-10'>
                <div className='w-1/6 '>
                    <DateFilter monthYear={monthYear} />
                </div>

            </div>
            {isLoading ? <div className='flex justify-center items-center '>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" /> <span>Loading...</span>
            </div> : (Array.isArray(top_three) && top_three?.length > 0 ? <>

                <h1 className='text-center my-4 text-3xl font-bold font-mono '>Top 3 Performers of {selected_Date !== '' ? selected_Date : dateFormate} </h1>
                <div className='w-full flex space-x-6 justify-center px-4'>


                    {top_three.map((data: any, ind: any) => <TopThree {...data} key={ind} />)}
                </div>

                <h1 className='text-center  text-3xl font-bold font-mono '>TOP 3 Performers Districtwise of {selected_Date !== '' ? selected_Date : dateFormate} </h1>
                <DistrictWise />
            </> : (<></>))}

        </div>
    )
}

export default WallOfFame